<template>
  <div class="container-fluid bg-white">
    <ul class="nav nav-tabs thetoppills py-2">
        <li class="nav-item" v-if="permission.view_store_management === 1">
        <router-link class="nav-link makeGreen" active-class="pillactive" to="/manage-store"
          :class="$router.currentRoute.value.name === 'Create Store' || $router.currentRoute.value.name ==='Edit Store'? 'pillactive' : ''"
        >
          <!-- <span class="iconify pillicons"><font-awesome-icon icon="toolbox" /></span> -->
          Manage Store
        </router-link>
      </li>
      <li class="nav-item" v-if="permission.administration_module === 1 || permission.view_administration === 1">
        <router-link class="nav-link makeGreen" active-class="pillactive" to="/manage-user"
          :class="$router.currentRoute.value.name === 'Create User' || $router.currentRoute.value.name ==='Edit User'? 'pillactive' : ''"
        >
          <!-- <span class="iconify pillicons"><font-awesome-icon icon="user-cog" /></span> -->
          Manage Users
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const permission = computed(() => store.state.getCurrentUserPermission);
    return { permission };
  },
};
</script>

<style>

</style>