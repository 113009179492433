<template>
    <div class="mt-3 gridtop" style="overflow-x:hidden">
        <div class="d-flex onmobile justify-content-between">
            <div class="mt-1 fw-bold" style="font-size:20px">
                <span @click="$router.go(-1)">
                    <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
                </span>
                {{purchase?.batch_code}}
            </div>

            <PrintButtonHeader :title="'Purchase-Invoice-' + purchase?.batch_code" :urlA4="'/purchase-receipt/'" :loading="loading"
            :url58mm="'/print58mm-purchase-receipt/'" :url80mm="'/print80mm-purchase-receipt/'"
            :currentUrl="'View Purchase Receipt'" :data="purchase" :type="'Purchase Receipt'" />

        </div>
        <div>
            <div class="d-flex salescardcontainer">
                <div class="card rounded-lg mt-3 mb-3 px-4 py-3 w-75 salescard cardprintsales print-sales-shadow" style="overflow-x: auto;">
                    <Invoice :purchase_items="purchase_items" :purchase_array="purchase_array" :purchase="purchase" :loading="loading"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import axios from "axios";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import {
    useStore
} from "vuex";
import Invoice from './components/invoice'

export default {
    components: {
        Invoice
    },
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            purchase: null,
            grand_total: 0,
            tax_amount: 0,
            purchase_items: [],
            purchase_array: [],
            store: useStore(),
            loading:true
        }
    },
    methods: {
        getPurchase() {
            this.loading = true
            axios.get(`/inventory/purchase/${this.$route.params.id}`).then((response) => {
                this.loading = false
                this.purchase = response.data.data
                this.purchase_items = response.data.data.purchase_item
                this.purchase_items.forEach((s) => {
                    this.purchase_array.push({
                        "product_name": s?.product?.product_name,
                        "cost_price": s?.cost_price,
                        "variant_name": s?.variant?.variant_name ?? s?.product?.unit_of_measurement,
                        "quantity": s?.quantity,
                        "total": parseFloat(s?.amount_paid)
                    })

                })

            }).catch(() => {
                this.loading = true
                this.toast.error("Oops, Something went wrong")
            })
        },

    },


    created() {
        this.getPurchase()
    }

}
</script>
<style scoped>

.subtotal {
    margin-right: 99px;
}

.taxmargin {
    margin-right: 150px;
}

.totalmargin {
    margin-right: 134px;
}

.salesbottommargin {
    margin-right: 74px !important;
}

.salesbottommarginpaid {
    margin-right: 32px !important;
}

.salesbottommarginbal {
    margin-right: 46px !important;
}

.salescardcontainer {
    justify-content: center;
}

@media (max-width: 767px) {
    .salescard {
        width: 100% !important;
    }

    .onmobile {
        flex-direction: column !important;
    }

    .salescardcontainer {
        display: block !important;
    }

    .invoicelogo {
        width: 50px;
        height: 50px;
    }
}
</style>
