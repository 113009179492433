<template>
<SettingHeaderTab />

<div>
    <div class="tab-pane mt-5 fade show active bg-white rounded-lg mx-3 px-3 py-3" id="company" role="tabpanel" aria-labelledby="company-tab">
        <div class="d-flex justify-content-between">
            <div>
                <h4>Create Supplier</h4>
                <div class="text-muted" style="font-size: 10px;">Add people who supplier your products</div>
            </div>
        </div>
        <div class="row">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id" style="list-style:none">
                <li class="text-danger"><small>{{ errors }}</small></li>
            </ul>
        </div>
        <form class="mt-4" @submit.prevent="createSupplier">
            <div class="row">
                <div class="form-group col">
                    <label for="inputEmail4">Supplier Name</label>
                    <input type="text" class="form-control" placeholder="Enter full name" v-model="supplier_name" onkeypress="return /[a-z, ]/i.test(event.key)">

                </div>
                <div class="form-group col">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" placeholder="Enter phone number" v-model="phone_no">
                </div>
                <div class="form-group col">
                    <label>Email Address</label>
                    <input type="text" class="form-control" placeholder="Enter email address" v-model="email">
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>Address</label>
                    <input type="text" class="form-control" placeholder="Enter customer's address" v-model="address">
                </div>
                <div class="form-group col-md-4">
                    <label>Country</label>
                    <select v-model="country_id" class="form-select" @change="getStates($event)">
                        <option>Choose Country</option>
                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label>State</label>
                    <select v-model="state_id" class="form-select" @change="getCities($event)">
                        <option value="">Choose state</option>
                        <option :value="state.id" v-for="state in states" :key="state.id">{{ state.name }}</option>

                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>City</label>
                    <select v-model="city_id" class="form-select">
                        <option value="">Choose city</option>
                        <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                    </select>
                </div>
                <div class="form-group col"></div>
                <div class="form-group col"></div>
            </div>
            <div class="mt-4 d-flex justify-content-end">
                <button type="submit" class="btn px-5 me-5 allbuttonsclr text-white" v-if="crudLoading === false">Save</button>
                <button v-if="crudLoading === true" type="submit" class="btn px-5 me-5 allbuttonsclr text-white">
                    <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Processing</span>
                    </div>
                </button>
                <button type="button" class="btn px-5 exportbtn" @click="$router.go(-1)">Cancel</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    ref
} from '@vue/reactivity';
import SettingHeaderTab from './components/settingheadertab.vue';
import axios from 'axios';
import {
    useRouter
} from 'vue-router';
import {
    useToast
} from 'vue-toastification';
import {
    onMounted
} from '@vue/runtime-core';

export default {
    components: {
        SettingHeaderTab
    },
    setup() {
        const supplier_name = ref('')
        const phone_no = ref('')
        const country_id = ref('')
        const city_id = ref('')
        const state_id = ref('')
        const email = ref('')
        const address = ref('')

        const crudLoading = ref(false)
        const router = useRouter()
        const validationErrors = ref('')
        const toast = useToast()

        const countries = ref([])
        const states = ref([])
        const cities = ref([])
        const loading = ref(false)

        const createSupplier = async () => {
            crudLoading.value = true
            await axios.post('settings/supplier', {
                supplier_name: supplier_name.value,
                phone_no: phone_no.value,
                country_id: country_id.value,
                city_id: city_id.value,
                state_id: state_id.value,
                email: email.value,
                address: address.value

            }).then((res) => {
                crudLoading.value = false
                router.push('/all-suppliers')
                toast.success(res.data.message);
            }).catch((error) => {
                if (error.response.status === 422) {
                    toast.warning('There are errors in your input');
                    validationErrors.value = error.response.data.message
                }
                if (error.response.status === 500) {
                    toast.error('An error occured please try again');
                }
                crudLoading.value = false
            })
        }

        const getAllCountries = async () => {
            const response = await axios.get('countries')
            countries.value = response.data.data.data
            loading.value = false
        }

        const getStates = async (event) => {
            let country_id = event.target.value
            const response = await axios.get('/states/' + country_id)
            states.value = response.data.data.data
            loading.value = false
        }

        const getCities = async (event) => {
            let state_id = event.target.value
            const response = await axios.get('/cities/' + state_id)
            cities.value = response.data.data.data
            loading.value = false
        }
        onMounted(getAllCountries())

        return {
            crudLoading,
            validationErrors,
            supplier_name,
            email,
            address,
            phone_no,
            country_id,
            city_id,
            state_id,
            countries,
            states,
            cities,
            createSupplier,
            getAllCountries,
            getStates,
            getCities

        }
    }
}
</script>

<style>
</style>
