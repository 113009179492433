<template>
  <ReportHeaderTab />

  <div class="mt-3 gridtop">
     <div class="d-flex justify-content-between my-3">
      <div class="mt-2 fw-bold ms-1" style="font-size:20px">
        <router-link to="/stock-adjustment-report"><font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" /></router-link> SA-123456</div>
      <div class="d-flex gridtop">
        <div class="me-2">
          <button class="btn btn-light px-5" style="background:white; border:1px solid gray;">Download as PDF</button>
        </div>
        <div class="d-flex">
          <button class="btn px-5 me-2" style="background:white; border:1px solid gray;">
            Print 58mm
          </button>
        </div>
        <div class="d-flex">
          <button class="btn px-5 me-2" style="background:white; border:1px solid gray;">
            Print 80mm
          </button>
        </div>
        <div class="d-flex">
          <button class="btn px-5 mr-2" style="background:white; border:1px solid gray;">
            Print A4
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto;">
      <div class="d-flex justify-content-between my-5 headercreate" style="font-size: 13px;">
        <div class="text-secondary">Date: <span class="fw-bold text-dark">21st August 2021</span></div>
        <div class="text-secondary">STOCK TRANSFER NUMBER: <span class="text-dark fw-bold">SA-123456</span></div>
        <div class="text-secondary">STORE: <span class="fw-bold text-dark">Surulere store</span></div>
        <div class="text-secondary">DESTINATION STORE: <span class="fw-bold text-dark">Ijesha store</span></div>
        <div class="text-secondary">TRANSFERRED BY BY: <span class="fw-bold text-dark">Esther Jessica</span></div>
      </div>
      <div class="">
        <table class="table table-sm-responsive">
          <thead class="thead-light" style="border-style: hidden !important;">
            <tr class="pb-5 text-center">
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">S/N</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Products</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Variant</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <th class="text-secondary" style="font-size: 16px;">1</th>
              <td class="text-secondary" style="font-size: 16px;">Yam</td>
              <td class="text-secondary" style="font-size: 16px;">Carton</td>
              <td class="text-secondary" style="font-size: 16px;">20</td>
            </tr>
            
          </tbody>
        </table>
      </div>
      <div class="mt-4 d-flex justify-content-end">
        <div class="me-4 fw-bold">Grand Total:</div>
        <span>₦2000</span>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeaderTab from './components/reportheadertab.vue'
export default {
  components: { ReportHeaderTab },
}
</script>

<style>

</style>