<template>
  <ReportHeaderTab />
  <!-- <div class="container-fluid text-black">
    <ul class="nav nav-tabs thetoppills py-2" >
      <li class="nav-item" role="presentation">
        <a class="nav-link pillactive" id="sales-tab" data-bs-toggle="tab" href="#sales" role="tab" aria-controls="sales" aria-selected="true">
          <span class="iconify pillicons" data-icon="akar-icons:shopping-bag"></span> Sales Report
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="performance-tab" data-bs-toggle="tab" href="#performance" role="tab" aria-controls="performance" aria-selected="true">
          <span class="iconify pillicons" data-icon="akar-icons:shopping-bag"></span> Top Performance
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="adjustment-tab" data-bs-toggle="tab" href="#adjustment" role="tab" aria-controls="adjustment" aria-selected="true">
          <span class="iconify pillicons" data-icon="akar-icons:shopping-bag"></span> Adjustment Report
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="bin-tab" data-bs-toggle="tab" href="#bin" role="tab" aria-controls="bin" aria-selected="true">
          <span class="iconify pillicons" data-icon="akar-icons:shopping-bag"></span> Bin Card
        </a>
      </li>
    </ul>
  </div> -->

  <div class="mt-3 px-3 gridtop">
    <div class="mt-2 px-5" style="font-size: 14px">Sales</div>
    <div class="d-flex justify-content-between gridtop px-5">
      <div class="mt-2 font-weight-bold" style="font-size: 20px">
        <span
          class="iconify arrowradius"
          data-icon="ant-design:arrow-left-outlined"
        ></span>
        PU-12345678
      </div>
      <div class="">
        <select class="form-control">
          <option selected>Download as PDF</option>
          <option value="">Send as Email</option>
          <option value="">Print</option>
        </select>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div
      class="card salescard rounded-lg mt-3 mx-3 mb-3 px-4 py-3 cardprintsales print-sales-shadow"
    >
      <table class="table table-sm-responsive">
        <thead class="thead">
          <tr class="">
            <th class="text-secondary font-weight-bold" style="font-size: 14px">
              Sales Date
            </th>
            <th class="text-secondary font-weight-bold" style="font-size: 14px">
              Customer
            </th>
            <th class="text-secondary font-weight-bold" style="font-size: 14px">
              Sales No
            </th>
            <th class="text-secondary font-weight-bold" style="font-size: 14px">
              Store
            </th>
            <th class="text-secondary font-weight-bold" style="font-size: 14px">
              Sold By
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>12-02-10</td>
            <td>Olu</td>
            <td>SN-12345678</td>
            <td>Surulere Store</td>
            <td>Peter John</td>
          </tr>
        </tbody>
      </table>
      <hr class="firsthr" />
      <div>
        <div class="font-weight-bold">000909</div>
        <div class="d-flex justify-content-between">
          <div>Yam</div>
          <div>1 * N3,387.00</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Tax</div>
          <div>N280</div>
        </div>
        <hr class="my-3 thinborder" />
        <div class="font-weight-bold">000909</div>
        <div class="d-flex justify-content-between">
          <div>Yam</div>
          <div>1 * N3,387.00</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Tax</div>
          <div>N280</div>
        </div>
        <hr class="my-5 thinborder" />
        <div class="d-flex justify-content-between">
          <div>Total Amount</div>
          <div>N3000.00</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Total Discount</div>
          <div>N0.00</div>
        </div>
        <div class="d-flex justify-content-between mb-5">
          <div>Total VAT</div>
          <div>N670.00</div>
        </div>
        <hr class="my-5 thinborder" />
        <div class="d-flex justify-content-between font-weight-bold">
          <div>Grand Total</div>
          <div>N3,670.00</div>
        </div>
        <div class="text-secondary mb-5" style="font-size: 12px">1 Product</div>
        <hr class="my-5 thinborder" />
        <div class="mb-2 font-weight-bold">Payment Information</div>
        <div class="d-flex justify-content-between">
          <div>Payment Type</div>
          <div class="font-weight-bold">Cash</div>
        </div>
        <div class="d-flex justify-content-between mb-5">
          <div>Paid Amount</div>
          <div class="font-weight-bold">N3,670.00</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeaderTab from "./components/reportheadertab.vue";
export default {
  components: { ReportHeaderTab },
};
</script>

<style>
@media (max-width: 767px) {
  .salescard {
    width: 100% !important;
  }
}
</style>
