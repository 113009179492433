<template>
  <div>
  <div class="d-flex justify-content-between mb-5">
    <div class="title" style="font-size: 45px"></div>
    <div></div>
  </div>
  <!-- <div class=""><span></span></div> -->
  <div class="d-flex justify-content-between mb-5">
    <div class="side-left">
      <div class="side-left-1"><span></span></div>
      <div class="side-left-2"><span></span></div>
      <div class="side-left-3"><span></span></div>
    </div>
    <div>
      <div class="side-right-1"><span></span></div>
      <div class="side-right-2"><span></span></div>
    </div>
  </div>

  <table
    cellpadding="0"
    cellspacing="0"
    width="100%"
    class="w3samples_table_loader"
  >
    <tr>
      <th class="col1">
        <span></span>
      </th>
      <th class="col4">
        <span></span>
      </th>
      <th class="col5">
        <span></span>
      </th>
    </tr>
    <tr>
      <td class="col1">
        <span></span>
      </td>
      <td class="col4">
        <span></span>
        <span class="sub-temp"></span>
        <span class="sub-temp sub-temp-three"></span>
      </td>
      <td class="col5">
        <span></span>
      </td>
    </tr>
    <tr>
      <td class="col1">
        <span></span>
      </td>
      <td class="col4">
        <span></span>
        <span class="sub-temp"></span>
        <span class="sub-temp sub-temp-three"></span>
      </td>
      <td class="col5">
        <span></span>
      </td>
    </tr>
    <tr>
      <td class="col1">
        <span></span>
      </td>
      <td class="col4">
        <span></span>
        <span class="sub-temp"></span>
        <span class="sub-temp sub-temp-three"></span>
      </td>
      <td class="col5">
        <span></span>
      </td>
    </tr>

  </table>
  </div>
</template>



<style>
.title {
  width: 10rem;
  height: 25px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines-table 1.6s infinite linear;
  display: block;
  min-width: 15px;
}
.side-right-1 {
  width: 10rem;
  height: 15px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines-table 1.6s infinite linear;
  display: block;
  min-width: 15px;
}
.side-right-2 {
  margin-top: 10px;
  width: 12rem;
  height: 15px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines-table 1.6s infinite linear;
  display: justify;
  min-width: 15px;
}

.side-left-1 {
  width: 10rem;
  height: 15px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines-table 1.6s infinite linear;
  display: block;
  min-width: 15px;
}

.side-left-2 {
  margin-top: 10px;
  width: 12rem;
  height: 15px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines-table 1.6s infinite linear;
  display: block;
  min-width: 15px;
}
.w3samples_table_loader {
  background-color: #fff;
  color: #666;
  width: 100%;
  padding: 0;
}
.w3samples_table_loader th {
  background: #f7f9fa;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 10px;
  text-align: left;
}
.w3samples_table_loader td {
  border-top: 0px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 10px;
  vertical-align: top;
}
.w3samples_table_loader span {
  width: calc(100% - 5px);
  height: 15px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines-table 1.6s infinite linear;
  display: inline-block;
  min-width: 15px;
}
.w3samples_table_loader span.sub-temp {
  width: calc(100% - 50%);
  margin-top: 5px;
}
.w3samples_table_loader span.sub-temp-three {
  width: calc(100% - 70%);
  display: block;
}
.col1 {
  width: 25px;
}
.col2 {
  width: 60px;
}
.col3 {
  width: 45px;
}
.col4 {
  width: 344px;
}
.col5 {
  width: 145px;
}
.col6 {
  width: 113px;
}
.col7 {
  width: 73px;
}
.col8 {
  width: 160px;
}
.col9 {
  width: 92px;
}

@keyframes shine-lines-table {
  0% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
