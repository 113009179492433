<template>
<div>
<AdminHeader />

<div id="stores" :class="permission.view_store_management === 1 ? 'tab-pane fade show active' : ''">
    <div class="mt-3 font-weight-bold d-flex justify-content-between gridtop">
        <div class="mt-2" style="font-size: 20px">Store List</div>
        <div class="d-flex">
            <div class="d-flex align-items-center small pl-2 me-2 mt-1">
                <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                    <input type="text" class="form-control " placeholder="Search stores" style="padding-left: 38px;" v-model="query" />
                <!-- <font-awesome-icon icon="barcode" class="scan" /> -->
            </div>
            <div class="dropdown">
                    <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../private-assets/images/Vector.png" class="me-2" alt=""> Export
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a @click="drawPdf(print_content, headerArrayColumn, 'p', 'stores', 'Stores')" class="dropdown-item" href="#">PDF</a></li>
                        <li><a @click="reusableMixinToCSV('stores.csv')" class="dropdown-item" href="#">CSV</a></li>
                    </ul>
                </div>
            <div class="me-2">
                <router-link to="/create-store" class="btn allbuttonsclr mt-1 text-white" :class="permission.create_store_management===0 ? 'disabled':''">
                        Add Store
                </router-link>
            </div>
            <div>
                <button class="btn btn-danger text-white mt-1" :data-bs-toggle="permission.delete_store_management===1 ? 'modal' :''" :data-bs-target="permission.delete_store_management===1 ? '#bulkModal' :''" :class="permission.delete_store_management===0 ? 'disabled':''">Bulk Delete</button>
            </div>
        </div>
    </div>
    <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding">
        <div v-if="loading === true" class="text-center py-5">
            <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="permission_status === 403 && is_permission === false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
        <div class="text-center py-5" v-if="details.length === 0 && loading === false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">Your store bucket is empty!</div>
            <!-- <div>Create store</div> -->
        </div>

        <div v-if="loading === false && details.length > 0">
            <div class="card-toolbar">
                <!--begin::Toolbar-->
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->

                </div>
                <!--end::Toolbar-->
            </div>
            <div class="" style="overflow-x: auto">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5 text-center">
                            <th scope="col"> <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" /></th>
                            <th class="font-weight-bold heading">Stores</th>
                            <th class="font-weight-bold heading">Address</th>
                            <th class="font-weight-bold heading">State</th>
                            <th class="font-weight-bold heading">Country</th>
                            <th class="font-weight-bold heading">Phone Number</th>
                            <th class="font-weight-bold">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(store, index) in details" :key="index.id" class="text-center">
                            <td><input type="checkbox" name="" id="" v-model="selectedModel" :value="store.id" /></td>
                            <td class="text-secondary text-capitalize py-3 row1" style="font-size: 16px">
                                {{ store.store_name }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ store.address }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ store.city?.name }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ store.country?.name }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ store.phone_no }}
                            </td>
                            <td class="py-3">
                                <div class="" style="font-size: 13px">
                                    <router-link :to="permission.edit_store_management===0 ? '#' : `/edit-store/${store.id}`">
                                        <font-awesome-icon prefix="fal" icon="pen" class="me-2 text-dark" style="font-size: 15px" :style="permission.edit_store_management===1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                    </router-link>
                                    <font-awesome-icon @click="getModalCurrentId(store.id)" type="button" :data-bs-toggle="store.first_store !== store.created_at ? 'modal' : ''" :data-bs-target="store.first_store !== store.created_at ? '#singleModal' : ''" icon="trash" :style="store.first_store !== store.created_at ? {} : {'color':'#DCDCDC', 'cursor':'default'}" v-if="permission.delete_store_management===1"/>
                                    <font-awesome-icon @click="getModalCurrentId(store.id)" type="button" :data-bs-toggle="permission.delete_store_management===1? 'modal' : ''" :data-bs-target="permission.delete_store_management===1 ? '#singleModal' : ''" icon="trash" :style="permission.delete_store_management===1? {} : {'color':'#DCDCDC', 'cursor':'default'}" v-else/>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <pagination style="float:right;" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)" @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0">
    </pagination>
</div>

<SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleStoreDeleteButton" :modalText="'Are you sure you want to delete this store'" :action="'deleteSingle'"></SingleDeleteModal>
<BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkDeletStoreButton" :modalText="'Are you sure you want to delete this stores'"></BulkDeleteModal>
</div>
</template>

<script>
import AdminHeader from "./administrationheadertab.vue";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import SingleDeleteModal from '../../../components/ActionModal.vue';
import BulkDeleteModal from '../../../components/bulkDeleteModal.vue';

import {
    reusableMixin
} from "../../../lib/reusableMixin";
import "jspdf-autotable";

import {useStore} from "vuex";

export default {
    components: {
        AdminHeader,
        SingleDeleteModal,
        BulkDeleteModal,
    },
    mixins: [reusableMixin],

    data() {
        let sortOrders = {};

        let columns = [{
                width: '16%',
                label: 'Name',
                name: 'name',
                sortable: false
            },
            {
                width: '15%',
                label: 'Sortcode',
                name: 'sortcode',
                sortable: false
            },
            {
                width: '15%',
                label: 'Phonecode',
                name: 'phonecode',
                sortable: false
            },
            {
                width: '5%',
                label: 'Status',
                name: 'status',
                sortable: false
            },
            {
                width: '5%',
                label: 'Actions',
                name: 'actions',
                sortable: false
            },

        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content: [],
            headerArrayColumn: ['S/N', 'Stores', 'Address', 'State', 'Country', 'Phone Number'],

            errorMessage: null,
            getCustomers: '',
            details: [],
            loading: false,
            query: null,
            supplier_id: null,

            selectedModel: [],
            allSelected: false,

            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                draw: 0,
                length: 5,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            permission_status:null,
            is_permission:true,
            store: useStore(),

        }
    },

    methods: {
        dataToPrint(details) {
            details.map((store, idx) => {
                let result = {
                    sn: idx + 1,
                    store_name: store.store_name,
                    address: store.address,
                    city: store.city?.name,
                    country: store.country?.name ,
                    phone_no: store.phone_no,

                };
                this.print_content.push(result)
            });
        },
        getModalCurrentId(id) {
            this.currentID = id;
        },
        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },
        fetchData(url = '/admin/store/user-stores') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.dataToPrint(this.details)
                this.configPagination(data.data);
            }).catch(error => {
                this.errorMessagem = error
                this.loading = false;

                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            });
        },

        callSingleStoreDeleteButton() {
            axios.delete(`/admin/store/${this.currentID}`).then(() => {
                this.toast.success('Store deleted successfully')
                this.selectedModel = []
                this.fetchData()
            }).catch((error) => {
                if(error?.response?.status === 400 || error?.response?.status === 500){
                    this.toast.error(error?.response?.data?.message ?? 'Whoops, Something went wrong')
                }
                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            })

        },

        callBulkDeletStoreButton() {
            axios.post(`/admin/store/delete-bulk`, {
                stores: this.selectedModel,
            }).then((response) => {
                this.fetchData();
                this.selectedModel =[]
                this.toast.success(response.data.message);

            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.error(error.response.data.message[0]);
                } 

                if (error.response.status == 400) {
                    this.toast.error(error.response.data.message);
                } 


                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }

            })
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
    },

    computed:{
        permission(){
            return this.store.state.getCurrentUserPermission
        }
    },

    watch: {
        query() {
            this.fetchData()
        }
    },
    created() {
        this.fetchData();
    },
}

// export default {
//       components: {
//         AdminHeader,
//         SingleDeleteModal,
//         BulkDeleteModal,
//     },
//   setup() {
//     const allStores = ref("");
//     const currentID = ref("");
//     const toast = useToast();
//     const loading = ref(false);

//     const getAllStores = async () => {
//       loading.value = true;

//       const response = await axios.get("/admin/store");

//       allStores.value = response.data.data;

//       loading.value = false;
//     };

//     const getModalCurrentId = (id) => {
//       currentID.value = id;
//     };

//     const deleteStore = async (id) => {
//       await axios.delete(`/admin/store/${currentID.value}`);

//       allStores.value = allStores.value.filter((u) => u.id !== id);

//       getAllStores();

//       toast.success("Store deleted successfully", { timeout: 5000 });
//     };

//     onMounted(getAllStores());

//     return {
//       getAllStores,
//       allStores,
//       deleteStore,
//       currentID,
//       getModalCurrentId,
//       loading,
//     };
//   },
// };
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }
}
</style>
