<template>
    <InventoryHeaderTab />

    <div>
        <div class="mt-3 px-3 font-weight-bold gridtop">
            <div class="mt-2" style="font-size: 20px">List of Stock Adjustment</div>

            <div class="d-flex justify-content-between gridtop mb-2">
                <div class="d-flex dateinputs allsalestop">
                    <div class="mb-0 me-1" style="width: 170px">
                        <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control"
                            style="background: none; border: none" />
                    </div>
                    <div class="mb-0 me-2" style="width: 170px">
                        <Datepicker v-model="tableData.end_date" :minDate="tableData.start_date"
                            :enableTimePicker="false" class="form-control" style="background: none; border: none" />
                    </div>
                    <div class="salessubmitdate">
                        <button type="submit" data-bs-dismiss="modal"
                            class="btn btn-success allbuttonsclr text-white mt-2" v-if="crudLoading === false"
                            @click="fetchData()">Submit</button>
                        <button v-if="crudLoading == true" class="btn btn-success allbuttonsclr text-white mt-2"
                            type="button" @click="fetchData()" disabled>
                            <span class="">Processing...</span>
                        </button>
                    </div>
                </div>

                <div class="d-flex gridtop">
                    <div class="d-flex me-2">
                        <div class="d-flex align-items-center small pl-2 me-2">
                            <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                            <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px;max-width:170px;margin-top:5px"
                                v-model="query" />
                        </div>
                    </div>
                    <div class="d-flex mt-1">

                        <div class="dropdown">
                            <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                                Export
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a @click="drawPdf(print_content, headerArrayColumn, 'l', 'Stock adjustment', 'Stock Adjustments')"
                                        class="dropdown-item" href="#">PDF</a>
                                </li>
                                <li>
                                    <a @click="reusableMixinToCSV('stockadjustments.csv')" class="dropdown-item"
                                        href="#">CSV</a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-1">
                            <router-link to="/create-stock-adjustment" class="btn text-white btn-success" :class="permission.create_stock_adjustment === 1 ? '':'disabled'">
                                    <!-- <font-awesome-icon icon="plus" />  -->
                                    <span class="ms-1">Create Stock Adjustment</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="card thecard rounded-lg mt-3 mb-3 py-3 px-4 cardpadding" style="overflow-x: auto">
            <div class="text-center py-5" v-if="details.length === 0 && loading === false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your stock adjustment list is empty!</div>
            </div>
            <div v-if="loading === true" class="text-center py-5">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="card-toolbar" v-if="loading === false && details.length > 0">
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()"
                            class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->

                </div>
            </div>
            <div class="" v-if="loading === false && details.length > 0">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5">
                            <th class="font-weight-bold heading" style="width:13%">Stock Adjustment Number</th>
                            <th class="font-weight-bold heading" style="width:12%">Date</th>
                            <th class="font-weight-bold heading" style="width:12%">Time</th>

                            <th class="font-weight-bold heading" style="width:11%">Store</th>
                            <th class="font-weight-bold heading" style="width:10%">Adjusted By</th>
                            <th class="font-weight-bold" style="width:5%">Action</th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 16px">
                        <tr class="" v-for="adjustment in details" :key="adjustment.id">
                            <td class="text-secondary py-3 row1" style="width: 265px">
                                {{ adjustment.batch_code }}
                            </td>
                            <td class="text-secondary py-3 row1">{{ adjustment.date }}</td>
                            <td class="text-secondary py-3 row1">
                                {{ timeConvert(adjustment?.time) }}
                            </td>
                            <td class="text-secondary py-3 row1">{{ adjustment.store?.store_name }}</td>
                            <td class="text-secondary py-3 row1">{{ adjustment.user.fullname }}</td>
                            <td class="py-3">
                                <div class="dropdown p-0">
                                    <button class="btn btn-default dropdown-toggle" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="background-color:#fff;padding:2px">
                                        Select
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <router-link class="dropdown-item" :class="permission.view_stock_adjustment === 0 ? 'disabled':''"
                                                :to="'/view-stock-adjustment/' + adjustment.id">View</router-link>
                                        </li>
                                        <li><a class="dropdown-item" href="#">Print</a></li>
                                        <li><a class="dropdown-item" href="#" :data-bs-toggle="permission.reverse_stock_adjustment === 1 ? 'modal':''" 
                                                data-bs-target="#singleModal" :class="permission.reverse_stock_adjustment === 1 ? '':'disabled'"
                                                @click="getCurrentId(adjustment.id)">Reverse</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
        <Alert :modalId="'singleModal'" @alertModal="handleAction()"
            :modalText="'Are you sure you want to reverse this stock adjustment'" :action="'alertModal'"
            title="Reverse Stock Adjustment"></Alert>

        <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
            @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
    </div>

</template>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";
import Alert from "../../../components/AlertModal.vue";

import axios from "axios";
import {
    useToast
} from "vue-toastification";

import jsPDF from "jspdf";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import "jspdf-autotable";
import {
    useStore
} from "vuex";
// import moment from 'moment';

export default {
    components: {
        InventoryHeaderTab,
        Alert

    },
    mixins: [reusableMixin],
    data() {
        let sortOrders = {};
        let columns = [{
            width: "16%",
            label: "Name",
            name: "name",
            sortable: false,
        },
        {
            width: "15%",
            label: "Sortcode",
            name: "sortcode",
            sortable: false,
        },
        {
            width: "15%",
            label: "Phonecode",
            name: "phonecode",
            sortable: false,
        },
        {
            width: "5%",
            label: "Status",
            name: "status",
            sortable: false,
        },
        {
            width: "5%",
            label: "Actions",
            name: "actions",
            sortable: false,
        },
        ];


        // Show date from the past 6 months
        var current_date = new Date();
        let current_date_to_date = current_date.setMonth(current_date.getMonth() - 6);
        current_date_to_date = new Date(current_date_to_date)

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            headerArrayColumn: ['S/N', 'Stock Adjustment Number', 'Store', 'Date', 'Adjusted By'],
            details: [],
            loading: false,
            query: null,
            print_content: [],

            selectedModel: [],
            allSelected: false,
            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                start_date: current_date_to_date,
                end_date: new Date(),
                draw: 0,
                length: 10,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            store: useStore(),
            crudLoading: false,
            currentId: null

        }
    },
    methods: {
        dataToPrint(details) {
            details.map((store, idx) => {
                let result = {
                    sn: idx + 1,
                    batch_code: store.batch_code,
                    date: store.date,
                    store: store.store?.store_name,
                    user: store.user.fullname
                };
                this.print_content.push(result)
            });
        },
        getCurrentId(id) {
            this.currentId = id
        },
        handleAction() {
            this.loading = true
            axios.put(`/inventory/store-adjustment/reverse/${this.currentId}`).then((response) => {
                this.loading = false
                if (response.status == 200) {
                    this.toast.success(response.data.message)
                    this.fetchData()
                }

            }).catch((error) => {
                this.loading = false
                this.toast.error(error?.response?.data?.message ?? 'Whoops, Something went wrong')
            })

        },
        exportToPdf() {
            const doc = new jsPDF();
            doc.autoTable({
                html: ".table"
            });
            doc.save("stockadjustments.pdf");
        },

        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },

        fetchData(url = '/inventory/store-adjustment/web') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                this.print_content = []
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.dataToPrint(this.details)
                this.configPagination(data.data);
            }).catch(error => {
                this.errorMessagem = error
                this.loading = false;
            });
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
    },

    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission
        }
    },

    watch: {
        query() {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style>
.allbuttonsclr {
    background: green !important;
    color: white !important;
}

.table-hamburger {
    cursor: pointer;
}

.toggle {
    position: absolute;
}

.innertoggle {
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}

.innertoggle:hover {
    color: rgb(25, 135, 84);
}

.viewselect {
    width: 95px;
    border: 1px solid rgb(255, 255, 255) !important;
    border: 1px solid rgb(247, 241, 241);
    --tw-shadow: 0 20px 30px -12px rgba(0, 0, 0, 0.45);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }
}
</style>
