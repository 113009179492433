<template>
  <ReportHeaderTab />

  <div class="mt-3 gridtop">
    <!-- <div class="mt-2 px-5" >Stock Adjustment</div> -->
    <div class="d-flex justify-content-between my-3">
      <div class="mt-2 fw-bold" style="font-size: 20px">
        <router-link to="/stock-adjustment-report"
          ><font-awesome-icon
            class="arrowradius ms-1"
            prefix="far"
            icon="angle-left"
        /></router-link>
        SA-123456
      </div>
      <div class="d-flex gridtop">
        <div class="me-2">
          <button
            class="btn px-5"
            style="background: white; border: 1px solid gray"
          >
            Download as PDF
          </button>
        </div>
        <div class="d-flex">
          <router-link to="/print-stock-adjustment">
            <button
              class="btn px-5 me-2"
              style="background: white; border: 1px solid gray"
            >
              Print 58mm
            </button>
          </router-link>
        </div>
        <div class="d-flex">
          <router-link to="/print-stock-adjustment">
            <button
              class="btn px-5 me-2"
              style="background: white; border: 1px solid gray"
            >
              Print 80mm
            </button>
          </router-link>
        </div>
        <div class="d-flex">
          <router-link to="/print-stock-adjustment">
            <button
              class="btn px-5 mr-2"
              style="background: white; border: 1px solid gray"
            >
              Print A4
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg mt-4 mb-3 px-4 py-3">
      <div
        class="d-flex justify-content-between my-5 headercreate"
        style="font-size: 15px"
      >
        <div class="text-secondary">
          Date: <span class="text-dark fw-bold">21st August 2021</span>
        </div>
        <div class="text-secondary">
          STOCK ADJUSTMENT NUMBER:
          <span class="text-dark fw-bold">SA-123456</span>
        </div>
        <div class="text-secondary">
          STORE: <span class="text-dark fw-bold">Surulere store</span>
        </div>
        <div class="text-secondary">
          ADJUSTED BY: <span class="text-dark fw-bold">Esther Jessica</span>
        </div>
      </div>
      <div class="">
        <table class="table table-sm-responsive">
          <thead class="thead-light" style="border-style: hidden !important">
            <tr class="pb-5 text-center">
              <th class="fw-bold">S/N</th>
              <th class="fw-bold">Products</th>
              <th class="fw-bold">Cost Price</th>
              <th class="fw-bold">Variant</th>
              <th class="fw-bold">Quantity</th>
              <th class="fw-bold">Reason</th>
              <th class="fw-bold">Adjustment Type</th>
              <th class="fw-bold">Total (₦)</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <th class="text-secondary">1</th>
              <td class="text-secondary">Yam</td>
              <td class="text-secondary">N200</td>
              <td class="text-secondary">Pieces</td>
              <td class="text-secondary">20</td>
              <td class="text-secondary">Mistakes with counting</td>
              <td class="text-secondary">Increasing</td>
              <td class="text-secondary">4000</td>
            </tr>
            <tr class="text-center">
              <th class="text-secondary">2</th>
              <td class="text-secondary">Yam</td>
              <td class="text-secondary">N200</td>
              <td class="text-secondary">Pieces</td>
              <td class="text-secondary">20</td>
              <td class="text-secondary">Mistakes with counting</td>
              <td class="text-secondary">Decreasing</td>
              <td class="text-secondary">(2000)</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4 d-flex justify-content-end">
        <div class="me-3 fw-bold" style="font-size: 19px">Grand Total:</div>
        <span style="font-size: 19px">₦2000</span>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeaderTab from "./components/reportheadertab.vue";
export default {
  components: { ReportHeaderTab },
};
</script>

<style></style>
