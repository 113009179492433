<template>
   <div class="footergrid footerpadding">
    <div class="mt-2">
      <div><img src="../assets/images/twitter.webp" alt="twitter"></div>
      <div class="py-5"><img src="../assets/images/fb.webp" alt=""></div>
      <div><img src="../assets/images/insta.png" alt=""></div>
    </div>
    <div>
      <div class="fw-bold mb-4 mt-2" style="font-size: 20px">Menu</div>
      <div class="mt-3">
          <router-link to="/home" class="link">
            Home
          </router-link>
      </div>
      <div class="mt-2">
          <router-link to="/pricing" class="link">
            Pricing
          </router-link>
      </div>
      <div class="mt-2">
          <router-link to="/terms-and-conditions" class="link">
            Terms and Conditions
          </router-link>
      </div>
      <div class="mt-2">
          <router-link to="/privacy-policy" class="link">
            Privacy policy
          </router-link>
      </div>
    </div>
    <div>
      <div class="fw-bold mb-4 mt-2" style="font-size: 20px">Address</div>
      <div class="mt-3">{{details.address}}</div>
      <!-- <div class="">Johnson Crescent. Surulere Lagos</div> -->
      <div class="my-2">{{details.tel}}</div>
      <div class="my-2">{{details.tel2}}</div>
      <div class="my-2">{{details.email}}</div>
      <div class="my-2">{{details.email2}}</div>
    </div>
    <div>
      <div class="fw-bold mb-4 mt-2" style="font-size: 20px">Stay up to date</div>
      <div class="my-3" style="position: relative">
        <input type="text" placeholder="Your email adddress" class="form-control bottominput icon-rtl">
        <!-- <img class="field-icon " src="../assets/images/btn.webp" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
        details: {}
    }
  },
  mounted() {
    this.pageData();
  },
  methods: {
    pageData() {
        axios.get("/admin/fetchProjectSetup").then((response) => {
            this.details = response.data.data;
        })
    }
  }
  
}
</script>

<style scoped>
.icon-rtl {
  padding-right: 25px;
  background: url("../assets/images/btn.webp") no-repeat 98%;
  background-size: 20px;
}
.link {
  text-decoration: none;
}

.footergrid {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr;
}

.footerpadding {
  padding-top: 50px;
  padding-bottom: 50px;
}

.bottominput {
  width: 255px !important;
}

@media (max-width: 767px) {
  .footergrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  .bottominput {
    width: 100% !important;
  }

  .lastsvg {
    right: 26px;
  }

  .field-icon {
    margin-right: 5px;
  }  
}
</style>