<template>
  <div class="container-fluid bg-white">
    <ul class="nav nav-tabs thetoppills py-2" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <router-link active-class="pillactive" to="all-sales" class="nav-link">
          <font-awesome-icon icon="coins" class="pillicons" /> Sales
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>