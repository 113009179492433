<template>
<div>
    <div class="tab-pane fade show active" id="allproduct" role="tabpanel" aria-labelledby="allproduct-tab">
         <div class="px-3 font-weight-bold gridtop">
        <div class="d-flex justify-content-between gridtop mb-2">
            <div class="d-flex dateinputs allsalestop">
                <div class="m-2" style="font-size: 20px">Creditors List</div>
            </div>
            <div class="d-md-flex allsalestop secondsalestop mt-1">
                <div class="input-group mb-0 me-2" style="width: 214px">
                    <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:2px">
                        <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                        <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px" v-model="query" />
                    </div>
                </div>
                <div class="dropdown">
                    <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                        Export
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a @click="drawPdf(print_content, headerArrayColumn,'l','creditors','Creditor')" class="dropdown-item" href="#">PDF</a>
                        </li>
                        <li>
                            <a @click="reusableMixinToCSV('creditors.csv')" class="dropdown-item" href="#">CSV</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
        <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
            <div class="text-center py-5" v-if="details.length === 0 && loading === false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your creditor list is empty!</div>
            </div>
            <div v-if="loading === true" class="text-center py-5">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="" v-if="loading === false && details.length > 0">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5">
                            <th class="font-weight-bold heading">Supplier</th>
                            <th class="font-weight-bold heading">Total Amount Due({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold heading">Total Amount Paid({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold heading">Total Balance({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="creditor in details" :key="creditor.id">
                            <td class="text-secondary py-3 row1">{{creditor?.supplier?.supplier_name}}</td>
                            <td class="text-secondary py-3 row1">
                                {{ $filters.formatMoney(creditor?.total_amount)  }}
                            </td>
                            <!-- <td class="text-secondary py-3 row1">₦<v-num #="{ number }" :value="creditor?.credits?.reduce(function (sum, creditor) {return parseFloat(sum) + creditor.amount_paid}, 0)">{{ number }}</v-num></td> -->
                            <td class="text-secondary py-3 row1">
                                {{ $filters.formatMoney(creditor?.amount_paid)  }}
                            </td>
                            <!-- <td class="text-secondary py-3 row1">₦<v-num #="{ number }" :value="creditor?.total_balance">{{ number }}</v-num></td> -->
                            <td class="text-secondary py-3 row1">
                                {{ $filters.formatMoney(creditor.total_amount- creditor?.credits?.reduce(function (sum, creditor) {return parseFloat(sum) + parseFloat(creditor.amount_paid)}, 0)) }}

                            </td>
                            <td class="py-3">
                                <div class="">
                                    <router-link class="text-dark" :to="'/user-credit/'+ creditor.supplier_id"> 
                                        <font-awesome-icon icon="eye" style="font-size: 15px" />
                                    </router-link>
                                </div>
                            </td>
                            </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)" @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>

</div>
</template>

<script>
import axios from 'axios';
import {
    useToast
} from 'vue-toastification';

import {
    reusableMixin
} from "../../../lib/reusableMixin";

export default {
    mixins: [reusableMixin],

    data() {
        let sortOrders = {};

        let columns = [{
                width: '16%',
                label: 'Name',
                name: 'name',
                sortable: false
            },
            {
                width: '15%',
                label: 'Sortcode',
                name: 'sortcode',
                sortable: false
            },
            {
                width: '15%',
                label: 'Phonecode',
                name: 'phonecode',
                sortable: false
            },
            {
                width: '5%',
                label: 'Status',
                name: 'status',
                sortable: false
            },
            {
                width: '5%',
                label: 'Actions',
                name: 'actions',
                sortable: false
            },

        ];

        // Show date from the past 6 months
        var current_date = new Date();
        let current_date_to_date = current_date.setMonth(current_date.getMonth() - 6);
        current_date_to_date = new Date(current_date_to_date)

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content: [],
            headerArrayColumn: ['S/N', 'Supplier Name', 'Total Amount Due','Total Amount Paid', 'Total Balance'],
            errorMessage: null,
            getsuppliers: '',
            details: [],
            loading: false,
            query: null,
            supplier_id: null,

            selectedModel: [],
            allSelected: false,

            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
              start_date: current_date_to_date,
                end_date: new Date(),
                draw: 0,
                length: 5,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            permission_status: null,
            crudLoading:null

        }
    },

    methods: {
        dataToPrint(details) {
            details.map((creditor, idx) => {
                let result = {
                    sn: idx + 1,
                    supplier_name: creditor?.supplier?.supplier_name,
                    total_amount: creditor?.total_amount,
                    amount_paid: creditor?.credits?.reduce(function (sum, creditor) {return parseFloat(sum) + creditor.amount_paid}, 0),
                    total_balance: creditor.total_amount- creditor?.credits?.reduce(function (sum, creditor) {return parseFloat(sum) + creditor.amount_paid}, 0)
                };
                this.print_content.push(result)
            });
        },

        fetchData(url = '/creditors') {
            this.loading = true;
            this.tableData.query = this.query
            this.crudLoading = true

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                this.crudLoading = false
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.dataToPrint(this.details)
                this.configPagination(data.data);
            }).catch(error => {
                this.crudLoading = false
                this.errorMessage = error
                this.loading = false;
                if (error?.response?.status === 403) {
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            });
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

    },

    watch: {
        query() {
            this.fetchData()
        }
    },
    mounted() {
        this.fetchData();
        this.getCompany();

    },

}
</script>

<style scoped>
@media (max-width: 280px) {
    .alldebitstop {
        display: flex !important;
        flex-direction: column !important;
    }
}

@media (max-width: 767px) {
    .thecard {
        /* width: 21rem; */
        width: 100%;
    }
}
</style>
