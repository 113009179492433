<template>
  <div class="pagebg">
    <div class="container d-flex justify-content-center authtop">
      <div class="row justify-content-center align-self-center">
        <div class="text-center"><h1>Password Reset</h1></div>
        <div class="w-100 text-center mb-4">Let's get you a new password</div>

        <div class="authbg"></div>

        <div class="card w-75" style="z-index: 26">
          <div class="text-center h5 mt-3">Password Reset</div>
          <div class="d-flex justify-content-center">
            <div class="">
              <div
                class="col-md-6 w-100"
                v-for="errors in validationErrors"
                :key="errors.id"
              >
                <div class="text-danger">{{ errors }}</div>
              </div>
            </div>
          </div>
          <form @submit.prevent="resetPasswordMail" class="px-3 pb-4 pt-2">
            <div class="mb-4">
              <!-- <span class="input-group-text bg-white pl-4" id="basic-addon1">
                <font-awesome-icon icon="envelope" class="fontinput" />
              </span> -->
              <label class="form-label labelform">Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control py-2"
                placeholder="Email Address"
              />
            </div>

            <div class="mt-5 text-center">
              <button
                v-if="loading === false"
                type="submit"
                class="btn btn-lg btn-block w-100 buttongreen"
              >
                Send Reset Link
              </button>
              <button
                v-if="loading === true"
                disabled
                type="submit"
                class="btn btn-lg btn-block w-100 buttongreen"
              >
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Processing</span>
                </div>
              </button>
            </div>
          </form>
          <div class="text-secondary text-center pt-3 pb-5">
            <router-link
              to="/auth/login"
              class="makeitgreen"
              style="text-decoration: none"
            >
              Back to Sign In
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-secondary d-flex justify-content-between bottom-content-container pb-3 thefooter">
      <div>© 2021, Made with <i class="fa fa-heart text-danger"></i> by <span class="text-success">Ikooba Technologies</span> for a better web</div>
      <div class="d-flex">
        <div class="bottom-footer-margin">Ikooba Tim</div>
        <div class="bottom-footer-margin">Creative</div>
        <div class="bottom-footer-margin">Blog</div>
        <div class="bottom-footer-margin">Licenses</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default {
  setup() {
    const email = ref("");
    const validationErrors = ref("");
    const toast = useToast();
    const loading = ref(false);
    const router = useRouter();

    const resetPasswordMail = async () => {
      loading.value = true;

      await axios
        .post("/send-reset-password-email-web", {
          email: email.value,
        })
        .then(() => {
          loading.value = false;
          router.push("/auth/password-reset-success?email=" + email.value);

          toast.success("Reset link has been sent to your mail");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toast.error("There is error in your input");
            validationErrors.value = error.response.data.message;
          }
          if (error.response.status === 403) {
            toast.error("Email not found!");
          }
          if (error.response.status === 500) {
            toast.error("An error occured, please try again!");
          }
          loading.value = false;
        });
    };

    return { email, resetPasswordMail, loading, validationErrors };
  },
};
</script>

<style scoped>
.pagebg {
  background: #f2fff8;
  position: relative;
  height: 100vh;
}

.authbg {
  background-image: url("../assets/images/Rectangle93.png") !important;
  background-repeat: no-repeat;
  position: absolute;
  /* margin-top: 5rem; */
  bottom: 0;
  /* background-position: center bottom; */
  height: 23rem;
  max-height: 20rem;
  width: 100% !important;
  z-index: 1;
  /* height: 100vh !important */
}

.card {
  border: none !important;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
}

.makeitgreen:hover {
  color: green !important;
}

@media (max-width: 1000px) {
  .authbg {
    display: none;
  }
}

.authtop {
  padding-top: 84px;
}

.inputborder {
  border-left: white;
}

.fontinput {
  font-size: 23px;
}

.footergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.bottom-content-container {
  padding-left: 113px;
  padding-right: 111px;
}

.bottom-footer-margin {
  margin-right: 14px;
  /* margin-left: 5px; */
}

.password-reset-success-icon {
  background: #9fffcc;
  border-radius: 999px;
}

.thefooter {
  margin-top: 84px;
}

.thealtfooter {
  margin-top: 100px;
}

.buttongreen {
  background: green !important;
  color: white !important;
}

@media (max-width: 767px) {
  .bottom-content-container {
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
}
</style>
