<template>
  <ReportHeaderTab />
  <div class="active">
    <div class="mt-3 fw-bold gridtop">
      <div class="d-flex justify-content-between gridtop mb-2">
        <div class="mt-2" style="font-size: 24px">Customer Report</div>
         <div class="d-flex allformstop">
        <!--  <div class="d-flex">
            <div class="reportDate" style="width: 160px">
              <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control"
                style="background: none; border: none" />
            </div>
            <div class="reportDate me-1" style="width: 160px">
              <Datepicker v-model="tableData.end_date" :enableTimePicker="false" class="form-control"
                style="background: none; border: none" />
            </div>
          </div>
          <div class="d-flex selectstore">
            <div class="input-group mb-4 me-3" id="stores" style="width: 149px;font-weight:400">
              <Multiselect v-model="tableData.store" label="name" :options="getStore" placeholder="--Stores--"
                :searchable="true" :create-option="false" :close-on-select="true" />
            </div>
          </div>

          <div class="topreportbtn">
            <button type="submit" data-bs-dismiss="modal" class="btn btn-success allbuttonsclr text-white"
              v-if="crudLoading === false" @click="fetchData()">
              Submit
            </button>
            <button v-if="crudLoading == true" class="btn btn-success allbuttonsclr text-white" type="button"
              @click="fetchData()" disabled>
              <span class="">Processing...</span>
            </button>
          </div>-->
        </div> 
        <div class="d-flex searchexportrpts">
          <div class="input-group mb-4 me-2" style="width: 214px">
            <div class="d-flex align-items-center small ps-5 me-2" styfle="margin-top:2px">
              <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
              <input type="text" class="form-control" placeholder="Search customer name" style="padding-left: 38px"
                v-model="query" />
            </div>
          </div>
          <div>
            <div class="dropdown">
              <button class="btn exportbtn me-2 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                Export
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a @click="drawPdf(print_content, headerArrayColumn, 'l', 'customer-sales', 'Customer Sales', '', footerRowArray)"
                    class="dropdown-item" href="#">PDF</a>
                </li>
                <li>
                  <a @click="reusableMixinToCSV('Customer-sales')" class="dropdown-item" href="#">CSV</a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card salescard rounded-lg mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
      <div v-if="loading === true" class="text-center py-5">
        <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="text-center py-5" v-if="details.length === 0 && loading === false">
        <div>
          <img src="../../private-assets/images/emptypng.png" alt="" />
        </div>
        <div class="fw-bold mt-3">Your Customer sales list is empty!</div>
      </div>
      <div class="card-toolbar" v-if="loading === false && details.length > 0">
        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

          <div class="d-flex align-items-center py-1" style="margin-right: 10px">
            <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option :value="pagination.total">All</option>
            </select>
          </div>
          <!--begin::Actions-->

        </div>
      </div>
      <div v-if="loading === false && details.length > 0">
        <table class="table table-sm-responsive">
          <thead class="thead-white" style="border-style: hidden !important">
            <tr class="pb-5">
              <th class="fw-bold heading">Customer Name</th>
              <th class="fw-bold heading">Transaction</th>
              <th class="fw-bold heading">Total Amount({{ company?.currency?.symbol }})</th>
              <th class="fw-bold">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="customer in details" :key="customer.id">
              <td class="text-secondary row1">{{ customer?.customer_name }}</td>
              <td class="text-secondary row1">{{ customer?.transactions }}</td>
              <td class="text-secondary row1">{{  $filters.formatMoney(customer?.total_amt)  }}</td>
              <td class="">
                <router-link class="text-success" :to="'/user-customer-report/' + customer?.id+'?store='+tableData.store"
                  style="text-decoration: none">View</router-link>
              </td>
            </tr>
            <tr class="fw-bold">
              <td class="row1">GRAND TOTAL</td>
              <td class="row1">
                <v-num #="{ number }" :value="total_transaction">{{ number }}</v-num>
              </td>
              <td class="row1">{{ $filters.formatMoney(total_amount)  }}</td>
              <td class="row1"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
      @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

import { reusableMixin } from "../../../lib/reusableMixin";

import { useStore } from "vuex";
// import Multiselect from "@vueform/multiselect";
import ReportHeaderTab from "./components/reportheadertab.vue";
export default {
  components: {
    ReportHeaderTab,
    // Multiselect,
  },
  mixins: [reusableMixin],
  data() {
    let sortOrders = {};
    let columns = [
      {
        width: "16%",
        label: "Name",
        name: "name",
        sortable: false,
      },
      {
        width: "15%",
        label: "Sortcode",
        name: "sortcode",
        sortable: false,
      },
      {
        width: "15%",
        label: "Phonecode",
        name: "phonecode",
        sortable: false,
      },
      {
        width: "5%",
        label: "Status",
        name: "status",
        sortable: false,
      },
      {
        width: "5%",
        label: "Actions",
        name: "actions",
        sortable: false,
      },
    ];

    // Show date from the past 6 months
    // var current_date = new Date();
    // let current_date_to_date = current_date.setMonth(
    //   current_date.getMonth() - 6
    // );
    // current_date_to_date = new Date(current_date_to_date);

    // columns.forEach((column) => {
    //   sortOrders[column.name] = -1;
    // });


    return {
      print_content: [],
      headerArrayColumn: [
        "S/N",
        "Customer Name",
        "Transactions",
        "Total Amount",
      ],
      footerRowArray: [],

      details: [],
      paymentTypes: [],
      loading: false,
      query: null,

      selectedModel: [],
      allSelected: false,
      columns: columns,
      sortKey: "id",
      sortOrders: sortOrders,
      tableData: {
        store: "All",
        start_date: null, //current_date_to_date,
        end_date: null, //new Date(),
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "desc",
        id: "",
        status: [0, 1, 2, 3],
        query: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      toast: useToast(),
      currentID: null,
      store: useStore(),
      crudLoading: false,
      total_transaction: 0,
      total_amount: 0,

    };
  },
  methods: {
    grandTotal(item) {
      this.total_transaction = item.reduce(function (sum, t) {
        return sum + t.transactions
      }, 0);

      this.total_amount = item.reduce(function (sum, t) {
        return (parseFloat(sum) + parseFloat(t.total_amt)).toFixed(2)
      }, 0);
    },

    dataToPrint(details) {
      details.map((sales, idx) => {
        let result = {
          sn: idx + 1,
          customer_name: sales.customer_name,
          transactions: sales.transactions,
          total_amount: this.$filters.formatMoney(sales.total_amt),
        };
        this.print_content.push(result);
      });
      this.footerRowArray = []
      this.footerRowArray.push("", "GRAND TOTAL", this.total_transaction, this.$filters.formatMoney(this.total_amount))
    },

    selectAll() {
      if (this.allSelected) {
        const selected = this.details.map((u) => u.id);
        this.selectedModel = selected;
      } else {
        this.selectedModel = [];
      }
    },

    fetchData(url = "/report/customer-report") {
      this.loading = true;
      this.crudLoading = true;
      this.tableData.query = this.query;

      if (this.crudLoading && this.query) {
        this.crudLoading = false;
      }

      this.tableData.draw++;
      axios
        .get(url, {
          params: this.tableData,
        })
        .then((response) => {
          this.print_content = [];
          if (!response.data.status == 200) {
            this.loading = false;
            return (this.errorMessage = "Could not fetch data");
          }
          this.loading = false;
          this.crudLoading = false;
          let data = response.data;

          this.details = data.data.customers.data;
          this.grandTotal(data.data.customers2)
          this.dataToPrint(this.details);
          this.configPagination(data.data.customers);
        })
        .catch((error) => {
          this.crudLoading = false;
          this.errorMessagem = error;
          this.loading = false;
        });
    },

    timeConvert(time) {
      // Check correct time format and split into components
      if (time) {
        time = time
          .toString()
          .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
          // If time format correct
          time = time.slice(1); // Remove full string match value
          time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
      }
      return time;
    },

    computed: {
      permission() {
        return this.store.state.getCurrentUserPermission;
      },
    },

    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
  },

  watch: {
    query() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.allStore();
    this.getCompany();
  },
};
</script>
  

<style scoped>
.reportDate {
  margin-top: -6px;
}

.salestop {
  margin-left: -12px;
}

@media (max-width: 767px) {
  .allformstop {
    flex-direction: column;
  }

  .salessubmitdate {
    margin-left: 16px;
  }

  .salescard {
    width: 21rem;
  }

  .selectstore {
    margin-left: 12px;
  }

  #stores {
    width: 140px !important;
  }

  #right-store {
    margin-left: 4px;
  }

  .topreportbtn {
    margin-left: 13.5px;
    margin-top: -15px;
    margin-bottom: 8px;
  }

  .searchexportrpts {
    margin-left: -33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .allformstop {
    flex-direction: column;
  }

  .salessubmitdate {
    margin-left: 10px;
  }

  .allformstop {
    flex-direction: row;
  }

  .nexttopform {
    flex-direction: column;
  }

  .searchexportrpts {
    margin-left: -33px;
  }
}
</style>
