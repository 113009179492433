<template>
  <SettingHeaderTab />

  <div class="mt-5 mb-3 bg-white rounded-lg px-3 py-3">
    <div class="">
      <div>
        <h4>Change Password</h4>
        <div class="text-muted" style="font-size: 10px">
          You can change your password here
        </div>
      </div>
      <div class="row mx-2" v-if="Array.isArray(validationErrors)">
        <ul
          class="col-md-4"
          v-for="errors in validationErrors"
          :key="errors.id"
        >
          <li class="text-danger" style="list-style: none">{{ errors }}</li>
        </ul>
      </div>
      
      <div class="row mx-2" v-else>
        <ul class="col-md-4">
          <li class="text-danger" style="list-style: none">{{validationErrors}}</li>
        </ul>
      </div>
      <form class="mt-4" @submit.prevent="changePassword">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="inputEmail4">Current Password</label>
            <input
              type="text"
              class="form-control"
              placeholder="*******"
              v-model="password"
            />
            <p class="form-text text-danger" v-if="validationErrors?.password">
              {{ validationErrors.password[0] }}
            </p>
          </div>
          <div class="form-group col-md-4">
            <label>New Password</label>
            <input
              type="text"
              class="form-control"
              placeholder="*******"
              v-model="new_password"
            />
            <p
              class="form-text text-danger"
              v-if="validationErrors?.new_password"
            >
              {{ validationErrors.new_password[0] }}
            </p>
          </div>
          <div class="form-group col-md-4">
            <label>Confirm New Password</label>
            <input
              type="text"
              class="form-control"
              placeholder="*******"
              v-model="password_confirmation"
            />
            <p
              class="form-text text-danger"
              v-if="validationErrors?.password_confirmation"
            >
              {{ validationErrors.password_confirmation[0] }}
            </p>
          </div>
        </div>
        <div class="text-center mt-3">
          <button
            v-if="loading === false"
            type="submit"
            class="btn buttongreen btn-lg btn-block w-25 rounded-3"
          >
            Change
          </button>
          <button
            v-if="loading === true"
            disabled
            type="submit"
            class="btn buttongreen btn-lg btn-block w-25 rounded-3"
          >
            <div class="spinner-grow text-info" role="status">
              <span class="visually-hidden">Processing</span>
            </div>
          </button>
        </div>
      </form>

      <!-- <div class="mt-5">
        <h4>Change Email</h4>
        <div class="text-muted" style="font-size: 10px;">You can change your Email Address here</div>
      </div>
      <form class="mt-4">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="inputEmail4">Current Email</label>
            <input type="text" class="form-control" placeholder="Enter current email address">
          </div>
          <div class="form-group col-md-4">
            <label>New Email</label>
            <input type="text" class="form-control" placeholder="Enter new email address">
          </div>
          <div class="form-group col-md-4">
            <label>Confirm New Email</label>
            <input type="text" class="form-control" placeholder="Confirm new email address">
          </div>
        </div>
        <div class="text-center mt-3">
          <button type="submit" class="btn px-5 mr-5 btn-success text-white">Change</button>
        </div>
      </form> -->
    </div>
  </div>
</template>

<script>
import SettingHeaderTab from "./components/settingheadertab.vue";

import axios from "axios";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ref } from "@vue/reactivity";
export default {
  components: { SettingHeaderTab },

  setup() {
    const password = ref("");
    const new_password = ref("");
    const password_confirmation = ref("");
    const validationErrors = ref([]);

    const router = useRouter();
    const toast = useToast();
    const loading = ref(false);

    const clearFields = () => {
      password.value = "";
      new_password.value = "";
      password_confirmation.value = "";
      validationErrors.value = [];
    };
    const changePassword = async () => {
      loading.value = true;
      await axios
        .post("/user/change-password", {
          password: password.value,
          new_password: new_password.value,
          password_confirmation: password_confirmation.value,
        })
        .then((response) => {
          loading.value = false;
          clearFields();
          localStorage.removeItem("token");
          localStorage.clear()
          router.push("/auth/login");
          toast.success(response?.data?.message);
        })
        .catch((error) => {
          clearFields();

          if (error.response.status === 422) {
            toast.warning("Your inputs are not valid, kindly recheck");
            validationErrors.value = error.response.data.message;
          }
          if (error.response.status === 500) {
            toast.error("Please recheck your login details");
          }
          loading.value = false;
        });
    };

    return {
      changePassword,
      password,
      new_password,
      password_confirmation,
      router,
      toast,
      loading,
      validationErrors,
    };
  },
};
</script>

<style></style>
