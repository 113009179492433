<template>
  <div>
    <div class="pagebg">
      <div class="authtop">
        <div class="text-center">
          <div class="mt-1">
            <h1>Create an Account</h1>
          </div>
          <div class="w-100 text-center mb-4">
            Already have an Account?
            <router-link to="/auth/login" class="text-success fw-bold" style="text-decoration: none">Login
              here</router-link>
          </div>
        </div>
        <div class="authbg"></div>
        <div class="d-flex justify-content-center" style="z-index: 26; position: relative">
          <div class="card w-75">
            <div class="card-title text-center fw-bold pt-3">Register with</div>
            <div class="row mx-2">
              <ul class="col-md-4" v-for="(errors, index) in validationErrors" :key="index">
                <li class="text-danger">{{ errors }}</li>
              </ul>
            </div>
            <form @submit.prevent="registerUser" class="card-body">
              <div class="row">
                <div class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">First Name</label>
                    <input name="first_name" v-model="first_name" type="text" class="form-control py-2"
                      placeholder="First Name" />
                  </div>
                </div>
                <div class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label">Last Name</label>
                    <input name="last_name" v-model="last_name" type="text" class="form-control py-2"
                      placeholder="Last Name" />
                  </div>
                </div>
                <div class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">Company Name</label>
                    <input name="company_name" v-model="company_name" type="text" class="form-control py-2"
                      placeholder="Company Name" />
                  </div>
                </div>
                <div class="mb-4 col-md-6">
                  <div class="">
                    <!-- <span class="-text bg-white pl-4" id="basic-addon1">
                      <font-awesome-icon class="fontinput" icon="store" />
                    </span> -->
                    <label class="form-label labelform">Store Name</label>
                    <input name="store_name" v-model="store_name" type="text" class="form-control py-2"
                      placeholder="Store Name" />
                  </div>
                </div>
                <div class="mb-4 col-md-6">
                  <div class="">
                    <!-- <span class="-text bg-white pl-4" id="basic-addon1">
                      <font-awesome-icon icon="envelope" class="fontinput" />
                    </span> -->
                    <label class="form-label labelform">Email Address</label>
                    <input v-model="email" name="email" type="text" class="form-control py-2"
                      placeholder="Email Address" />
                  </div>
                </div>
                <div class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">Phone</label>
                    <vue-tel-input :value="phone" @input="onInput"></vue-tel-input>
                    <!-- <input
                      v-model="phone"
                      type="text"
                      class="form-control py-2"
                      placeholder="Phone Number"
                    /> -->
                  </div>
                </div>
                <div v-if="showPassword" class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">Password</label>
                    <input name="password" v-model="password" type="password" class="form-control py-2"
                      placeholder="Password" />
                    <font-awesome-icon style="cursor: pointer" @click="togglePasswordField" v-if="showPassword"
                      class="field-icon" icon="eye-slash" />
                  </div>
                </div>
                <div v-else class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">Password</label>
                    <input name="password" v-model="password" type="text" class="form-control py-2"
                      placeholder="Password" />
                    <font-awesome-icon style="cursor: pointer" @click="togglePasswordField" v-if="!showPassword"
                      class="field-icon" icon="eye" />
                  </div>
                </div>
                <div v-if="showConfirmPassword" class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">Confirm Password</label>
                    <input name="password_confirmation" v-model="password_confirmation" type="password"
                      class="form-control py-2" placeholder="Confirm Password" />
                    <font-awesome-icon style="cursor: pointer" @click="togglePasswordConfirmField"
                      v-if="showConfirmPassword" class="field-icon" icon="eye-slash" />
                  </div>
                </div>
                <div v-else class="mb-4 col-md-6">
                  <div class="">
                    <label class="form-label labelform">Confirm Password</label>
                    <input v-model="password_confirmation" type="text" class="form-control py-2"
                      placeholder="Confirm Password" />
                    <font-awesome-icon style="cursor: pointer" @click="togglePasswordConfirmField"
                      v-if="!showConfirmPassword" class="field-icon" icon="eye" />
                  </div>
                </div>
              </div>
              <!-- <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >I agree to the terms and conditions</label>
              </div> -->
              <div class="mt-5 text-center">
                <button v-if="loading === false" type="submit" class="btn buttongreen btn-lg btn-block w-75 rounded-3">
                  Sign Up
                </button>
                <button v-if="loading === true" type="submit" class="btn buttongreen btn-lg btn-block w-75 rounded-3">
                  <div class="spinner-grow text-info" role="status">
                    <span class="visually-hidden">Processing</span>
                  </div>
                </button>
              </div>

              <!-- <div class="text-secondary text-center pb-3 mt-3">
                Already have an Account? <router-link to="/auth/login" class="text-success">Sign in</router-link>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";

export default {
  setup() {
    const first_name = ref("");
    const last_name = ref("");
    const company_name = ref("");
    const store_name = ref("");
    const email = ref("");
    const phone = ref("");
    const password = ref("");
    const password_confirmation = ref("");
    const validationErrors = ref("");
    const toast = useToast();
    const router = useRouter();
    const loading = ref(false);
    const countries = ref([]);
    const showPassword = ref(true);
    const showConfirmPassword = ref(true);

    const togglePasswordField = () => {
      showPassword.value = !showPassword.value;
      console.log(showPassword.value);
    };

    const togglePasswordConfirmField = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const onInput = (_, phoneObject) => {
      if (phoneObject?.formatted) {
        phone.value = phoneObject.number;
      }
    };

    const registerUser = async () => {
      loading.value = true;
      await axios
        .post("/register-web", {
          first_name: first_name.value,
          last_name: last_name.value,
          company_name: company_name.value,
          email: email.value,
          phone_no: phone.value,
          password: password.value,
          store_name: store_name.value,
          password_confirmation: password_confirmation.value,
        })
        .then((res) => {
          loading.value = false;
          router.push("/auth/send-email-verifcation");
          toast.success(res.data.message);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toast.error("There are errors in your input");
            validationErrors.value = error.response.data.message;
          }
          if (error.response.status === 500) {
            toast.error("An error occured please try again");
          }
          loading.value = false;
        });
    };
    const getAllCountries = async () => {
      const response = await axios.get("countries");
      countries.value = response.data.data.data;
      loading.value = false;
    };
    onMounted(getAllCountries());

    return {
      first_name,
      last_name,
      company_name,
      store_name,
      email,
      phone,
      password,
      password_confirmation,
      validationErrors,
      registerUser,
      loading,
      countries,
      togglePasswordField,
      togglePasswordConfirmField,
      showPassword,
      showConfirmPassword,
      onInput,
    };
  },
};
</script>

<style scoped>
.pagebg {
  background: #f2fff8;
  position: relative;
  height: 100vh;
  padding-bottom: 100px;
}

.authbg {
  background-image: url("../assets/images/Rectangle93.png") !important;
  background-repeat: no-repeat !important;
  position: absolute;
  margin-top: 10rem;
  bottom: -10;
  height: 40rem;
  width: 100% !important;
  z-index: 1;
}

.card {
  border: none !important;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
}

.labelform {
  color: #35413b;
}

.authtop {
  padding-top: 50px;
}

.inputborder {
  border-left: white;
}

.fontinput {
  font-size: 23px;
}

.footergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.bottom-content-container {
  padding-left: 113px;
  padding-right: 111px;
}

.bottom-footer-margin {
  margin-right: 14px;
  /* margin-left: 5px; */
}

.password-reset-success-icon {
  background: #9fffcc;
  border-radius: 999px;
}

.thefooter {
  margin-top: 84px;
}

.thealtfooter {
  margin-top: 100px;
}

.buttongreen {
  background: green !important;
  color: white !important;
}

ul {
  list-style-type: none;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -27px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

.second-field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -27px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

@media screen and (max-width: 550px) {
  .authbg {
    margin-top: 38rem;
    bottom: -10;
    height: 40rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .authbg {
    margin-top: 23rem;
    bottom: -10;
    height: 30rem !important;
  }
}

@media (max-width: 767px) {
  .bottom-content-container {
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
}
</style>
