<template>

  <div class="gridtop">
    <!-- <div class="d-flex justify-content-between">
      <div class="mt-1 fw-bold" style="font-size:20px">
        <router-link to="/all-purchases">
          <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
        </router-link>
        {{ purchase?.batch_code }}
      </div>
      <PrintButtonHeader :title="'Purchase-Invoice-' + purchase?.batch_code" :urlA4="'/purchase-receipt/'"
        :url58mm="'/print58mm-purchase-receipt/'" :url80mm="'/print80mm-purchase-receipt/'"
        :currentUrl="'View Purchase Receipt'" :data="purchase" :type="'Purchase Receipt'" />
    </div> -->
    <!-- <PrintInvoice /> -->
    <PrintInvoice :title="'Purchase-Invoice-'" :urlA4="'/purchase-receipt/'"
            :url58mm="'/print58mm-purchase-receipt/'" :url80mm="'/print80mm-purchase-receipt/'"
            :currentUrl="'View Purchase Receipt'" :data="purchase" :type="'Purchase Receipt'"
            :purchase_items="purchase_items" :purchase_array="purchase_array" 
            :purchase="purchase"
        />
   
    
  </div>
</template>




<script>
import {
  useToast
} from "vue-toastification";
import axios from "axios";
import {
  reusableMixin
} from "../../../lib/reusableMixin";
import {
  useStore
} from "vuex";
import PrintInvoice from '../purchase/components/print-invoice.vue'


export default {
  components: {
        PrintInvoice
    },
  mixins: [reusableMixin],
  data() {
    return {
      toast: useToast(),
      purchase: null,
      grand_total: 0,
      tax_amount: 0,
      purchase_items: [],
      purchase_array: [],
      store: useStore()
    }
  },
  computed: {
    totalQty() {
      let qty = this.purchase_items.reduce((a, b) => {
        return a + b.unit_quantity
      }, 0);
      return qty
    },
  },
  methods: {
    getPurchase() {
      axios.get(`/inventory/purchase/${this.$route.params.id}`).then((response) => {
        this.purchase = response.data.data
        this.purchase_items = response.data.data.purchase_item
        this.purchase_items.forEach((s) => {
          this.purchase_array.push({
            "product_name": s?.product?.product_name,
            "cost_price": s?.cost_price,
            "variant_name": s?.variant?.variant_name ?? s?.product?.unit_of_measurement,
            "variant": s?.variant,
            "quantity": s?.quantity,
            "unit_quantity": s?.unit_quantity,
            "total": parseFloat(s?.amount_paid)
          })

        })

      }).catch(() => {
        this.toast.error("Oops, Something went wrong")
      })
    },

  },


  created() {
    this.getPurchase()
  }

}
</script>


<style>
@media (max-width: 767px) {
  .printpurchasebtns {
    margin-top: 10px;
  }
}
</style>