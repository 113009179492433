<template>
  <ReportHeaderTab />
  <div class="mt-3 px-3 gridtop">
    <div class="mt-2 px-5">Stock Adjustment</div>
    <div class="d-flex justify-content-between px-5">
      <div class="mt-2" style="font-size: 20px">
        <span
          class="iconify arrowradius"
          data-icon="ant-design:arrow-left-outlined"
        ></span>
        SA-123456
      </div>
      <div class="dgridtop">
        <div class="me-2">
          <select class="form-select">
            <option selected>Download as PDF</option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="bg-white rounded-lg mt-3 mx-3 mb-3 px-4 py-3 cardpadding"
      style="overflow-x: auto"
    >
      <div
        class="d-flex justify-content-between my-5 headercreate"
        style="font-size: 15px"
      >
        <div class="text-secondary">
          Date: <span class="text-dark fw-bold">21st August 2021</span>
        </div>
        <div class="text-secondary">
          STOCK ADJUSTMENT NUMBER:
          <span class="text-dark fw-bold">SA-123456</span>
        </div>
        <div class="text-secondary">
          STORE: <span class="text-dark fw-bold">Surulere store</span>
        </div>
        <div class="text-secondary">
          ADJUSTED BY: <span class="text-dark fw-bold">Esther Jessica</span>
        </div>
      </div>
      <div class="">
        <table class="table table-sm-responsive">
          <thead class="thead-light">
            <tr class="pb-5 text-center">
              <th class="text-secondary font-weight-bold">Available Qty</th>
              <th class="text-secondary font-weight-bold">Products</th>
              <th class="text-secondary font-weight-bold">Cost Price</th>
              <th class="text-secondary font-weight-bold">Variant</th>
              <th class="text-secondary font-weight-bold">Quantity</th>
              <th class="text-secondary font-weight-bold">Reason</th>
              <th class="text-secondary font-weight-bold">Adjustment Type</th>
              <th class="text-secondary font-weight-bold">Total (N)</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <td class="text-secondary">Yam</td>
              <td class="text-secondary">N200</td>
              <td class="text-secondary">Pieces</td>
              <td class="text-secondary">20</td>
              <td class="text-secondary">Mistakes with counting</td>
              <td class="text-secondary">Increasing</td>
              <td class="text-secondary">4000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeaderTab from "./components/reportheadertab.vue";
export default {
  components: { ReportHeaderTab },
};
</script>

<style></style>
