<template>
    <div class="mt-3 px-3 gridtop">
        <div class="d-flex justify-content-between">
            <div class="mt-2 fw-bold" style="font-size: 20px">
                <router-link :to="'/user-debtor/' + debtor?.customer?.id">
                    <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
                </router-link>
                {{ debtor_debts?.debtor?.batch_code }}
            </div>
            <PrintButtonHeader :title="'Invoice-' + debtor?.batch_code" :urlA4="'/debtor-receipt/'"
            :url58mm="'/print58mm-debtor-receipt/'" :url80mm="'/print80mm-debtor-receipt/'"
            :currentUrl="'View debtor Receipt'" :data="debtor_debts" :type="'Debtor Receipt'" :loading="loading"/>
        </div>
    </div>
    <div class="row pl-3 pr-3">
        <div class="bg-white rounded-lg mt-3 mx-3 mb-3 px-4 py-3 print-sales-shadow col-md-8" style="overflow-x: auto">
            <div id="printMe">
                <div class="d-flex justify-content-between mb-5">
                    <div class="fw-bold" style="font-size: 45px">Invoice</div>
                    <div>
                        <img class="invoicelogo" src="../../../assets/images/logo.png" />
                    </div>
                </div>
    
                <div class="d-flex justify-content-between mb-5">
                    <div>
                        <div class="fw-bold">Customer</div>
                        <div class="fw-bold" style="text-transform: uppercase">
                            {{ debtor_debts?.debtor?.customer?.customer_name }}
                        </div>
                        <div class="">{{ debtor_debts?.debtor?.customer?.address }}</div>
                        <div class="">{{ debtor_debts?.debtor?.customer?.email }}</div>
                    </div>
                    <div>
                        <div class="fw-bold">{{ loggedInUser?.business?.company_name }}</div>
                        <div class="">{{ loggedInUser?.business?.address }}</div>
                        <div class="mt-1">{{ loggedInUser?.business?.phone }}</div>
                    </div>
                </div>

                <table class="table table-sm-responsive">
                    <thead class="thead" style="border-style: hidden !important;">
                        <tr class="center">
                            <th class="fw-bold" style="font-size: 14px;">Sales Date</th>
                            <th class="fw-bold" style="font-size: 14px;">Sales No</th>
                            <th class="fw-bold" style="font-size: 14px;">Store</th>
                            <th class="fw-bold" style="font-size: 14px;">Sold By</th>
                        </tr>
                    </thead>
                    <tbody style="border-style: hidden !important;" v-if="!loading">
                        <tr class="center">
                            <td>{{ dateTime(sales?.date) }}</td>
                            <td>{{ sales?.batch_code }}</td>
                            <td>{{ debtor?.store?.store_name }}</td>
                            <td>{{ debtor?.user?.fullname }}</td>
                        </tr>
                    </tbody>
                </table>
                
                <div class="table-scroll">
                    <!-- {{debtor_debts}} -->
                    <table class="table table-hover table-strip" style="font-size: 12px">
                        <thead class="bg-light">
                            <tr class="">
                                <th class="text-secondary" style="font-size: 12px;width:20%">PRODUCT</th>
                                <th class="text-secondary" style="font-size: 12px">VARIANT</th>
                                <th class="text-secondary" style="font-size: 12px">QTY</th>
                                <th class="text-secondary" style="font-size: 12px">PRICE({{ company?.currency?.symbol }})</th>
                                <th class="text-secondary" style="font-size: 12px">DISCOUNT({{ company?.currency?.symbol }})</th>
                                <th class="text-secondary" style="font-size: 12px">TAX({{ company?.currency?.symbol }})</th>
                                <th class="text-secondary" style="font-size: 12px">AMOUNT({{ company?.currency?.symbol }})</th>
                            </tr>
                        </thead>
                        <tbody v-if="!loading">
                            <tr class="center" v-for="p in sales_array" :key="p.id">
                                <td>{{ p?.product_name }}</td>
                                <td>{{ p?.variant_name  }}</td>
                                <td>{{ p?.quantity }}</td>
                                <td>{{ $filters.formatMoney(p?.selling_price)  }} </td>

                                <td v-if="p.discount">{{ $filters.formatMoney(p?.discount) }}</td>
                                <td v-else>{{ "N/A" }}</td>
    
                                <td v-if="p.tax">{{ $filters.formatMoney(p?.tax) }}</td>
                                <td v-else>{{ "N/A" }}</td>
                                <td>{{ $filters.formatMoney(p?.total) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center" style="margin:50px" v-if="loading">
                        <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                        </div>
                    </div>
                </div>
                <div class="topd d-flex justify-content-end" v-if="!loading">
                    <div style="width: 300px">
                        <div class="d-flex justify-content-between">
                            <div class="fw-bold subtotal">SUBTOTAL</div>
                            <div class="leftsub">{{ $filters.formatMoney(subTotal, company?.currency?.symbol) }}</div>
                        </div>
    
                        <div class="d-flex justify-content-between">
                            <div class="fw-bold taxmargin">TAX</div>
                            <div class="">{{$filters.formatMoney(taxTotal, company?.currency?.symbol) }}</div>
                        </div>
    
                        <div class="d-flex justify-content-between">
                            <div class="fw-bold subtotal">DISCOUNT</div>
                            <div class="">{{ $filters.formatMoney(discountTotal, company?.currency?.symbol) }}</div>
                        </div>
    
                        <div class="d-flex justify-content-between">
                            <div class="fw-bold totalmargin">TOTAL</div>
                            <div class="">{{ $filters.formatMoney(grandTotal, company?.currency?.symbol) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="!loading">
                <hr class="firsthr" />

            <div class="fw-bold">Payment Information</div>
            <div>
                <div class="d-flex justify-content-between">
                    <div>Payment Type</div>
                    <div class="fw-bold" style="text-transform: capitalize">
                        {{ sales?.payment_type  == 'part' ?  'Part Payment' : sales?.payment_type }}
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Paid Amount</div>
                    <div class="fw-bold">{{ $filters.formatMoney(debtor_debts?.amount_paid, company?.currency?.symbol)  }}</div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Balance</div>
                    <div class="fw-bold" v-if="debtor_debts?.amount_owned !== null">
                        {{ $filters.formatMoney(debtor_debts?.amount_owned, company?.currency?.symbol)  }}
                    </div>
                    <div class="fw-bold" v-else>
                        {{ $filters.formatMoney(0, company?.currency?.symbol)  }}
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col mt-3">
            <div class="card rounded-lg">
                <div class="mx-2 pb-5 pt-2">
                    <div class="fw-bold fs-4">Record Receipt</div>
                    <div class="mt-3">
                        <label class="mb-2">Payment Type</label>
                        <select class="form-select" v-model="payment_type" :disabled="permission.create_record_receipts != 1">
                            <option selected disabled>Select Payment Type</option>
                            <option value="cash">Cash</option>
                            <option value="card">Card</option>
                        </select>
                    </div>
                    <div class="form-group mt-3">
                        <label class="font-weight-bold mb-2">Payment Amount</label>
                        <input type="text" onkeypress="return /^[0-9.]+$/i.test(event.key)" class="form-control" v-model="amount" @keyup="handleAmount()"  :disabled="permission.create_record_receipts != 1"/>
                    </div>
                    <div class="form-group mt-3">
                        <label class="mb-2">Balance</label>
                        <input disabled type="number" class="form-control" v-model="balance" />
                    </div>
                    <div class="mt-3">
                        <button class="btn allbuttonsclr" v-if="crudLoading === false" @click.prevent="makePayment" :disabled="permission.create_record_receipts != 1">
                            Record Receipt
                        </button>
                        <button class="btn allbuttonsclr" v-else disabled>
                            Processing
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import { reusableMixin } from "../../../lib/reusableMixin";
import { useStore } from "vuex";
// import Invoice from '../sales/component/invoice'
import { useRouter } from "vue-router";

export default {
    // components:{
    //   Invoice
    // },
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            router: useRouter(),
            debtor_debts: null,
            grand_total: 0,
            tax_amount: 0,
            debtor: [],
            debtor__array: [],
            store: useStore(),
            salesId: null,
            sales_items: [],
            sales: null,
            sales_array: [],

            payment_type: null,
            amount: null,
            balance: "0.00",
            def_balance:"0.00",
            crudLoading: false,
            loading:true
        };
    },
    methods: {
        getDebtor() {
            this.loading = true
            axios
                .get(`/debtors/id/${this.$route.params.id}`)
                .then((response) => {
                    this.loading = false
                    this.debtor_debts = response.data.data;
                    this.debtor = response.data.data.debtor;

                    this.sales = this.debtor_debts?.sales;
                    this.sales_items = this.debtor_debts?.sales?.sales_item;
                    this.sales_items.forEach((s) => {
                        this.sales_array.push({
                            product_name: s?.product?.product_name,
                            selling_price: s?.selling_price,
                            variant_name: s?.variant?.variant_name ?? s?.product?.unit_of_measurement,
                            quantity: s?.quantity,
                            tax: parseFloat(s.tax_amount),
                            discount: s?.discount,
                            total: parseFloat(s?.amount_paid)//s?.selling_price * s?.quantity,
                        });
                    });

                    this.def_balance = this.debtor_debts?.total_amount - this.debtor_debts?.amount_paid;
                    this.balance = (this.def_balance).toFixed(2)
                })
                .catch(() => {
                    this.loading = false
                    this.toast.error("Oops,Something went wrong");
                });
        },
        handleAmount() {
            let balance = this.debtor_debts?.total_amount - this.debtor_debts?.amount_paid;
            this.balance = (balance - this.amount).toFixed(2);
            if (Math.sign(this.balance) == -1) {
                this.amount = this.debtor_debts?.balance;
                this.balance = parseFloat(balance).toFixed(2);
            }
        },
        makePayment() {
            if (this.payment_type == null) {
                this.toast.error("Select payment type first");
                return;
            }
            this.crudLoading = true;
            axios
                .post("/debtors/pay-debt", {
                    amount: this.amount,
                    customer_id: this.debtor?.customer_id,
                    debt_id: this.debtor_debts?.id,

                })
                .then((response) => {
                    this.crudLoading = false;
                    this.$router.push({ name: "Debtor" });
                    this.toast.success(response.data.message);
                })
                .catch((error) => {
                    this.crudLoading = false;
                    this.toast.error(error.response.data.message[0]??'Something went wrong!');
                });
        },
    },
    computed: {
        loggedInUser() {
            return this.store.state.getLoggedInUser;
        },
        grandTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a) + b.total + b.tax - b.discount).toFixed(2);
            }, 0);
        },
        subTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a) + parseFloat(b.total)).toFixed(2);
            }, 0);
        },
        discountTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a) + parseFloat(b.discount)).toFixed(2);
            }, 0);
        },
        taxTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a)+parseFloat(b.tax)).toFixed(2)//parseFloat(a) + parseFloat(b.tax).toFixed(2);
            }, 0);
        },
    },

    created() {
        this.getDebtor();
        this.getCompany();
    },
};
</script>

<style scoped>
    .table-scroll{
        overflow-x:auto
    }
.subtotal {
    margin-right: 112px;
}

.taxmargin {
    margin-right: 162px;
}

.totalmargin {
    margin-right: 144px;
}

.salesbottommargin {
    margin-right: 74px !important;
}

.salesbottommarginpaid {
    margin-right: 32px !important;
}

.salesbottommarginbal {
    margin-right: 46px !important;
}

.salescardcontainer {
    justify-content: center;
}

@media (max-width: 767px) {
    .salescard {
        width: 100% !important;
    }

    .onmobile {
        flex-direction: column !important;
    }

    .salescardcontainer {
        display: block !important;
    }

    .invoicelogo {
        width: 80px;
        height: 60px;

        width: 93% !important;
    }

    #debittopbtns {
        margin-bottom: 5px;
    }
}
</style>
