<template>
  <div>
    <div class="d-flex justify-content-center">
      <div class="card secondcardII">
        <div class="">
          <div class="text-center mt-2" style="color: #037B40; font-size: 24px">You're almost there with</div>
          <div class="mt-5 px-3" style="font-size: 20px: color: #393A3D;">
            <div class="d-flex justify-content-between">
              <div class="makeTextBigger">BMAC IMS (Web)</div>
              <div class="makeTextBigger">{{ $filters.formatMoney(plan.price, currentUserCurrency?.symbol) }}</div>
            </div>
            <div style="text-align: right; color: #393A3D; text-transform: lowercase;">per {{ plan?.type == 'Monthly' ?
          'MONTH' : plan?.type == 'Quarterly' ? 'THREE MONTHS' : plan?.type == 'Half Year' ? 'SIX MONTHS' :
            plan?.type == 'Yearly' ? 'YEAR' : '' }}</div>
          </div>

          <div class="mt-3 px-3 py-4 w-100" style="font-size: 20px; background: #F2F8F6; color: #393A3D;">
            <div class="d-flex justify-content-between">
              <div class="makeTextBigger">Order Total</div>
              <div class="makeTextBigger">{{ $filters.formatMoney(plan.price, currentUserCurrency?.symbol) }}</div>
            </div>
            <div style="text-align: right; color: #393A3D;text-transform: lowercase;">per {{ plan?.type == 'Monthly' ?
          'MONTH' : plan?.type == 'Quarterly' ? 'THREE MONTHS' : plan?.type == 'Half Year' ? 'SIX MONTHS' :
            plan?.type == 'Yearly' ? 'YEAR' : '' }}, {{ plan?.max_employee }} users</div>
          </div>

          <div class="mt-3 px-3 py-4 w-100" style="font-size: 20px;">
            <div class="makeTextBigger">You’ll be charged <span class="fw-bold makeTextBigger">{{
              $filters.formatMoney(plan.price, currentUserCurrency?.symbol)
            }} per {{ plan?.type == 'Monthly' ?
  'MONTH' : plan?.type == 'Quarterly' ? 'THREE MONTHS' : plan?.type == 'Half Year' ? 'SIX MONTHS' :
plan?.type == 'Yearly' ? 'YEAR' : '' }}</span>
              plus applicable taxes until you cancel your License.</div>
          </div>

          <hr class="mt-3 fw-bold text-black">

          <div style="color: #393A3D" class="makeTextBiggerMuchBigger px-3 pt-4 pb-5">
            Select Payment Method
          </div>
          <ul class="list-group" style="border-radius:0;">


            <li class="list-group-item" style="border-right:0px;border-left:0px" href="#"
              v-for="(ch) in payment_channels" :key="ch.id">
              <a href="#" @click="buyNow(ch, plan, $route.params.id, $route.query.query)"
                v-if="ch.name.toLowerCase() == 'flutterwave'" class="d-flex justify-content-between align-items-center">

                <img :src="hostName + '' + ch.logo" :alt="ch.name" width="120" height="25" />
                <span class="badge rounded-pill">
                  <img class="card-arrow" src="../../../assets/images/arrow.webp" alt="" width=45>
                </span>
              </a>

              <a @click="currentData(plan, $route.params.id, $route.query.query)">
                <div class="d-flex justify-content-between align-items-center paystack-button"
                  v-if="ch.name.toLowerCase() == 'paystack'">
                  <img :src="hostName + '' + ch.logo" :alt="ch.name" width="120" height="25" />
                  <paystack buttonClass="''" buttonText="" :publicKey="ch.public_key" :email="currentUser.email"
                    :amount="plan.amount * 100" :reference="'PST-' + reference" :onSuccess="onSuccessfulPayment"
                    :onCanel="onCancelledPayment" />
                  <span class="badge rounded-pill">
                    <img class="card-arrow" src="../../../assets/images/arrow.webp" alt="" width=45>
                  </span>
                </div>
              </a>
            </li>
            


          </ul>


          <!-- <div style="background: #64F1AB; color: #393A3D" class="pt-4 pb-4 px-3 d-flex justify-content-between">
          <div>
            <div class="makeTextBigger mb-2">Credit and Debit Card</div>
            <div>
              <img class="paycard" src="../../../assets/images/mastercard.webp" alt="master">
              <img class="secondpaycard" src="../../../assets/images/visa.webp" alt="visa">
            </div>
          </div>
          <div class="">
            <img class="card-arrow" src="../../../assets/images/arrow.webp" alt="">
          </div>
        </div> -->

          <div>
            <div class="py-5 text-center">
              <button style="background: #DFEAFD; color: #8BBDA5" class="btn py-3 px-4">Cancel Payment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.paystack-button button {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
</style>
<script>
import axios from "axios";
import { reusableMixin } from '../../../lib/reusableMixin';
import host from "../../../main"
import { useFlutterwave } from "flutterwave-vue3"
import { useToast } from "vue-toastification";
import paystack from "vue3-paystack";
import { nanoid } from "nanoid"; //if using nanoid


export default {
  mixins: [reusableMixin],
  components: {
    paystack,
  },
  data() {

    return {
      publicKey: '',
      amount: '', //Expressed in lowest demonitation, so 1000kobo is equivalent to 10Naira
      email: '',
      firstname: '', //optional field remember to pass as a prop of firstname if needed
      lastname: '', //optional field remember to pass as a prop of lastname if needed


      toast: useToast(),
      plan: [],
      loading: false,
      payment_channels: [],
      payment_data: {
        plan_details_id: null,
      },
      currentPlan: null,
      currentId: null,
      query: null


    }
  },

  methods: {
    currentData(plan, currentId, query) {
      this.currentId = currentId;
      this.plan = plan;
      this.query = query

      this.payment_data.plan_details_id = currentId
      axios.post('/subscription/check-subscription', this.payment_data).then(() => {
      }).catch((error) => {
        this.toast.error(error.response.data.message);
        window.location.replace("/subscribe");
      })


    },

    onSuccessfulPayment: function (response) {
      let payment_data = {
        plan_details_id: this.currentId,
        plan: this.plan?.plan?.id,
        amount: this.plan?.amount,
        type: this.plan?.type,
        customer: this.currentUser.fullname,
        query: this.query,
        reference_number: response.trxref,
        meta: response,
        payment_type: 'Paystack'

      }

      axios.post('/subscription/buy', payment_data).then((response) => {
        this.toast.success(response.data.message);
        setTimeout(() => {
          window.location.replace("/subscription-plan");
        }, 600)
      }).catch((error) => {
        this.toast.error(error.response.data.message);
      })
    },
    onCancelledPayment: function () {
      console.log("Payment cancelled by user");
    },


    buyNow(ch, plan, current_id, query) {
      this.payment_data.plan_details_id = current_id
      axios.post('/subscription/check-subscription', this.payment_data).then((response) => {
        if (response.status == 200) {
          this.makePayment(ch, plan, current_id, query);
        }
      }).catch((error) => {
        this.toast.error(error.response.data.message);
      })
    },

    makePayment(ch, plan, current_id, query) {
      if (ch.name.toLowerCase() == 'flutterwave') {
        useFlutterwave({
          amount: plan.amount,
          callback(data) {
            let toast = useToast()

            let payment_data = {
              plan_details_id: current_id,
              plan: plan.plan.id,
              amount: data?.amount,
              type: plan?.type,
              customer: data.customer,
              query: query,
              reference_number:data.flw_ref,
              meta:data,
              payment_type: 'Flutterwave'

            }

            axios.post('/subscription/buy', payment_data).then((response) => {
              toast.success(response.data.message);
              setTimeout(() => {
                window.location.replace("/subscription-plan");
              }, 1000)
            }).catch((error) => {
              this.toast.error(error.response.data.message);
            })

          },
          country: "NG",
          currency: this.currentUserCurrency?.code,
          customer: { email: this.currentUser.email, name: this.currentUser.fullname, phone_number: this.currentUser.phone_no },
          customizations: { description: "", logo: "", title: "BMAC" },
          meta: {
            user_id: this.currentUser.id,
            token: 'FLW-' + this.reference
          },
          onclose() {
            console.log('close it here')

          },
          payment_options: "card",
          public_key: ch.public_key,
          redirect_url: undefined,
          tx_ref: "tx_ref_" + Math.random().toString(36).substring(1, 100)
        })
      }

    },
    getPlanDetail() {
      axios.get("/subscription/detail/" + this.$route.params.id).then((response) => {
        this.plan = response.data.data
      }).catch(() => {
      })

    },

    getPaymentChannels() {
      axios.get("/subscription/payment-channels").then((response) => {
        this.payment_channels = response.data.data
      }).catch(() => {
      })

    },
  },
  computed: {
    hostName() {
      return host.hostName
    },
    reference: function () {
      return nanoid(15);
    },

  },
  mounted() {
    this.getPlanDetail()
    this.getPaymentChannels()
  },

}
</script>
