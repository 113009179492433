<template>
  <ReportHeaderTab />
  
  <div class="" id="adjustment">
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
      <div class="mt-2" style="font-size:20px"> List of Stock Adjustment</div>
      <div class="d-flex gridtop">
        <div class="d-flex mr-2">
          <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:2px">
            <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
            <input type="text" class="form-control " placeholder="Search product" style="padding-left: 38px;"/>
          </div>
          <div class="me-2 mt-1">
            <select class="form-select" >
              <option selected>All</option>
            </select>
          </div>
        </div>
        <div class="d-flex mt-1">
          <div>
            <button class="btn btn-secondary me-2">
              <span class="iconify" data-icon="fluent:molecule-28-regular"></span> Export
            </button>
          </div>
          <!-- <div>
            <a href="./create-customer.html"><button class="btn btn-success">Add Stock Adjustment</button></a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="card salescard rounded-lg mt-3 mx-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto;">        
      <div class="">
        <table class="table table-sm-responsive">
          <thead class="thead-white">
            <tr class="pb-5 text-center">
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Stock Adjustment Number</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Date</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Store</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Adjusted By</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <td class="text-secondary" style="font-size: 16px;">SA-1234567</td>
              <td class="text-secondary" style="font-size: 16px;">2020-01-01</td>
              <td class="text-secondary" style="font-size: 16px;">Surulere Store</td>
              <td class="text-secondary" style="font-size: 16px;">Esther James</td>
              <td>
                <div class="">
                  <select class="text-success" style="border: none;">
                    <option selected class="text-success" style="font-size: 15px;">View</option>
                    <option class="text-success" style="font-size: 15px;">Print</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr class="text-center">
              <td class="text-secondary" style="font-size: 16px;">SA-1234567</td>
              <td class="text-secondary" style="font-size: 16px;">2020-01-01</td>
              <td class="text-secondary" style="font-size: 16px;">Surulere Store</td>
              <td class="text-secondary" style="font-size: 16px;">Esther James</td>
              <td>
                <div class="">
                  <select class="text-success" style="border: none;">
                    <option selected class="text-success" style="font-size: 15px;">View</option>
                    <option class="text-success" style="font-size: 15px;">Print</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr class="text-center">
              <td class="text-secondary" style="font-size: 16px;">SA-1234567</td>
              <td class="text-secondary" style="font-size: 16px;">2020-01-01</td>
              <td class="text-secondary" style="font-size: 16px;">Surulere Store</td>
              <td class="text-secondary" style="font-size: 16px;">Esther James</td>
              <td>
                <div class="">
                  <select class="text-success" style="border: none;">
                    <option selected class="text-success" style="font-size: 15px;">View</option>
                    <option class="text-success" style="font-size: 15px;">Print</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr class="text-center">
              <td class="text-secondary" style="font-size: 16px;">SA-1234567</td>
              <td class="text-secondary" style="font-size: 16px;">2020-01-01</td>
              <td class="text-secondary" style="font-size: 16px;">Surulere Store</td>
              <td class="text-secondary" style="font-size: 16px;">Esther James</td>
              <td>
                <div class="">
                  <select class="text-success" style="border: none;">
                    <option selected class="text-success" style="font-size: 15px;">View</option>
                    <option class="text-success" style="font-size: 15px;">Print</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr class="text-center">
              <td class="text-secondary" style="font-size: 16px;">SA-1234567</td>
              <td class="text-secondary" style="font-size: 16px;">2020-01-01</td>
              <td class="text-secondary" style="font-size: 16px;">Surulere Store</td>
              <td class="text-secondary" style="font-size: 16px;">Esther James</td>
              <td>
                <div class="">
                  <select class="text-success" style="border: none;">
                    <option selected class="text-success" style="font-size: 15px;">View</option>
                    <option class="text-success" style="font-size: 15px;">Print</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr class="text-center">
              <td class="text-secondary" style="font-size: 16px;">SA-1234567</td>
              <td class="text-secondary" style="font-size: 16px;">2020-01-01</td>
              <td class="text-secondary" style="font-size: 16px;">Surulere Store</td>
              <td class="text-secondary" style="font-size: 16px;">Esther James</td>
              <td>
                <div class="">
                  <select class="text-success" style="border: none;">
                    <option selected class="text-success" style="font-size: 15px;">View</option>
                    <option class="text-success" style="font-size: 15px;">Print</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div>Showing 1 to 10 of 50 entries</div>
        <div class="d-flex justify-content-between">
          <span class="mr-2">Display</span>
          <span>
            <select class="form-control displayfooter">
              <option selected>10</option>
              <option>20</option>
              <option >30</option>
            </select>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeaderTab from './components/reportheadertab.vue'
export default {
  components: { ReportHeaderTab }
}
</script>

<style>
@media (max-width: 767px) {
  .salescard {
    width: 21rem !important;
  }
}
</style>