<template>
<AdminHeader />

<div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
    <div class="mt-2" style="font-size: 20px">User List</div>
    <div class="d-flex">
        <div class="d-flex align-items-center small pl-2 me-2 mt-1">
            <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
            <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px" v-model="query" />
        </div>
        <div class="dropdown">
            <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../../private-assets/images/Vector.png" class="me-2" alt=""> Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a  @click="drawPdf(print_content, headerArrayColumn, 'l', 'employees', 'Employees')" class="dropdown-item" href="#">PDF</a></li>
                <li><a @click="reusableMixinToCSV('employees.csv')" class="dropdown-item" href="#">CSV</a></li>
            </ul>
        </div>
            <div class="me-2">
            <router-link to="/create-user" class="btn allbuttonsclr mt-1 text-white" :class="permission.create_employee===0 ? 'disabled' :'' ">Add User</router-link>
        </div>
        <button class="btn btn-danger text-white mt-1" data-bs-toggle="modal" data-bs-target="#bulkModal" :disabled="permission.delete_employee===0">Bulk Delete</button>
    </div>
</div>

<div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
    <div v-if="loading === true" class="text-center py-5">
        <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
        <div class="text-center py-5" v-if="permission_status === 403 && is_permission === false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
        <div class="text-center py-5" v-else-if="details.length === 0 && loading === false">
            <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
        <div class="fw-bold mt-3">Users bucket is empty!</div>
    </div>
    <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="loading === false && details.length > 0">

            <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option :value="pagination.total">All</option>
                </select>
            </div>
            <!--begin::Actions-->

        </div>
        <!--end::Toolbar-->
    </div>
    <div class="">
        <table class="table table-sm-responsive" v-if="loading === false && details.length > 0">
            <thead class="thead-white" style="border-style: hidden !important">
                <tr class="pb-5 text-center">
                    <th scope="col"> <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" /></th>
                    <th class="font-weight-bold heading">Email</th>
                    <th class="font-weight-bold heading">First Name</th>
                    <th class="font-weight-bold heading">Last Name</th>
                    <th class="font-weight-bold heading">Role</th>
                    <th class="font-weight-bold heading" style="width:20%;">Assigned Store</th>
                    <th class="font-weight-bold">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(emp, index) in details" :key="index.id" class="text-center">
                    <td><input type="checkbox" name="" id="" v-model="selectedModel" :value="emp.id" /></td>
                    <td class="text-secondary py-3 row1">{{emp?.email}}</td>
                    <td class="text-secondary py-3 row1">{{emp?.first_name}}</td>
                    <td class="text-secondary py-3 row1">{{emp.last_name}}</td>
                    <td class="text-secondary py-3 row1">{{emp?.roles[0]?.name}}</td>
                    <td class="text-secondary py-3 row1">
                        {{emp.stores.map(res=>res.store_name).join(", ").toString()}}
                        <!-- <span class="badge badge-pill bg-success row1" v-for="st in emp.stores" :key="st.id" style="margin-right:2px">{{st?.store_name}}</span> -->
                    </td>
                    <td class="py-3">
                        <div class="" style="font-size: 13px">
                                <router-link :to="permission.edit_employee===0 ? '#' :{name:'Edit User', params:{ id:emp.id}}">
                                    <font-awesome-icon prefix="fal" icon="pen" class="me-2" style="font-size: 15px" :style="permission.edit_employee===1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                </router-link>

                            <font-awesome-icon icon="trash" @click="getModalCurrentId(emp.id)" style="font-size: 15px" :data-bs-toggle="company?.user_id !== emp.id ? 'modal' : ''"  data-bs-target="#singleModal" :style="company?.user_id !== emp.id ? {} : {'color':'#DCDCDC', 'cursor':'default'}" v-if="permission.delete_employee===1"/>
                            <font-awesome-icon icon="trash" @click="getModalCurrentId(emp.id)" style="font-size: 15px" :style="permission.delete_employee===1 ? {} : {'color':'#DCDCDC', 'cursor':'default'}" v-else/>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div>
    <!-- Using modifiers -->

</div>
<pagination style="float:right;" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)" @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>

<SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleEmployeeDeleteButton" :modalText="'Are you sure you want to delete this employee'" :action="'deleteSingle'"></SingleDeleteModal>

<BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkDeletStoreButton" :modalText="'Are you sure you want to delete this employees'"></BulkDeleteModal>
</template>

<script>
import AdminHeader from "./administrationheadertab.vue";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import SingleDeleteModal from '../../../components/ActionModal.vue';
import BulkDeleteModal from '../../../components/bulkDeleteModal.vue';

import {
    reusableMixin
} from "../../../lib/reusableMixin";
import {useStore} from "vuex";


export default {
    components: {
        AdminHeader,
        SingleDeleteModal,
        BulkDeleteModal,
    },
    mixins: [reusableMixin],

    data() {
        let sortOrders = {};

        let columns = [{
                width: '16%',
                label: 'Name',
                name: 'name',
                sortable: false
            },
            {
                width: '15%',
                label: 'Sortcode',
                name: 'sortcode',
                sortable: false
            },
            {
                width: '15%',
                label: 'Phonecode',
                name: 'phonecode',
                sortable: false
            },
            {
                width: '5%',
                label: 'Status',
                name: 'status',
                sortable: false
            },
            {
                width: '5%',
                label: 'Actions',
                name: 'actions',
                sortable: false
            },

        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content:[],
            headerArrayColumn: ['S/N', 'Email', 'First Name', 'Last Name', 'Role', 'Assigned Store'],
            errorMessage: null,
            getCustomers: '',
            details: [],
            loading: false,
            query: null,

            selectedModel: [],
            allSelected: false,

            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                draw: 0,
                length: 10,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            company:null,
            permission_status: null,
            is_permission:true,
            store: useStore(),
        }
    },
    methods: {
        dataToPrint(details) {
            details.map((user, idx) => {
                let result = {
                    sn: idx + 1,
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    role: user.roles[0]?.name,
                    store: user.stores.map(res=>res.store_name).join(", ").toString(),
                };
                this.print_content.push(result)
            });
        },
        getUserCompany(){ //This is a property not a method
            axios.get('/settings/company').then((response) => {
                this.company = response.data.data.data
            })
        },

        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },
        fetchData(url = '/admin/employee') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.message.data;
                this.dataToPrint(this.details)
                this.configPagination(data.message);
            }).catch(error => {
                this.errorMessage = error
                this.loading = false;
                this.is_permission = false
                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            });
        },
        callSingleEmployeeDeleteButton() {
   
            axios.delete(`/admin/employee/${this.currentID}`).then(() => {
                this.toast.success('Employee deleted successfully')
                this.fetchData()
            }).catch((error) => {
                if(error?.response?.status === 400 || error?.response?.status === 500){
                    this.toast.error(error?.response?.data?.message ?? 'Whoops, Something went wrong')
                }
                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            })

        },

        callBulkDeletStoreButton() {
            axios.post(`/admin/employee/delete-bulk`, {
                employees: this.selectedModel,
            }).then((response) => {
                this.fetchData();
                this.selectedModel = []
                this.toast.success(response.data.message);

            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.error(error.response.data.message[0]);
                } else {
                    this.toast.error(error.response.data.message);
                }

                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }

            })
        },

        getModalCurrentId(id) {
            this.currentID = id;
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

    },

    watch: {
        query() {
            this.fetchData()
        }
    },
    created() {
        this.fetchData();
        this.getUserCompany()

    },

    computed:{
        permission(){
            return this.store.state.getCurrentUserPermission
        }
    },

}
</script>

<style scoped>
@media(max-width: 767px) {
    .thecard {
        width: 21rem;
    }
}
</style>
