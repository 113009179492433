<template>
    <InventoryHeaderTab />
    <div>
        <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
            <div class="mt-2" style="font-size: 20px">List of Product Location</div>
            <div class="d-flex gridtop">

                <div class=" pl-2 me-2" style="width:230px">
                    <!-- <select style="width:72px" v-model="tableData.length" @change="fetchData()" class="form-select">
                    <option value="5" selected>Store</option>

                    <option :value="pagination.total">All</option>
                </select> -->
                    <Multiselect label="name" v-model="tableData.store" :options="stores" placeholder="--Filter--"
                        :searchable="true" :create-option="true" :close-on-select="true" @select="fetchData()" />

                </div>
                <div>
                    <div class="d-flex align-items-center small" styfle="margin-top:2px">
                        <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                        <input type="text" class="form-control" placeholder="Search product" style="padding-left: 38px"
                            v-model="query" />
                    </div>
                </div>



            </div>
        </div>
        <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
            <div v-if="loading === true" class="text-center py-5">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="text-center py-5" v-if="details.length === 0 && loading === false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your product list is empty!</div>
            </div>
            <div class="card-toolbar" v-if="loading === false && details.length > 0">
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()"
                            class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->

                </div>
            </div>
            <div v-if="loading === false && details.length > 0">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5 text-left">
                            <!-- <th><input type="checkbox" name="" id="" /></th> -->
                            <th class="font-weight-bold" style="width:20%">Lists of Products</th>
                            <th class="font-weight-bold" style="width:8%">Store</th>
                            <th class="font-weight-bold" style="width:5%">Quantity</th>
                            <th class="font-weight-bold" style="width:10%">Pcs Per carton</th>
                            <th class="font-weight-bold" style="width:5%">Cartons</th>
                            <th class="font-weight-bold" style="width:5%">Pcs</th>
                            <th class="font-weight-bold" style="width:10%">Warning Quantity</th>
                            <th class="font-weight-bold" style="width:5%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-left" v-for="(location, index) in details" :key="index">
                            <!-- <th class="py-3"><input type="checkbox" name="" id="" /></th> -->
                            <td class="text-secondary py-3">{{ location?.product_name }} </td>
                            <!-- <td class="text-secondary py-3">{{location?.product?.store?.store_name}}</td> -->
                            <td class="text-secondary py-3">{{ location?.store_name }}</td>
                            <td class="text-secondary py-3">{{ location.quantity }}</td>
                            <td class="text-secondary py-3">
                                {{ location?.product.variants.filter(v => v.variant_selling_price ==
                                        Math.max.apply(Math, location.product.variants.map((variant) => {
                                            return variant.variant_selling_price;
                                        })))[0]?.variant_quantity ?? 'N/A'
                                }}

                            </td>

                            <td class="text-secondary py-3">
                                {{ (carton[location.id] == undefined) ? "N/A" : carton[location.id] }}
                                {{ calculatePieces(location, location.id) }}
                            </td>
                            <td class="text-secondary py-3">{{ (pieces[location.id] == undefined) ? "N/A" :
                                    pieces[location.id]
                            }}</td>
                            <td class="text-secondary py-3">{{ location?.warning_quantity ?? 'N/A' }}</td>
                            <td class="py-3">
                                <router-link to="#" class="text-dark"
                                    :data-bs-toggle="permission.edit_product === 1 ? 'modal' : ''"
                                    data-bs-target="#editLocation" @click="getProductId(location)">
                                    <font-awesome-icon prefix="fal" icon="pen" class="mx-2" style="font-size: 15px"
                                        :style="permission.edit_product === 1 ? {} : { 'color': '#DCDCDC !important', 'cursor': 'default' }" />
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
            @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0">
        </pagination>

        <div class="modal fade" id="editLocation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editLocation">Edit Location</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="editLocation">
                        <div class="modal-body">
                            <div class="col-md-12">
                                <label>Warning Quantity</label>
                                <input type="text" class="form-control" placeholder="Enter warning quantity"
                                    v-model="form.warning_quantity">
                            </div>
                            <br>
                            <div class="modal-footer">
                                <button type="submit" data-bs-dismiss="modal"
                                    class="btn allbuttonsclr text-white">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleProductDeleteButton"
        :modalText="'Are you sure you want to delete this product'" :action="'deleteSingle'"></SingleDeleteModal>
    <BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkDeleteProductButton"
        :modalText="'Are you sure you want to delete this products'"></BulkDeleteModal>
</template>
<style src="@vueform/multiselect/themes/default.css">

</style>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import SingleDeleteModal from "../../../components/ActionModal.vue";
import BulkDeleteModal from "../../../components/bulkDeleteModal.vue";
import {
    reusableMixin
} from "../../../lib/reusableMixin";

import {
    useStore
} from "vuex";

import Multiselect from '@vueform/multiselect'

export default {
    components: {
        InventoryHeaderTab,
        SingleDeleteModal,
        BulkDeleteModal,
        Multiselect
    },
    mixins: [reusableMixin],
    data() {

        let sortOrders = {};
        let columns = [{
            width: "16%",
            label: "Name",
            name: "name",
            sortable: false,
        },
        {
            width: "15%",
            label: "Sortcode",
            name: "sortcode",
            sortable: false,
        },
        {
            width: "15%",
            label: "Phonecode",
            name: "phonecode",
            sortable: false,
        },
        {
            width: "5%",
            label: "Status",
            name: "status",
            sortable: false,
        },
        {
            width: "5%",
            label: "Actions",
            name: "actions",
            sortable: false,
        },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            details: [],
            loading: false,
            query: null,

            selectedModel: [],
            allSelected: false,
            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                outofstock: !isNaN(this.$route.query.outofstock) ? true : false,
                totallowstock: !isNaN(this.$route.query.totallowstock) ? true : false,
                store: "All",
                draw: 0,
                length: 10,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            store: useStore(),
            stores: ["All"],
            form: {
                warning_quantity: "",
            },
            productId: '',
            carton: [],
            pieces: []

        }
    },
    methods: {

        calculatePieces(location, id) {
            let variant = location.product.variants
            let product_quantity = location.quantity
            let quantity = product_quantity

            if (variant.length > 0) {
                let variant_qty = variant.filter(v => v.variant_selling_price == Math.max.apply(Math, variant.map((variant) => {
                    return variant.variant_selling_price;
                })))[0]?.variant_quantity
                let carton = 0

                while (parseInt(variant_qty) <= quantity) {
                    quantity -= variant_qty
                    carton += 1

                    if(quantity < variant_qty){break;}
                }

                this.pieces[id] = variant.length > 0 ? quantity : 'N/A'
                this.carton[id] = variant.length > 0 ? carton : 'N/A'

            }

        },

        getStores() {
            axios.get('/admin/store/user-stores').then((response) => {
                let result = response.data.data
                result.forEach((s) => {
                    this.stores.push({ value: s.id, name: s.store_name })
                })

            })
        },
        getProductId(product) {
            this.productId = product.id
            this.getProduct(this.productId)
            return this.productId
        },
        editLocation() {
            axios.put(`/inventory/product/web/edit-warning-qty/${this.productId}`, {
                warning_quantity: this.form.warning_quantity,
            })
                .then((res) => {
                    this.fetchData()
                    this.toast.success(res.data.message);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.toast.warning("There are errors in your input");
                    }
                    if (error.response.status === 400) {
                        this.toast.error("This is a bad request");
                    }
                    if (error.response.status === 403) {
                        this.toast.error(error.response.data.message);
                    }

                    if (error.response.status === 500) {
                        this.toast.error("An error occured please try again");
                    }
                });
        },
        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },

        fetchData(url = '/inventory/product/location/web/all') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.configPagination(data.data);
            }).catch(error => {
                this.errorMessagem = error
                this.loading = false;
            });
        },

        callSingleProductDeleteButton() {
            axios.delete(`/inventory/product/${this.currentID}`).then(() => {
                this.toast.success('Product deleted successfully')
                this.selectedModel = []
                this.fetchData()
            }).catch((error) => {
                this.toast.error(error?.response?.data?.message ?? 'Whoops, Something went wrong')
            })

        },

        callBulkDeleteProductButton() {
            axios.post(`/inventory/product/delete-bulk`, {
                products: this.selectedModel,
            }).then((response) => {
                this.fetchData();
                this.selectedModel = []
                this.toast.success(response.data.data);

            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.error(error.response.data.message[0]);
                } else if (error.response.status == 400) {
                    this.toast.error(error.response.data.message[0].id);
                } else {
                    this.toast.error(error.response.data.message);
                }
            });
        },

        getModalCurrentId(id) {
            this.currentID = id;
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
        getProduct(id) {
            axios.get(`/inventory/product/store-product/${id}`).then((response) => {
                const theproduct = response.data.data;
                this.form.warning_quantity = theproduct.warning_quantity;
            })

        }
    },

    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission
        },
        isOutOfStocks() {
            return this.details.filter(location => !isNaN(this.$route.query.outofstock) && location.quantity < 1)
        }
    },

    watch: {
        query() {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
        this.getStores()



    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }
}
</style>
