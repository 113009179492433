<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      inactivityTimer: null,
    }
  },
  mounted() {
    this.startInactivityTimer()
  },
  methods: {
    startInactivityTimer() {
      this.inactivityTimer = setTimeout(() => {
        window.location.href = '/auth/login';
        if(localStorage.token !== undefined) {
          localStorage.removeItem('token');
        }
      }, 60 * 60 * 1000)
      
      window.addEventListener('mousemove', this.resetInactivityTimer)
      window.addEventListener('keydown', this.resetInactivityTimer)
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer)
      this.startInactivityTimer()
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.resetInactivityTimer)
    window.removeEventListener('keydown', this.resetInactivityTimer)
  }
}
</script>

<style>

</style>
