<template>
  <div class="page-breadcrumb" id="content_wrapper">
    <div class="align-items-center">
      <div class="my-0 d-flex">
        <h4 class="page-title text-capitalize">
          Welcome {{ user?.first_name }}
        </h4>
        <span class="ms-2"
          ><img
            src="../private-assets/images/lf20_qamcb5gv.json].png"
            style="height: 31px; margin-top: -3px"
        /></span>
      </div>
    </div>
  </div>

  <div style="overflow-x: hidden">
    <div class="d-flex justify-content-between gridtop">
      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        :to="
          '/all-sales?todaysdate=' +
          new Date()
            .toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .split('/')
            .reverse()
            .join('-')
        "
        style="text-decoration: none; border-radius: 1rem"
      >
        <div class="d-flex justify-content-between" style="border-radius: 1rem">
          <div class="pl-2">
            <div class="hometopfontgrid">Total Revenue Today</div>
            <div class="mt-1" style="font-size: 19px">
              {{ $filters.formatMoney(revenue, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="../private-assets/images/Icon3.webp" class="mr-2" />
          </div>
        </div>
      </router-link>
      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/debtor"
        style="text-decoration: none; border-radius: 1rem"
      >
        <div class="d-flex justify-content-between">
          <div cl4ass="pl-2">
            <div class="hometopfontgrid">Debtors</div>
            <div class="mt-1" style="font-size: 19px">
              {{ $filters.formatMoney(debtors, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="../private-assets/images/Icon.webp" class="mr-2" />
            <!-- <font-awesome-icon class="dashboardicon text-danger mr-2 " style="background: #FFE8DA;" icon="long-arrow-alt-down" /> -->
          </div>
        </div>
      </router-link>
      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/creditor"
        style="text-decoration: none; border-radius: 1rem"
      >
        <div class="d-flex justify-content-between">
          <div class="pl-2">
            <div class="hometopfontgrid">Creditors</div>
            <div class="mt-1" style="font-size: 19px">
              {{ $filters.formatMoney(creditors, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="../private-assets/images/Icon2.webp" class="mr-2" />
            <!-- <font-awesome-icon class="dashboardicon mr-2" icon="sticky-note" style="color: #1BB8EA !important; background:#DDF7FF" /> -->
            <!-- <i class="fas fa-sticky-note dashboardicon text-success mr-2 dashboardicon" style="color: #1BB8EA !important; background:#DDF7FF"></i> -->
          </div>
        </div>
      </router-link>

      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/expired-products?period=0"
        style="text-decoration: none; border-radius: 1rem"
      >
        <div
          class="d-flex justify-content-between remove-margin"
          style="border-radius: 1rem"
        >
          <div class="pl-2">
            <div class="hometopfontgrid">Expiring products</div>
            <div class="mt-1" style="font-size: 19px">
              {{ expired_products }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="../private-assets/images/Icon1.webp" class="mr-2" />
            <!-- <font-awesome-icon class="dashboardicon mr-2" icon="shopping-cart" style="background: #FDF5AF; color:#FFB905" /> -->
          </div>
        </div>
      </router-link>
    </div>

    <div class="row mt-4">
      <div class="col-md-9">
        <div class="card pr-4">
          <div class="card-body">
            <div class="d-md-flex">
              <div>
                <h6 class="card-title" style="font-weight: 600">
                  Top 10 Selling Products
                </h6>
                
              </div>
              <div class="ms-auto d-flex rounded-2">
                <select
                  class="text-success rounded-1"
                  style="border: none"
                  @change="topProducts()"
                  v-model="period"
                >
                  <option selected class="text-success" style="font-size: 15px" value="daily">
                    Daily
                  </option>
                  <option class="text-success" style="font-size: 15px" value="weekly">
                    Weekly
                  </option>
                  <option selected class="text-success" style="font-size: 15px" value="lastweek">
                    Last Week
                  </option>
                  <option class="text-success" style="font-size: 15px" value="monthly">
                    Monthly
                  </option>
                  <option class="text-success" style="font-size: 15px" value="yearly">
                    Yearly
                  </option>
                </select>
                
              </div>
            </div>
          </div>
          <div v-if="loading === true" class="text-center py-5">
            <div
              class="spinner-border text-success"
              role="status"
              style="width: 5rem; height: 5rem"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div
            class="text-center py-5"
            v-if="topproducts?.length === 0 && loading === false"
          >
            <div>
              <img src="../private-assets/images/emptypng.png" alt="" />
            </div>
            <div class="fw-bold mt-3">Your sales list is empty!</div>
          </div>
          <div v-if="loading === false && topproducts?.length > 0">
            <table class="table table-responsive px-4">
              <thead>
                <tr class="bg-light">
                  <th class="" style="width:50%">Product Name</th>
                  <th class="border-top-0">Prices({{ company?.currency?.symbol }})</th>
                  <th class="border-top-0">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="product in topproducts" :key="product.id">
                  <td class="">
                    <div class="d-flex align-items-center">
                      <div class="me-2" v-if="product.image">
                        <img
                          class="tableimg"
                          :src="host.hostName + product.image"
                          alt=""
                        />
                      </div>
                      <!-- <div class="me-2" v-else>
                        <img
                          class="tableimg"
                          src="../private-assets/images/pseudopp.jpg"
                          alt=""
                        />
                      </div> -->
                      <div class="tabletext">
                        <div class="m-b-0 font-16">
                          {{ product.product_name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tablepadding">
                    {{ $filters.formatMoney(product.selling_price) }}
                  </td>
                  <td class="tablepadding">{{ product.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-3 sidegridcontainer">
        <router-link
          class="makeGreen bg-white"
          to="/all-products"
          style="text-decoration: none; border-radius: 1rem"
        >
          <div
            class="
              bg-white
              besidetable
              px-3
              d-flex
              justify-content-between
              sidegrid
            "
          >
            <div class="py-3 pl-2">
              <div class="" style="font-weight: 500">Total Products</div>
              <div class="h3 mt-2">{{ total_products }}</div>
            </div>
            <div class="dashboardiconcontainer py-2 mt-3 pr-3">
              <img src="../private-assets/images/Icon1.webp" class="mr-2" />
              <!-- <font-awesome-icon class="dashboardicon mr-2" icon="shopping-cart" style="background: #FDF5AF; color:#FFB905" /> -->
            </div>
          </div>
        </router-link>
        <router-link
          class="makeGreen bg-white my-2"
          to="/all-product-location?outofstock"
          style="text-decoration: none; border-radius: 1rem"
        >
          <div
            class="
              bg-white
              besidetable
              px-3
              rounded-lg
              d-flex
              justify-content-between
              sidegrid
              outstockmargin
            "
          >
            <div class="py-3 pl-2">
              <div class="" style="font-weight: 500">Out of Stock</div>
              <div class="h3 mt-2">{{ out_of_stock }}</div>
            </div>
            <div class="dashboardiconcontainer py-2 mt-3 pr-3">
              <img src="../private-assets/images/Icon1.webp" class="mr-2" />
              <!-- <font-awesome-icon class="dashboardicon mr-2" icon="shopping-cart" style="background: #FDF5AF; color:#FFB905" /> -->
            </div>
          </div>
        </router-link>
        <router-link
          class="makeGreen bg-white"
          to="/all-product-location?totallowstock"
          style="text-decoration: none; border-radius: 1rem"
        >
          <div
            class="
              bg-white
              besidetable
              px-3
              mt-3
              rounded-lg
              d-flex
              justify-content-between
              sidegrid
            "
          >
            <div class="py-3 pl-2">
              <div class="" style="font-weight: 500">Total Low Stock</div>
              <div class="h3 mt-2">{{ low_stock }}</div>
            </div>
            <div class="dashboardiconcontainer py-2 mt-3 pr-3">
              <img src="../private-assets/images/Icon1.webp" class="mr-2" />
              <!-- <font-awesome-icon class="dashboardicon mr-2" icon="shopping-cart" style="background: #FDF5AF; color:#FFB905" /> -->
            </div>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import { useToast } from "vue-toastification";

import { onMounted } from "@vue/runtime-core";
import host from "../../main";
import { reusableMixin } from '../../lib/reusableMixin';

export default {
  mixins: [reusableMixin],

  created(){
    this.getCompany();
  },

  setup() {
    const store = useStore();
    const showToggle = ref(true);
    const targetToggle = ref(null);
    const toast = useToast();
    const revenue = ref(0);
    const debtors = ref(0);
    const creditors = ref(0);
    const expired_products = ref(0);
    const total_products = ref(0);
    const out_of_stock = ref(0);
    const low_stock = ref(0);
    const topproducts = ref(0);
    const loading = ref(true);
    const period = ref('monthly');

    const sumInfo = async () => {
      await axios
        .get("/dashboard/suminfo")
        .then((response) => {
          revenue.value = response.data.data?.total_revenue_today;
          debtors.value =
            response.data.data.total_debtors ?? 0;
          creditors.value =
            response.data.data.total_creditor;
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    };
    const countInfo = async () => {
      await axios
        .get("/dashboard/countinfo")
        .then((response) => {
          expired_products.value = response.data.data.expired_products;
          total_products.value = response.data.data.total_products;
          out_of_stock.value = response.data.data.out_of_stock;
          low_stock.value = response.data.data.low_stock;
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    };

    const topProducts = async () => {
      loading.value = true
      showToggle.value = !showToggle.value;
      await axios
        .get(`/dashboard/topproducts?period=${period.value}`)
        .then((response) => {
          loading.value = false;
          topproducts.value = response.data.data;
        })
        .catch((error) => {
          loading.value = false;
          toast.error(error.response.data.message);
        });
    };

    const toggleBar = () => {
      showToggle.value = !showToggle.value;
    };

    onClickOutside(targetToggle, () => {
      showToggle.value = false;
    });

    const user = computed(() => store.state.getLoggedInUser);

    onMounted(sumInfo(), countInfo(), topProducts());

    return {
      user,
      showToggle,
      toggleBar,
      targetToggle,
      revenue,
      debtors,
      creditors,
      low_stock,
      total_products,
      expired_products,
      topproducts,
      topProducts,
      period,
      out_of_stock,
      host,
      loading,
    };
  },
};
</script>

<style scoped>
.hometopfontgrid {
  font-size: 15px;
  font-weight: 500;
}

.remove-margin {
  margin-left: 1rem;
  margin-right: 1rem;
}

.gridtop {
  overflow: auto;
  margin-left: -15px;
  width: 102.5%;
}

.sidegrid {
  /* width: 241px; */
  width: 100%;
  border-radius: 1rem;
}

.table-hamburger {
  cursor: pointer;
}

.toggle {
  position: absolute;
}

.innertoggle {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  border-bottom: 1px solid gray;
  width: 100% !important;
}

.innertoggle:hover {
  background: rgb(216, 213, 213);
  width: 100%;
}

.table thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.tabletext {
  font-size: 18px;
  font-weight: 600;
}

.tableimg {
  height: 36px;
  border-radius: 999px;
}

.tablepadding {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.hometabletoggle {
  background: white;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  /* padding-left: 20px;
    padding-right: 20px; */
  padding-top: 3px;
  /* padding-bottom: 3px; */
  width: 114px;
}

@media (max-width: 500px) {
  .sidegrid {
    /* width: 150px !important; */
    width: 100% !important;
  }
}

@media (max-width: 1000px) {
  .gridtop {
    margin-left: -9px;
    width: 104.5%;
  }

  .sidegrid {
    width: 140px;
  }
}

/* @media (min-width: 1280px) {
  .sidegrid {
    width: 230px;
  }
}  */

@media (min-width: 1024px) {
  .outstockmargin {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1024px) {
  .sidegrid {
    width: 159px;
  }
}

@media (max-width: 767px) {
  .remove-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sidegridcontainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .sidegrid {
    width: 100%;
    /* width: 280px; */
  }

  .gridtop {
    margin-left: -3px;
    width: 100%;
  }

  .sidegrid {
    /* width: 223px; */
    width: 100%;
  }

  .outstockmargin {
    margin-left: 4px !important;
  }
}
</style>
