<template>
    <div>
        <div class="d-flex justify-content-between">
            <div class="mt-1 fw-bold" style="font-size:20px">
                <router-link to="/all-purchases">
                    <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
                </router-link>
                {{ purchase?.batch_code }}
            </div>
            <PrintButtonHeader :title="title + purchase?.batch_code" :urlA4="urlA4"
                :url58mm="url58mm" :url80mm="url80mm"
                :currentUrl="currentUrl" :data="data" :type="type" />
        </div>
        <div class="bg-white d-flex justify-content-center rounded-lg mt-3 mb-3 px-4 py-3 cardpadding"
            style="overflow-x: auto;">
            <div v-if="loading === true" class="text-center" style="margin:200px">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                </div>
            </div>
            <div class="printborder" v-if="!loading">
                <div class="p-3 sales_invoice" id="printMe">
                    
                    <div>Date: {{ dateTime(purchase?.date) }}, {{ timeConvert(purchase?.time) }}</div>
                    <div>Store: {{ purchase?.store?.store_name }}</div>
                    <div>Customer: {{ purchase?.supplier?.customer_name }}</div>
                    <div>Purchase No: {{ purchase?.batch_code }}</div>
                    <div>Purchased By: {{ purchase?.user?.fullname }}</div>

                    <div class="printdottedborder mt-3"></div>
                    <div class="printdottedborder mt-1 mb-3"></div>
                    <hr class="solid_line" />

                    <div v-for="purchase in purchase_array" :key="purchase.id" class="mt-3">
                        <div>{{ purchase.batch_code }}</div>
                        <div>{{ purchase?.product_name }}</div>
                        <div v-if="purchase?.variant">
                            <div class="d-flex justify-content-between">
                                <div>
                                    {{ `${purchase.quantity}` + ' ' + `${purchase?.variant?.variant_name}` + ' x '
                                            + `${$filters.formatMoney(purchase?.cost_price)}`}}
                                </div>
                                <div>
                                    {{ $filters.formatMoney(purchase?.total , company?.currency?.symbol)  }}
                                </div>
                            </div>

                        </div>

                        <div v-else>
                            <div class="d-flex justify-content-between">
                                <div>{{ purchase?.quantity }} {{ purchase?.variant_name }} x 
                                    {{ $filters.formatMoney(purchase?.cost_price)  }}
                            </div>
                                <div>
                                    {{ $filters.formatMoney(purchase?.total , company?.currency?.symbol)  }}
                                </div>
                            </div>
                        </div>
                        <hr class="solid_line" />
                    </div>

                    <div class="printdottedborder mt-3"></div>
                    <div class="printdottedborder mt-1 mb-3"></div>






                    <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8">

       
              <div class="d-flex justify-content-between" style="font-weight:500">
                <div>Total Amount</div>
                <span>
                  {{ $filters.formatMoney(purchase?.total_amount, company?.currency?.symbol) }}
                </span>
              </div>
            </div>
          </div>



          <div class="d-flex justify-content-between mt-3">
            <div>Amount Paid</div>
            <div v-if="purchase?.paid_amount">{{ $filters.formatMoney(purchase.paid_amount, company?.currency?.symbol) }}</div>
            <div v-else>{{ $filters.formatMoney(0, company?.currency?.symbol) }}</div>
          </div>
          
          <div class="d-flex justify-content-between">
            <div>Payment</div>
            <div style="text-transform:capitalize">{{purchase?.payment_type  }}</div>
          </div>

          <div class="d-flex justify-content-between"
            v-if="purchase?.payment_type == 'cash' || purchase?.payment_type == 'card'">
            <div>Change</div>
            <div class="">
              {{ $filters.formatMoney(purchase?.change, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Balance</div>
            <div class="" v-if="purchase?.balance !== null">
              {{ $filters.formatMoney(purchase?.balance, company?.currency?.symbol) }}
            </div>
            <div class="" v-else>
              {{ $filters.formatMoney(0, company?.currency?.symbol) }}
            </div>
          </div>



                    <div class="mt-4">Powered by BMAC POS</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
// import {
//     reusableMixin
// } from "../../../lib/reusableMixin";
import {
    useStore
} from "vuex";
import { reusableMixin } from '../../../../lib/reusableMixin';


export default {
    mixins: [reusableMixin],

    props:{
        title:String,
        urlA4:String,
        url58mm:String,
        url80mm:String,
        currentUrl:String,
        type:String,
        data:Object,

        purchase_items:Object,
        purchase_array:Array,
        purchase:Object,
        creditor:Object,
        creditor_credits:Object,
        loading:Boolean
    },
    data() {
        return {
            toast: useToast(),
            grand_total: 0,
            tax_amount: 0,
            store: useStore()
        }
    },
    computed: {

        totalQty() {
            let qty = this.purchase_items.reduce((a, b) => {
                return a + b.unit_quantity
            }, 0);
            return qty
        },
    },


    created() {
        this.getCompany();
        // this.getPurchase()
    }

}
</script>
