<template>
    <AdminHeader />

    <div class="tab-content mb-3" id="myTabContent">
        <div class="tab-pane mt-5 fade show active bg-white rounded-lg px-3 py-3" id="company" role="tabpanel"
            aria-labelledby="company-tab">
            <div class="d-flex justify-content-between">
                <div>
                    <h4>Edit User</h4>
                    <div class="text-muted" style="font-size: 10px;">Edit new Employees</div>
                </div>
            </div>
            <div class="row mx-2 mt-2">
                <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                    <li class="text-danger">{{ errors }}</li>
                </ul>
            </div>
            <form @submit.prevent="editUser" class="mt-4">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="mb-2">First Name <span class="text-danger">*</span></label>
                        <input v-model="first_name" type="text" class="form-control" placeholder="Enter first name">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="mb-2">Last Name <span class="text-danger">*</span></label>
                        <input v-model="last_name" type="text" class="form-control" placeholder="Enter last name">
                    </div>
                    <div class="form-group col-md-4 mt-4">
                        <label class="mb-2">Phone Number <span class="text-danger">*</span></label>
                        <input v-model="phone_no" type="text" class="form-control" placeholder="Enter phone number">
                    </div>
                    <div class="form-group col-md-4 mt-4">
                        <label class="mb-2">Email <span class="text-danger">*</span></label>
                        <input v-model="email" type="email" class="form-control" placeholder="Enter email address"
                            disabled>
                    </div>

                    <div class="form-group col-md-4 mt-4">
                        <label class="mb-2">Role <span class="text-danger">*</span></label>
                        <select class="form-select" v-model="role_id" @click="getSelectedRole" id="selectedRole">
                            <option value="" disabled>Choose Role</option>
                            <option v-for="theroles in roles" :key="theroles.id" :value="theroles.id" v-show="company?.user?.is_super !=1 ">{{ theroles.name
                            }}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="row mt-4">
                
                <div class="form-group col-md-4">
                    <label class="mb-2">Password</label>
                    <input v-model="password" type="text" class="form-control" placeholder="Enter phone number">
                </div>
                
            </div> -->
                <div class="row mt-4">
                    <div class="form-group col-md-12">
                        <label class="mb-2">Assigned Store <span class="text-danger">*</span></label>
                        <Multiselect mode="tags" v-model="store" label="name" placeholder="Select options"
                            :close-on-select="false" :searchable="true" :object="true" :options="store_arr" />

                        <!-- <Multiselect v-model="store" :value="store" :options="store_arr" placeholder="Select store" mode="tags" /> -->

                    </div>
                </div>
                <div class="fw-bold my-4" style="font-size:21px">Select Permissions</div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="my-2 row">
                            <div class="col-8 fw-bold">SALES</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="sales_module" name="sales_module" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Sales</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_sales" name="view_sales" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Make Sales</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_sales" name="create_sales" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Reverse Sales</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="reverse_sales" name="reverse_sales" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Selling Price</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_price_change" name="create_price_change" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Discount</div>
                            <div class="form-check form-switch col-2">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_discount" name="create_discount" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">SETTINGS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="settings_module" name="settings_module" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Inventory Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="inventory_category" name="inventory_category"
                                    @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_inventory_category" name="view_inventory_category"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Create Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_inventory_category" name="create_inventory_category"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Edit Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_inventory_category" name="edit_inventory_category"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Delete Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_inventory_category" name="delete_inventory_category"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <!-- Subcategory -->
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Inventory Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="inventory_subcategory" name="inventory_subcategory"
                                    @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_inventory_subcategory" name="view_inventory_subcategory"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Create Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_inventory_subcategory" name="create_inventory_subcategory"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Edit Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_inventory_subcategory" name="edit_inventory_subcategory"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Delete Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_inventory_subcategory" name="delete_inventory_subcategory"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <!-- End Subcategory -->

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="customers" name="customers" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">View Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_customers" name="view_customers" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Create Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_customers" name="create_customers" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Edit Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_customers" name="edit_customers" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Delete Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_customers" name="delete_customers" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="suppliers" name="suppliers" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">View Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_suppliers" name="view_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_suppliers" name="create_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_suppliers" name="edit_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_suppliers" name="delete_suppliers" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="taxes" name="taxes" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">View Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_taxes" name="view_taxes" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Create Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_taxes" name="create_taxes" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Edit Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_taxes" name="edit_taxes" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_taxes" name="delete_taxes" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Payment Type</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="payment_type" name="payment_type" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Payment Type</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_payment_type" name="view_payment_type" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Change Payment Type</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="change_payment_type" name="change_payment_type" :disabled="is_influencer">
                            </div>
                        </div>


                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Notification</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="notification" name="notification" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Notification</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_notification" name="view_notification" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Change Notification</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="change_notification" name="change_notification" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Change Password</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="change_password" name="change_password" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Change Email</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="change_email" name="change_email" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">INVENTORY MANAGEMENT</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="inventory_mgt_module" name="inventory_mgt_module"
                                    @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="product" name="product" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_product" name="view_product" :disabled="is_influencer">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">

                        <div class="my-2 row">
                            <div class="col-8">Create Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_product" name="create_product" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_product" name="edit_product" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_product" name="delete_product" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="stock_adjustment" name="stock_adjustment" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_stock_adjustment" name="view_stock_adjustment"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_stock_adjustment" name="create_stock_adjustment"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <!-- <div class="my-2 row">
                        <div class="col-8">View Stock Adjustment</div>
                        <div class="form-check form-switch col-2 mt-1">
                            <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_stock_adjustment" name="view_stock_adjustment">
                        </div>
                    </div> -->
                        <div class="my-2 row">
                            <div class="col-8">Reverse Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="reverse_stock_adjustment" name="reverse_stock_adjustment"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="stock_transfer" name="stock_transfer" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">View Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_stock_transfer" name="view_stock_transfer" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_stock_transfer" name="create_stock_transfer"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Reverse Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="reverse_stock_transfer" name="reverse_stock_transfer"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Product Location</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="product_location" name="product_location" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Product Location</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_product_location" name="view_product_location"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Expired Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="expired_products" name="expired_products" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Expired Products</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_expired_products" name="view_expired_products"
                                    :disabled="is_influencer">
                            </div>
                        </div>


                        <!-- <div class="my-2 row">
                        <div class="col-8">Reverse Stock Transfer</div>
                        <div class="form-check form-switch col-2 mt-1">
                            <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_stock_transfer" name="reverse_stock_transfer">
                        </div>
                    </div> -->
                        <div class="my-2 mt-4 row">
                            <div class="col-8 fw-bold">ADMINISTRATION</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="administration_module" name="administration_module"
                                    @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Employees</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="employees" name="employees" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Employees</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_administration" name="view_administration" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Employee</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_employee" name="create_employee" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Employee</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_employee" name="edit_employee" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Employee</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_employee" name="delete_employee" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Store Management</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="store_management" name="store_management" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_store_management" name="view_store_management"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_store_management" name="create_store_management"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="edit_store_management" name="edit_store_management"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="delete_store_management" name="delete_store_management"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 mt-4 row">
                            <div class="fw-bold col-8">Purchase</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="purchase_module" name="purchase_module" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Purchase</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_purchase" name="view_purchase" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Create Purchase</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_purchase" name="create_purchase" :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-8">Reverse Purchase</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_purchase" name="reverse_purchase" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 mt-4 row">
                            <div class="fw-bold col-8">DEBTORS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="debtors_module" name="debtors_module" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Debtors</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_debtors" name="view_debtors" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Record Receipts</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_record_receipts" name="create_record_receipts"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">CREDITORS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="creditors_module" name="creditors_module" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Creditors</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_creditors" name="view_creditors" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Record Payment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="create_record_payment" name="create_record_payment"
                                    :disabled="is_influencer">
                            </div>
                        </div>

                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">REPORTS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_reports" name="view_reports" @change="handleChange($event)"
                                    :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Reports</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0
                                    v-model="view_reports" name="view_reports" :disabled="is_influencer">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4 d-flex justify-content-end">
                    <button type="submit" class="btn px-3 me-5 allbuttonsclr text-white"
                        v-if="crudLoading === false">Update User</button>
                    <button v-if="crudLoading === true" type="submit" class="btn px-5 me-5 allbuttonsclr text-white"
                        disabled>
                        <div class="spinner-grow text-info" role="status">
                            <span class="visually-hidden">Processing</span>
                        </div>
                    </button>
                    <button type="button" @click="$router.go(-1)" class="btn px-3 exportbtn">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</template>

<style src="@vueform/multiselect/themes/default.css">

</style>

<script>
import AdminHeader from './administrationheadertab.vue'

import axios from 'axios';
import {
    useToast
} from 'vue-toastification';
import {
    useRouter
} from 'vue-router';
import {
    useRoute
} from 'vue-router';
import Multiselect from '@vueform/multiselect'

export default {
    components: {
        AdminHeader,
        Multiselect
    },
    data() {
        return {
            is_influencer: false,

            first_name: '',
            last_name: '',
            phone_no: '',
            email: '',
            password: '',
            store: [],
            crudLoading: false,
            role_id: '',
            stores: {},
            store_arr: [],
            roles: '',
            toast: useToast(),
            validationErrors: [],
            router: useRouter(),
            route: useRoute(),
            permission_id: '',
            sales_module: '',
            view_sales: '',
            create_sales: '',
            reverse_sales: '',
            create_price_change: '',
            create_discount: '',

            settings_module: '',
            view_settings: '',

            inventory_category: '',
            view_inventory_category: '',
            create_inventory_category: '',
            edit_inventory_category: '',
            delete_inventory_category: '',

            inventory_subcategory: '',
            view_inventory_subcategory: '',
            create_inventory_subcategory: '',
            edit_inventory_subcategory: '',
            delete_inventory_subcategory: '',

            customers: '',
            view_customers: '',
            create_customers: '',
            edit_customers: '',
            delete_customers: '',

            suppliers: '',
            view_suppliers: '',
            create_suppliers: '',
            edit_suppliers: '',
            delete_suppliers: '',

            taxes: '',
            view_taxes: '',
            create_taxes: '',
            edit_taxes: '',
            delete_taxes: '',

            change_email: '',
            change_password: '',

            payment_type: '',
            change_payment_type: '',
            view_payment_type: '',

            notification: '',
            change_notification: '',
            view_notification: '',

            inventory_mgt_module: '',
            product: '',
            view_product: '',
            edit_product: '',
            create_product: '',
            delete_product: '',

            stock_adjustment: '',
            view_stock_adjustment: '',
            create_stock_adjustment: '',
            reverse_stock_adjustment: '',

            stock_transfer: '',
            view_stock_transfer: '',
            create_stock_transfer: '',
            reverse_stock_transfer: '',

            product_location: '',
            expired_products: '',
            view_product_location: '',
            view_expired_products: '',

            administration_module: '',
            employees: '',
            view_administration: '',
            create_employee: '',
            edit_employee: '',
            delete_employee: '',

            store_management: '',
            view_store_management: '',
            create_store_management: '',
            edit_store_management: '',
            delete_store_management: '',

            debtors_module: '',
            view_debtors: '',
            create_record_receipts: '',

            purchase_module: '',
            view_purchase: '',
            create_purchase: '',
            reverse_purchase:'',

            creditors_module: '',
            view_creditors: '',
            create_record_payment: '',

            report_module: '',
            view_reports: '',
            company: null,
            id: null,

            permission_status: null,

        }

    },
    methods: {
        handleChange(evt) {
            if (evt.target.name == 'sales_module') {
                this.view_sales = this.sales_module
                this.create_sales = this.sales_module
                this.reverse_sales = this.sales_module
                this.create_price_change = this.sales_module
                this.create_discount = this.sales_module

            }
            if (evt.target.name == 'settings_module') {
                this.inventory_category = this.settings_module

                this.inventory_subcategory = this.settings_module

                this.customers = this.settings_module

                this.suppliers = this.settings_module

                this.taxes = this.settings_module

                this.payment_type = this.settings_module

                this.notification = this.settings_module

                this.change_email = this.settings_module
                this.change_password = this.settings_module
            }

            if (evt.target.name == 'inventory_category' || evt.target.name == 'settings_module') {
                this.view_inventory_category = this.inventory_category
                this.create_inventory_category = this.inventory_category
                this.edit_inventory_category = this.inventory_category
                this.delete_inventory_category = this.inventory_category
            }

            if (evt.target.name == 'inventory_subcategory' || evt.target.name == 'settings_module') {
                this.view_inventory_subcategory = this.inventory_subcategory
                this.create_inventory_subcategory = this.inventory_subcategory
                this.edit_inventory_subcategory = this.inventory_subcategory
                this.delete_inventory_subcategory = this.inventory_subcategory
            }

            if (evt.target.name == 'customers' || evt.target.name == 'settings_module') {
                this.view_customers = this.customers
                this.create_customers = this.customers
                this.edit_customers = this.customers
                this.delete_customers = this.customers
            }

            if (evt.target.name == 'suppliers' || evt.target.name == 'settings_module') {
                this.view_suppliers = this.suppliers
                this.create_suppliers = this.suppliers
                this.edit_suppliers = this.suppliers
                this.delete_suppliers = this.suppliers
            }

            if (evt.target.name == 'taxes' || evt.target.name == 'settings_module') {
                this.view_taxes = this.taxes
                this.create_taxes = this.taxes
                this.edit_taxes = this.taxes
                this.delete_taxes = this.taxes
            }
            if (evt.target.name == 'payment_type' || evt.target.name == 'settings_module') {
                this.view_payment_type = this.payment_type
                this.change_payment_type = this.payment_type
            }
            if (evt.target.name == 'notification' || evt.target.name == 'settings_module') {
                this.view_notification = this.notification
                this.change_notification = this.notification
            }

            if (evt.target.name == 'inventory_mgt_module') {
                this.product = this.inventory_mgt_module

                this.stock_adjustment = this.inventory_mgt_module
                this.stock_transfer = this.inventory_mgt_module
                this.expired_products = this.inventory_mgt_module
                this.product_location = this.inventory_mgt_module
            }

            if (evt.target.name == 'product' || evt.target.name == 'inventory_mgt_module') {
                this.view_product = this.product
                this.create_product = this.product
                this.edit_product = this.product
                this.delete_product = this.product
            }
            if (evt.target.name == 'stock_adjustment' || evt.target.name == 'inventory_mgt_module') {
                this.view_stock_adjustment = this.stock_adjustment
                this.create_stock_adjustment = this.stock_adjustment
                this.reverse_stock_adjustment = this.stock_adjustment
            }

            if (evt.target.name == 'stock_transfer' || evt.target.name == 'inventory_mgt_module') {
                this.view_stock_transfer = this.stock_transfer
                this.create_stock_transfer = this.stock_transfer
                this.reverse_stock_transfer = this.stock_transfer
            }

            if (evt.target.name == 'expired_products' || evt.target.name == 'inventory_mgt_module') {
                this.view_expired_products = this.expired_products
            }

            if (evt.target.name == 'product_location' || evt.target.name == 'inventory_mgt_module') {
                this.view_product_location = this.product_location
            }

            if (evt.target.name == 'administration_module') {
                this.employees = this.administration_module
                this.store_management = this.administration_module
                this.view_store_management = this.administration_module
            }

            if (evt.target.name == 'employees' || evt.target.name == 'administration_module') {
                this.view_administration = this.employees
                this.create_employee = this.employees
                this.edit_employee = this.employees
                this.delete_employee = this.employees

            }

            if (evt.target.name == 'store_management' || evt.target.name == 'administration_module') {
                this.view_store_management = this.store_management
                this.create_store_management = this.store_management
                this.edit_store_management = this.store_management
                this.delete_store_management = this.store_management
            }

            if (evt.target.name == 'debtors_module') {
                this.view_debtors = this.debtors_module
                this.create_record_receipts = this.debtors_module
            }

            if (evt.target.name == 'creditors_module') {
                this.view_creditors = this.creditors_module
                this.create_record_payment = this.creditors_module
            }

            if (evt.target.name == 'purchase_module') {
                this.view_purchase = this.purchase_module
                this.create_purchase = this.purchase_module
                this.reverse_purchase = this.purchase_module

            }
        },

        getUserCompany() { //This is a property not a method
            axios.get('/settings/company').then((response) => {
                this.company = response.data.data.data
            })
        },
        getSelectedRole() {
            var e = document.getElementById("selectedRole");
            var selected = e.options[e.selectedIndex].text
            if (selected == "Influencer") {
                this.is_influencer = true

                // Sales
                this.view_sales = 1
                this.sales_module = 1
                this.create_sales = 0
                this.reverse_sales = 0
                this.create_price_change = 0
                this.create_discount = 0

                // Settings
                this.settings_module = 1
                this.inventory_category = 1
                this.view_inventory_category = 1
                this.create_inventory_category = 0
                this.edit_inventory_category = 0
                this.delete_inventory_category = 0

                this.inventory_subcategory = 1
                this.view_inventory_subcategory = 1
                this.create_inventory_subcategory = 0
                this.edit_inventory_subcategory = 0
                this.delete_inventory_subcategory = 0

                this.customers = 1
                this.view_customers = 1
                this.create_customers = 0
                this.edit_customers = 0
                this.delete_customers = 0
                this.suppliers = 1
                this.view_suppliers = 1
                this.create_suppliers = 0
                this.edit_suppliers = 0
                this.delete_suppliers = 0
                this.taxes = 1
                this.view_taxes = 1
                this.create_taxes = 0
                this.edit_taxes = 0
                this.delete_taxes = 0
                this.payment_type = 1
                this.view_payment_type = 1
                this.change_payment_type = 0
                this.notification = 1
                this.view_notification = 1
                this.change_notification = 0

                // Inventory Module
                this.inventory_mgt_module = 1
                this.product = 1
                this.view_product = 1
                this.create_product = 0
                this.edit_product = 0
                this.delete_product = 0

                this.stock_adjustment = 1
                this.view_stock_adjustment = 1
                this.create_stock_adjustment = 0
                this.reverse_stock_adjustment = 0

                this.stock_transfer = 1
                this.view_stock_transfer = 1
                this.create_stock_transfer = 0
                this.reverse_stock_transfer = 0

                this.view_product_location = 1
                this.product_location = 1

                this.expired_products = 1
                this.view_expired_products = 1

                // Administration
                this.administration_module = 1
                this.employees = 1
                this.view_administration = 1
                this.create_employee = 0
                this.edit_employee = 0
                this.delete_employee = 0

                this.store_management = 1
                this.view_store_management = 1
                this.create_store_management = 0
                this.edit_store_management = 0
                this.delete_store_management = 0

                this.purchase_module = 1
                this.view_purchase = 1
                this.create_purchase = 0
                this.reverse_purchase =0

                this.debtors_module = 1
                this.view_debtors = 1
                this.create_record_receipts = 0

                this.creditors_module = 1
                this.view_creditors = 1
                this.create_record_payment = 0

                this.report_module = 1
                this.view_reports = 1


            } else {
                this.is_influencer = false
            }

        },
        getRoles() {
            axios.get('/admin/role').then((response) => {
                this.roles = response.data.data

            })
        },
        getStores() {

            axios.get('/admin/store').then((response) => {
                this.stores = response.data.data
                this.stores.forEach((store) => {
                    this.store_arr.push({
                        value: store.id,
                        name: store.store_name
                    })
                })

            })

        },
        editUser() {
            this.crudLoading = true
            let id = this.route.params.id

            // this.view_product_location = this.product_location,
            // this.view_expired_products = this.expired_products,
            axios.put('/admin/employee/' + id, {
                first_name: this.first_name,
                last_name: this.last_name,
                phone_no: this.phone_no,
                email: this.email,
                password: this.password,
                store: this.store,
                role_id: this.role_id,
                permission_id: this.permission_id,

                permissions: {
                    sales_module: this.sales_module,
                    view_sales: this.view_sales,
                    create_sales: this.create_sales,
                    reverse_sales: this.reverse_sales,
                    create_price_change: this.create_price_change,
                    create_discount: this.create_discount,

                    settings_module: this.settings_module,
                    view_settings: this.view_settings,
                    view_inventory_category: this.view_inventory_category,
                    create_inventory_category: this.create_inventory_category,
                    edit_inventory_category: this.edit_inventory_category,
                    delete_inventory_category: this.delete_inventory_category,

                    view_inventory_subcategory: this.view_inventory_subcategory,
                    create_inventory_subcategory: this.create_inventory_subcategory,
                    edit_inventory_subcategory: this.edit_inventory_subcategory,
                    delete_inventory_subcategory: this.delete_inventory_subcategory,


                    view_customers: this.view_customers,
                    create_customers: this.create_customers,
                    edit_customers: this.edit_customers,
                    delete_customers: this.delete_customers,

                    view_suppliers: this.view_suppliers,
                    create_suppliers: this.create_suppliers,
                    edit_suppliers: this.edit_suppliers,
                    delete_suppliers: this.delete_suppliers,

                    view_taxes: this.view_taxes,
                    create_taxes: this.create_taxes,
                    edit_taxes: this.edit_taxes,
                    delete_taxes: this.delete_taxes,

                    change_email: this.change_email,
                    change_password: this.change_password,
                    change_payment_type: this.change_payment_type,
                    view_payment_type: this.view_payment_type,

                    change_notification: this.change_notification,
                    view_notification: this.view_notification,

                    inventory_mgt_module: this.inventory_mgt_module,
                    view_product: this.view_product,
                    edit_product: this.edit_product,
                    create_product: this.create_product,
                    delete_product: this.delete_product,
                    view_stock_adjustment: this.view_stock_adjustment,
                    create_stock_adjustment: this.create_stock_adjustment,
                    reverse_stock_adjustment: this.reverse_stock_adjustment,
                    view_stock_transfer: this.view_stock_transfer,
                    create_stock_transfer: this.create_stock_transfer,
                    reverse_stock_transfer: this.reverse_stock_transfer,

                    view_product_location: this.view_product_location,
                    view_expired_products: this.view_expired_products,

                    administration_module: this.administration_module,
                    view_administration: this.view_administration,
                    create_employee: this.create_employee,
                    edit_employee: this.edit_employee,
                    delete_employee: this.delete_employee,
                    view_store_management: this.view_store_management,
                    create_store_management: this.create_store_management,
                    edit_store_management: this.edit_store_management,
                    delete_store_management: this.delete_store_management,

                    debtors_module: this.debtors_module,
                    view_debtors: this.view_debtors,
                    create_record_receipts: this.create_record_receipts,

                    creditors_module: this.creditors_module,
                    view_creditors: this.view_creditors,
                    create_record_payment: this.create_record_payment,

                    report_module: this.report_module,

                    view_purchase: this.view_purchase,
                    purchase_module: this.purchase_module,
                    create_purchase: this.create_purchase,
                    reverse_purchase: this.reverse_purchase,
                    view_reports: this.view_reports,
                }
            }).then(() => {
                this.router.push('/manage-user')
                this.toast.success('User updated successfully')
                this.validationErrors = '';
                this.crudLoading = false
            }).catch((error) => {
                this.crudLoading = false
                if (error?.response?.status === 422) {
                    this.toast.warning('Your inputs are not valid, kindly recheck');
                    this.validationErrors = error.response.data.message
                }
                if (error.response.status === 403) {
                    this.toast.error(error.response.data.message);
                }
                if (error.response.status === 500) {
                    this.toast.error('An error occured please try again');
                }

                if (error?.response?.status === 403) {
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            })
        },
        singleUser() {
            let id = this.route.params.id
            axios.get('/admin/employee/' + id).then((response) => {
                let store_var = []
                response.data.data.stores.forEach((st) => {
                    store_var.push({
                        value: st.id,
                        name: st.store_name
                    })
                })
                if(response.data.data.roles[0]?.name == "Influencer"){
                    this.is_influencer = true
                }
                this.id = response.data.data.id

                this.first_name = response.data.data.first_name
                this.last_name = response.data.data.last_name
                this.phone_no = response.data.data.profile.phone_no
                this.email = response.data.data.email
                this.password = response.data.data.password
                this.permission_id = response.data.data.roles[0]?.permission.id
                this.role_id = response.data.data.roles[0].id
                this.store = store_var

                this.sales_module = response.data.data.roles[0].permission.sales_module
                this.view_sales = response.data.data.roles[0].permission.view_sales
                this.create_sales = response.data.data.roles[0].permission.create_sales
                this.reverse_sales = response.data.data.roles[0].permission.reverse_sales
                this.create_price_change = response.data.data.roles[0].permission.create_price_change
                this.create_discount = response.data.data.roles[0].permission.create_discount

                this.settings_module = response.data.data.roles[0].permission.settings_module
                this.view_settings = response.data.data.roles[0].permission.view_settings

                this.inventory_category = (response.data.data.roles[0].permission.view_inventory_category == 1) || 
                        (response.data.data.roles[0].permission.create_inventory_category == 1) || 
                        (response.data.data.roles[0].permission.edit_inventory_category == 1) ||
                        (response.data.data.roles[0].permission.delete_inventory_category == 1) ? 1 : 0

                this.view_inventory_category = response.data.data.roles[0].permission.view_inventory_category
                this.create_inventory_category = response.data.data.roles[0].permission.create_inventory_category
                this.edit_inventory_category = response.data.data.roles[0].permission.edit_inventory_category
                this.delete_inventory_category = response.data.data.roles[0].permission.delete_inventory_category


                // Subcategory
                this.inventory_subcategory = (response.data.data.roles[0].permission.view_inventory_subcategory == 1) || 
                        (response.data.data.roles[0].permission.create_inventory_subcategory == 1) || 
                        (response.data.data.roles[0].permission.edit_inventory_subcategory == 1) ||
                        (response.data.data.roles[0].permission.delete_inventory_subcategory == 1) ? 1 : 0

                this.view_inventory_subcategory = response.data.data.roles[0].permission.view_inventory_subcategory
                this.create_inventory_subcategory = response.data.data.roles[0].permission.create_inventory_subcategory
                this.edit_inventory_subcategory = response.data.data.roles[0].permission.edit_inventory_subcategory
                this.delete_inventory_subcategory = response.data.data.roles[0].permission.delete_inventory_subcategory
                // Subcategory
                
                this.customers = (response.data.data.roles[0].permission.view_cutomers)||
                        ( response.data.data.roles[0].permission.create_cutomers) ||
                        ( response.data.data.roles[0].permission.edit_cutomers) ||
                        ( response.data.data.roles[0].permission.delete_cutomers) ? 1: 0       
                this.view_customers = response.data.data.roles[0].permission.view_cutomers
                this.create_customers = response.data.data.roles[0].permission.create_customers
                this.edit_customers = response.data.data.roles[0].permission.edit_customers
                this.delete_customers = response.data.data.roles[0].permission.delete_customers

                this.suppliers = (response.data.data.roles[0].permission.view_suppliers)||
                        ( response.data.data.roles[0].permission.create_suppliers) ||
                        ( response.data.data.roles[0].permission.edit_suppliers) ||
                        ( response.data.data.roles[0].permission.delete_suppliers) ? 1: 0
                this.view_suppliers = response.data.data.roles[0].permission.view_suppliers
                this.create_suppliers = response.data.data.roles[0].permission.create_suppliers
                this.edit_suppliers = response.data.data.roles[0].permission.edit_suppliers
                this.delete_suppliers = response.data.data.roles[0].permission.delete_suppliers

                this.change_email = response.data.data.roles[0].permission.change_email
                this.change_password = response.data.data.roles[0].permission.change_password
                this.payment_type = (response.data.data.roles[0].permission.change_payment_type)||
                        (response.data.data.roles[0].permission.view_payment_type) ? 1 : 0
                this.change_payment_type = response.data.data.roles[0].permission.change_payment_type
                this.view_payment_type = response.data.data.roles[0].permission.view_payment_type

                this.notification = (response.data.data.roles[0].permission.change_notification)||
                        (response.data.data.roles[0].permission.view_notification) ? 1 : 0
                this.change_notification = response.data.data.roles[0].permission.change_notification
                this.view_notification = response.data.data.roles[0].permission.view_notification

                this.taxes = (response.data.data.roles[0].permission.view_taxes)||
                        ( response.data.data.roles[0].permission.create_taxes) ||
                        ( response.data.data.roles[0].permission.edit_taxes) ||
                        ( response.data.data.roles[0].permission.delete_taxes) ? 1: 0
                this.view_taxes = response.data.data.roles[0].permission.view_taxes
                this.create_taxes = response.data.data.roles[0].permission.create_taxes
                this.edit_taxes = response.data.data.roles[0].permission.edit_taxes
                this.delete_taxes = response.data.data.roles[0].permission.delete_taxes

                this.inventory_mgt_module = response.data.data.roles[0].permission.inventory_mgt_module

                this.product =(response.data.data.roles[0].permission.view_product)||
                        ( response.data.data.roles[0].permission.create_product) ||
                        ( response.data.data.roles[0].permission.edit_product) ||
                        ( response.data.data.roles[0].permission.delete_product) ? 1: 0
                this.view_product = response.data.data.roles[0].permission.view_product
                this.edit_product = response.data.data.roles[0].permission.edit_product
                this.create_product = response.data.data.roles[0].permission.create_product
                this.delete_product = response.data.data.roles[0].permission.delete_product

                this.stock_adjustment =(response.data.data.roles[0].permission.view_stock_adjustment)||
                        ( response.data.data.roles[0].permission.create_stock_adjustment) ||
                        ( response.data.data.roles[0].permission.reverse_stock_adjustment) ? 1: 0
                this.view_stock_adjustment = response.data.data.roles[0].permission.view_stock_adjustment
                this.create_stock_adjustment = response.data.data.roles[0].permission.create_stock_adjustment
                this.reverse_stock_adjustment = response.data.data.roles[0].permission.reverse_stock_adjustment

                this.stock_transfer =(response.data.data.roles[0].permission.view_stock_transfer)||
                        ( response.data.data.roles[0].permission.create_stock_transfer) ||
                        ( response.data.data.roles[0].permission.reverse_stock_transfer) ? 1: 0
                this.view_stock_transfer = response.data.data.roles[0].permission.view_stock_transfer
                this.create_stock_transfer = response.data.data.roles[0].permission.create_stock_transfer
                this.reverse_stock_transfer = response.data.data.roles[0].permission.reverse_stock_transfer

                this.view_product_location = response.data.data.roles[0].permission.view_product_location
                this.view_expired_products = response.data.data.roles[0].permission.view_expired_products
                this.product_location = this.view_product_location
                this.expired_products = this.view_expired_products


                this.administration_module = response.data.data.roles[0].permission.administration_module

                this.employees =(response.data.data.roles[0].permission.view_administration)||
                        ( response.data.data.roles[0].permission.create_employee) ||
                        ( response.data.data.roles[0].permission.edit_employee) ||
                        ( response.data.data.roles[0].permission.delete_employee) ? 1: 0
                this.view_administration = response.data.data.roles[0].permission.view_administration
                this.create_employee = response.data.data.roles[0].permission.create_employee
                this.edit_employee = response.data.data.roles[0].permission.edit_employee
                this.delete_employee = response.data.data.roles[0].permission.delete_employee

                this.store_management =(response.data.data.roles[0].permission.view_store_management)||
                        ( response.data.data.roles[0].permission.create_store_management) ||
                        ( response.data.data.roles[0].permission.edit_store_management) ||
                        ( response.data.data.roles[0].permission.delete_store_management) ? 1: 0
                this.view_store_management = response.data.data.roles[0].permission.view_store_management
                this.create_store_management = response.data.data.roles[0].permission.create_store_management
                this.edit_store_management = response.data.data.roles[0].permission.edit_store_management
                this.delete_store_management = response.data.data.roles[0].permission.delete_store_management

                this.debtors_module = response.data.data.roles[0].permission.debtors_module
                this.view_debtors = response.data.data.roles[0].permission.view_debtors
                this.create_record_receipts = response.data.data.roles[0].permission.create_record_receipts

                this.purchase_module = response.data.data.roles[0].permission.purchase_module
                this.view_purchase = response.data.data.roles[0].permission.view_purchase
                this.create_purchase = response.data.data.roles[0].permission.create_purchase
                this.reverse_purchase = response.data.data.roles[0].permission.reverse_purchase


                this.creditors_module = response.data.data.roles[0].permission.creditors_module
                this.view_creditors = response.data.data.roles[0].permission.view_creditors
                this.create_record_payment = response.data.data.roles[0].permission.create_record_payment
                this.view_reports = response.data.data.roles[0].permission.view_reports
            })
        }
    },
    mounted() {
        this.singleUser()
        this.getRoles()
        // get all stores
        this.getStores()
        this.getUserCompany()
    }

}
</script>

<style scoped>
.form-switch .form-check-input {
    height: 24px !important;
    width: 48px !important;
    margin-top: -4px;
}

.form-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}

.form-switch .form-check-input:checked {
    background-color: #30D158;
    border-color: #30D158;
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

@media only screen and (max-width: 768px) {
    .resposivePerm {
        display: flex !important;
        justify-content: space-between !important;
    }
}
</style>
