<template>
  <div class="" style="overflow-x: hidden !important">
    <Navbar  />
    <div class="scrollspy-example" data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" tabindex="0">
      <div class="firsttopcontent" id="scrollspyHome">
        <div class="homepadding row topbottompadding toppadded">
          <div class="lefttoptext col-md-6">
            <div class="toptextcolr">
              Accurately Track and monitor your Stocks
            </div>
            <div class="mt-4">
              With the inventory software, retailers, wholesalers and distributors
              can manage their inventory in a single place at any time and at
              anywhere,
            </div>
            <div>
              <!-- <span v-if="token === undefined"> -->
                <button class="btn btn-success mt-5 px-4">Sign In</button>
              <!-- </span> -->
            </div>
          </div>
          <div class="col-md-6">
            <img class="firsttopimg" src="../assets/images/topimg.svg" alt="" />
          </div>
        </div>
      </div>

      <div class="homepadding">
        <div class="text-center" style="overflow-x: hidden !important">
          <div class="toptextcolr mt-5">Features</div>
          <div class="underfeatures">
            Building a thriving business is hard enough without worrying about
            tracking your stocks.<br />
            We are here to ease some of your worries by providing the solutions
            <br />below
          </div>
        </div>
      </div>

      <div class="homepadding row topbottompadding" style="margin-top: 50px; overflow-x: hidden !important" id="scrollspyFeatures">
        <div class="lefttoptext col-md-6">
          <div class="toptextcolr">Track and monitor your Sales</div>
          <div class="mt-4">
            You can track, monitor and make sales of your stocks
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="firsttopimg"
            src="../assets/images/firstfeature.svg"
            alt=""
          />
        </div>
      </div>

      <div class="homepadding row topbottompadding" style="margin-top: 50px">
        <div class="col-md-6">
          <img
            class="firsttopimg"
            src="../assets/images/secondfeature.svg"
            alt=""
          />
        </div>
        <div class="lefttoptext col-md-4 lefttextsecondfeature">
          <div class="toptextcolr">Track debtors</div>
          <div class="mt-4">
            Business owners can find and monitor the customers who bought on
            credits and are owing them.
          </div>
        </div>
      </div>

      <div class="homepadding row topbottompadding" style="margin-top: 50px">
        <div class="lefttoptext col-md-6">
          <div class="toptextcolr">Track and monitor your Sales</div>
          <div class="mt-4">
            You can track, monitor and make sales of your stocks
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="firsttopimg"
            src="../assets/images/thirdfeature.svg"
            alt=""
          />
        </div>
      </div>

      <div class="text-center">
        <div class="toptextcolr mt-5" style="color: #080055">More Features</div>
        <div class="underfeatures">Find more solution we provide for you.</div>
      </div>

      <div class="homepadding">
        <div class="homegrid">
          <div
            class="card text-center py-5 cardfeatures"
            style="background: white"
          >
            <img class="cardicons" src="../assets/images/firstfeatureicon.webp" alt="">
            <div class="cardheader mt-3 cardtoptext">Control Access</div>
            <div class="card-body" style="font-size: 16px">
              Get to see and choose the access you want to give to your team.
            </div>
          </div>

          <div
            class="card text-center py-5 cardfeatures"
            style="background: white"
          >
            <img class="cardicons" src="../assets/images/secondfeatureicon.webp" alt="">
            <div class="cardheader mt-3 cardtoptext">Secure</div>
            <div class="card-body" style="font-size: 16px">
              Get to see and choose the access you want to give to your team.
            </div>
          </div>

          <div
            class="card text-center py-5 cardfeatures"
            style="background: white"
          >
            <img class="cardicons" src="../assets/images/thirdfeatureicon.webp" alt="">
            <div class="cardheader mt-3 cardtoptext">Low Stock Notification</div>
            <div class="card-body" style="font-size: 16px">
              Cut pilfering, stealing and tampering using the IMS. It automatically tracks your inventory levels across all channels.
            </div>
          </div>
        </div>
        
        <div class="homegrid secondgrid">
          <div
            class="card text-center py-5 cardfeatures"
            style="background: white"
          >
            <img class="cardicons" src="../assets/images/fourthfeatureicon.webp" alt="">
            <div class="cardheader mt-3 cardtoptext">Real time updates</div>
            <div class="card-body" style="font-size: 16px">
              Record and Update your business transactions anywhere, anytime on any internet device.
            </div>
          </div>

          <div
            class="card text-center py-5 cardfeatures"
            style="background: white"
          >
            <img class="cardicons" src="../assets/images/fifthfeatureicon.webp" alt="">
            <div class="cardheader mt-3 cardtoptext">Smart Reporting</div>
            <div class="card-body" style="font-size: 16px">
              Make better decisions with real-time inventory reporting. Generate sales report, customer report, profitability reports and lots more.
            </div>
          </div>

          <div
            class="card text-center py-5 cardfeatures"
            style="background: white"
          >
            <img class="cardicons" src="../assets/images/sixthfeatureicon.webp" alt="">
            <div class="cardheader mt-3 cardtoptext">Hands-on Support</div>
            <div class="card-body" style="font-size: 16px">
              We go the journey with you providing guidance and support for an optimal experience.
            </div>
          </div>
        </div>
      </div>

      <div class="homepadding">
        <div class="toptextcolr text-center" >Mobile POS Available</div>
        <div class="mt-3 text-center mb-5" style="color: #696984">This applcation is also available on Playstore</div>

        <div class="posgrid">
          <img class="posimage" src="../assets/images/pos1.webp" alt="">
          <img class="posimage" src="../assets/images/pos2.webp" alt="">
          <img class="posimage" src="../assets/images/pos3.webp" alt="">
        </div>
      </div>
      
<!-- 
      <div class="subscriptionsection" id="scrollspyPricing" >
        <div class="homepadding">
          <div class="py-5">
            <div class="toptextcolr text-center">Subscription</div>
            <div class="mt-3 text-center">Choose the best plan Plan for your business</div>
            <div class="mt-4 text-center" style="font-size: 24px">Monthly Plan</div>
            <div class="d-flex justify-content-center mt-2 mb-4">
              <div style="height: 3px; border: 1px solid #047B40; width: 66px; background: #047B40"></div>
            </div>
            <div class="d-flex justify-content-around subscribesection mb-5">
                <div
                  class="card subcard mt-3 p-2"
                >
                  <div class="card-body">
                    <div class="mt-3" style="font-size: 22px">Basics</div>
                    <div>
                      <span style="font-size: 42px; font-weight: 600">7,500.00</span> 
                      <span style="10px; color: gray">/ Per User</span>
                    </div>
                    <div>
                      <img src="../assets/images/hrule.webp" width="100%" class="enterpriseimg" alt="">
                    </div>
                    <div class="mt-3">
                      Flexible pricing without monthly fees and a 5% fee per transaction.
                    </div>
                    <div class="mt-4">
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">2 locations, Admin + 2 Users</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Expiring Product Module</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Multi- Location capacity</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Multiple Payment Method</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Make Sales in different valiant e.g Pcs, Carton etc.</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Debtors and Creditors Management</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Employees Management</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Consolidated and Individual Store Reports</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">24/7 Customer service support etc.</span>
                      </div>
                    </div>
                    <div class="text-center mt-3" @click="choosePlan">
                        <button type="button" style="padding:0px;border:0px;"  @click="setAmount(7500)">
                          <label class="btn py-2 btn-outline-success px-5 button labl">
                            Buy Plan
                          </label>
                        </button>
                    </div>
                  </div>
                </div>
                
                <div
                  class="card subcard mt-3 p-2"
                >
                  <div class="card-body">
                    <div class="mt-3" style="font-size: 22px">Standard</div>
                    <div>
                      <span style="font-size: 42px; font-weight: 600">15,000.00</span> 
                      <span style="10px; color: gray">/ Per User</span>
                    </div>
                    <div>
                      <img src="../assets/images/hrule.webp" width="100%" class="enterpriseimg" alt="">
                    </div>
                    <div class="mt-3">
                      Flexible pricing without monthly fees and a 5% fee per transaction.
                    </div>
                    <div class="mt-4">
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Up to 4 Locations, Admin + 6 Users</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Expiring Product Module</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Multi- Location capacity</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Multiple Payment Methods</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Make Sales in different variant e.g Pcs, Carton etc.</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Debtors and Creditors Management</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Employees Mangement</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Consolidated and Individual Store Reports</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">24/7 Customer service support etc.</span>
                      </div>
                    </div>
                    <div class="text-center mt-3" @click="choosePlan">
                        <button type="button" style="padding:0px;border:0px;" @click="setAmount(20000)">
                          <label class="btn py-2 btn-outline-success px-5 button labl">
                            Buy Plan
                          </label>
                        </button>
                    </div>
                  </div>
                </div>

                
                <div
                  class="card subcard mt-3 p-2"
                >
                  <div class="card-body">
                    <div class="mt-3" style="font-size: 22px">Premium</div>
                    <div>
                      <span style="font-size: 42px; font-weight: 600">0.00</span> 
                      <span style="10px; color: gray">/ Per User</span>
                    </div>
                    <div>
                      <img src="../assets/images/hrule.webp" width="100%" class="enterpriseimg" alt="">
                    </div>
                    <div class="mt-3">
                      Flexible pricing without monthly fees and a 5% fee per transaction.
                    </div>
                    <div class="mt-4">
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">More than 4 Locations + More than 6 Users</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Exipring Product Module</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Multi- Location capacity</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Multiple Payment Methods</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Make Sales in different variant e.g Pcs, Carton etc.</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Debtors and Creditors Management</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Employees Mangement</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">Consolidated and Individual Store Reports</span>
                      </div>
                      <div class="d-flex mt-1">
                        <span><img src="../assets/images/ticksquare.webp" alt=""></span>
                        <span class="ms-3" style="color: #5B5971;">24/7 Customer service support etc.</span>
                      </div>
                    </div>
                    <div class="text-center mt-3" @click="choosePlan">
                        <button type="button" style="padding:0px;border:0px;" data-bs-toggle="modal"
                          data-bs-target="#modalId">
                          <label class="btn py-2 btn-outline-success px-5 button labl">
                            Buy Plan
                          </label>
                        </button>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </div>
      </div> -->


      <div class="bg-success">
        <div class="homepadding semipadding text-white d-flex justify-content-between">
          <div class="">
            <div style="font-size: 24px">Start IMS today</div>
            <div class="semibottom">Start your free trial today, quality experience awaits you</div>
          </div>
          <div class="semibutton">
            <!-- <span v-if="token === undefined"> -->
              <button class="btn py-3 px-5" style="background:white; color:green">Sign in</button>
            <!-- </span> -->
          </div>
        </div>
      </div>
    </div>

    <div style="background: #F8FFFB;">
      <div class="homepadding">
        <Footer />
      </div>
    </div>
  </div>




  
    <!-- Modal -->
    <div class="modal fade" id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog mw-25 w-50 modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
                  <img src="../assets/images/contact2-.png" alt="trash">
                </div>
      
                <div class="fw-bold text-center mt-3 mb-2" style="font-size:20px">
                    Contact Us
                </div>
            <div class="text-center">
              <div>Call Customer Support: <span class="text-muted">{{ super_user?.profile?.phone_no  }}</span></div>
              <div>Email: <span class="text-muted">{{ super_user?.email ?? 'business@onebmac.com' }} </span> </div>
            </div>
          </div>
          <div class="my-3 text-center">
            <button type="button" class="btn btn-default" style="border: 1px solid grey" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    
    <div class="modal fade" id="modalId2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog mw-25 w-50 modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-around payment_home">
              <div class="fluter_home">
                  <span>
                    <img @click="flutterPayment" src="../assets/images/flutter.png" alt="flutter">
                  </span>
              </div>

              <div class="stack_home position-relative">
                <img src="../assets/images/paystack.png" alt="paystack">
                <span>
                    <paystack
                      buttonClass="'hide_pay position-absolute'"
                      buttonText=""
                      :publicKey="publicKey"
                      :email="user.getLoggedInUser?.email"
                      :amount="amount"
                      :reference="reference"
                      :onSuccess="onSuccessfulPayment"
                      :onCanel="onCancelledPayment"
                      style="background: transparent; border: none; position: absolute; left: 0; top: 0;bottom: 0; width: 100%;"
                      >
                  </paystack>
                </span>
              </div>
            </div>
          </div>
          <div class="my-3 text-center">
            <button ref="closeModalRef" type="button" class="btn btn-default" style="border: 1px solid grey" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>


    
    <button class="d-none" ref="payRef" type="button" style="padding:0px;border:0px;" data-bs-toggle="modal"
      data-bs-target="#modalId2">
    </button>




</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";
import { reusableMixin } from '../lib/reusableMixin';
import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
import paystack from "vue3-paystack";
import { useFlutterwave } from "flutterwave-vue3"

export default {
  components: {
    Navbar,
    Footer,
    paystack
  },
  mixins: [reusableMixin],
  data() {
    const router = useRouter();
    const toast = useToast();
    return {
      plans: [],
      categories: [],
      types: [],
      details: [],
      loading: false,
      router: router,
      toast: toast,
      amount: 0,
      token: localStorage?.token,
      payment: false,
      publicKey:'pk_live_7505355c505d780e487e23eb2ae2911b8a42f09e',
      user: localStorage.vuex !== undefined && JSON.parse(localStorage?.vuex),
      planData: {
        type: 'Monthly',
        category: 'IMS',
        plan: null,
        plan_details_id: null,
        amount: 0,
        user_sub_id: null
      }

    }
  },
  computed: {
    reference(){
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for( let i=0; i < 10; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    }
  },
  methods: {
    onSuccessfulPayment(res) {
      this.$refs.closeModalRef.click();
      axios.post("/subscription/buy", {
        amount: this.amount/100,
        reference_number: res.reference,
        plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
        type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
        payment_type: 'paystack'

      }).then((response) => {
        this.toast.success(response.data.message);
        this.$refs.closeModalRef.click();
        setTimeout(() => {
          window.location.replace("/subscription-plan");
        }, 1000)
      })
      .catch ((err)=> {
        this.toast.error('error');
        console.log(err)
      })
    },

    onCancelledPayment() {
      console.log('cancel')
    },

    setAmount (amount) {
      if(this.token === undefined) {
        this.toast.error('signin to buy plan');
        this.router.push('/auth/login')
      }else {
        this.checkIfEligible(amount);
      }
      this.amount = amount * 100;
      this.payment = false;
    },

    flutterPayment() {
      useFlutterwave({
          amount: this.amount/100,
          callback(data) {
            let toast = useToast();
            let payment_data = {
              plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
              type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
              customer: data.customer,
              reference_number:data.flw_ref,
              meta:data,
              payment_type: 'Flutterwave'
            }
            this.$refs.closeModalRef.click();

            axios.post('/subscription/buy', payment_data).then((response) => {
              toast.success(response.data.message);
              setTimeout(() => {
                window.location.replace("/subscription-plan");
              }, 1000)
            }).catch((error) => {
              this.toast.error(error.response.data.message);
            })

          },
          country: "NG",
          currency: this.user.getLoggedInUser?.code,
          customer: { email: this.user.getLoggedInUser.email, name: this.user.getLoggedInUser.fullname, phone_number: this.user.getLoggedInUser.company.phone },
          customizations: { description: "", logo: "", title: "BMAC" },
          meta: {
            user_id: this.user.getLoggedInUser.id,
            // token: 'FLW-' + this.reference
          },
          onclose() {
            console.log('close it here')
          },
          payment_options: "card",
          public_key: "FLWPUBK-9dbf5b16745932868693fead47ef7100-X",
          redirect_url: undefined,
          tx_ref: "tx_ref_" + Math.random().toString(36).substring(1, 100)
        })
    },

    checkIfEligible(amount) {
        this.payment = true;
      axios.post('/subscription/check-subscription', {
        plan_details_id: this.plans.filter(plan=> plan.amount === amount)[0].id,
      }).then((res) => {
        this.$refs.payRef.click();
        console.log(res);
      }).catch((error) => {
        this.payment = false;
        console.log(error)
        this.toast.error(error.response.data.message);
      })
    },


    getActiveCategories() {
      axios.get("/subscription/category/active").then((response) => {
        this.categories = response.data.data
        this.planData.category = response?.data?.data[0]?.name //this is changed to
      })
    },

    getPlanTypes() {
      axios.get("/subscription/types").then((response) => {
        this.types = response.data.data
        this.planData.type = this.types[Object.keys(this.types)[0]]
      })
    },

    // buyPlan(type) {
    //   // if(this.token === undefined) {
    //   //   this.toast.error('signin to buy plan');
    //   //   this.router.push('/auth/login')
    //   // }
    //   // else {
    //     this.checkIfEligible(type);
    //   // }
    // },

    fetchPlan() {
      axios.get("/subscription/active").then((response) => {
        this.plans = response.data.data;
      })
      .catch((err)=> {
        console.log(err,'error')
      })
    },

    scrollTop(){
        window.scroll({
            top: 0,
            behavior: 'smooth',
        })
    }



  },

  mounted() {
    this.superUser()
    this.getActiveCategories()
    this.fetchPlan()
    this.getPlanTypes();
    this.scrollTop();
  },
  mountBefore() {

  }
}
</script>
<style>
  .homepadding {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (max-width: 767px) {
    .homepadding {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400');

* {
  font-family: 'Inter', sans-serif;
}
.subscribesection {
  flex-wrap: wrap;
}
.hide_pay {
  z-index: 100 !important;
  background: pink !important;
}
.payment_home img {
  width: 180px;
  height: 100px;
  cursor: pointer;
}
.secondcard {
  border: 2px solid green;
}

.toptextcolr {
  font-weight: 700;
  font-size: 47px;
  color: #080055;
}

.homepadding {
  padding-left: 80px;
  padding-right: 80px;
}

.topbottompadding {
  padding-bottom: 30px;
}

.firsttopcontent {
  padding-top: 30px;
  background: #FAFFFC;
}

.lefttoptext {
  margin-top: 90px;
  color: #080055;
}

.firsttopimg {
  width: 100%;
  margin-top: 10px;
}

.underfeatures {
  color: #696984;
  font-size: 20px;
}

.cardheader {
  font-weight: 700;
  font-size: 18px;
}

.homegrid {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 220px;
}

.cardfeatures {
  border: 1px solid white;
  position: relative;
  height: 17rem;
  width: 20rem;
  margin-top: 5rem;
  border-radius: 0.7rem;
 --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.cardtoptext {
  color: #080055;
}

.cardicons {
  position: absolute;
  margin-top: -8rem;
  margin-left: 4.3rem;
}

.secondgrid {
  margin-bottom: 6rem;
}

.subscriptionsection {
  background: linear-gradient(228.96deg, rgba(200, 255, 225, 0.5) -18.08%, rgba(143, 255, 200, 0.5) 122.67%);
}

.subcard {
  width: 25rem;
  border-radius: 0.7rem;
}

.semibottom {
  font-size: 42px;
  font-weight: 900;
  width: 42rem;
}

.semibutton {
  margin-top: 60px;
}

.semipadding {
  padding-top: 70px;
  padding-bottom: 70px;
}

.scrollspy-main {
  position: relative;
}

.toppadded {
  padding-top: 80px;
  padding-bottom: 80px;
}

.posgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 128px;
  margin-bottom: 7rem;
}

@media (max-width: 350px) {
  .firsttopimg {
    width: 17rem !important;
  }

  .cardfeatures {
    width: 17rem;
  }

  .subcard {
    width: 18rem;
  }
  
  .enterpriseimg {
    width: 15rem;
  }

  .semibottom {
    font-size: 23px;
    width: 18rem !important;
  }

  .posimage {
    margin-right: 10px;
    margin-top: 10px;
    width: 18rem;
  }
}

@media (max-width: 767px) {
  .homepadding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .lefttoptext {
    margin-top: 50px;
  }

  .toptextcolr {
    font-size: 32px;
  }

  .homegrid {
    grid-template-columns: 1fr;
    place-items: center;
    grid-gap: 0px;
  }

  .posgrid {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    place-items: center;
  }

  .subscribesection {
    /* display: flex; */
    /* flex-direction: column; */
    flex-wrap: wrap;
  }

  .rightcard {
    margin-left: 0px !important;
  }

  .semibottom {
    font-size: 30px;
    font-weight: 900;
    width: 23rem;
  }

  .semipadding {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* .firsttopimg {
    width: 20rem;
    height: 22rem;
    object-fit: cover;
  } */
  .enterpriseimg { 
    width: 16.8rem;
  }

  .lefttoptext {
    margin-top: 50px;
  }

  .toptextcolr {
    font-size: 32px;
  }

  .homegrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }

  .posgrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }

  .posimage {
    margin-right: 10px;
    margin-top: 10px;
    width: 22rem;
  }

  .semibottom {
    width: 37rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  /* .firsttopimg {
    width: 37rem;
  } */

  .firsttopimg {
    width: 29rem;
    height: 29rem;
    /* object-fit: cover; */
  }

  .homegrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }

  .posgrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }

  .lastsvg {
    right: 44px;
  }

  .bottominput {
    width: 79% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1400px) {
  .homegrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .posgrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .posimage {
    width: 25rem;
  }

  .lastsvg {
    right: 94px;
  }
}
</style>
