<template>
<SettingHeaderTab />

<div class="mb-3">
    <div class="tab-pane mt-3 fade show active bg-white rounded-lg px-3 py-3" id="company" role="tabpanel" aria-labelledby="company-tab">
        <div class="d-flex justify-content-between">
            <div>
                <h4>Create Customer</h4>
                <div class="text-muted" style="font-size: 10px">
                    Create customers here
                </div>
            </div>
        </div>

        <div class="row">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                <li class="text-danger" style="list-style: none">
                    <small>{{ errors }}</small>
                </li>
            </ul>
        </div>

        <form @submit.prevent="createCustomer" class="mt-4">
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Customer Name <span class="text-danger">*</span></label>
                    <input v-model="customer_name" type="text" class="form-control" placeholder="Enter full name" name="customer_name" onkeypress="return /[a-z, ]/i.test(event.key)"/>
                    <p class="form-text text-danger" v-if="validationErrors.customer_name">
                        {{ validationErrors.customer_name[0] }}
                    </p>
                </div>
                <div class="form-group col-md-4">
                    <label>Phone Number</label>
                    <input v-model="phone_no" type="number" class="form-control" placeholder="Enter phone number" name="phone_no" />
                    <p class="form-text text-danger" v-if="validationErrors.phone_no">
                        {{ validationErrors.phone_no[0] }}
                    </p>
                </div>
                <div class="form-group col-md-4">
                    <label>Email Address</label>
                    <input type="text" class="form-control" placeholder="Enter email address" v-model="email" name="email" />
                    <p class="form-text text-danger" v-if="validationErrors.email">
                        {{ validationErrors.email[0] }}
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>Address</label>
                    <input type="text" class="form-control" placeholder="Enter customer's address" v-model="address" name="address" />
                </div>
                <div class="form-group col-md-4">
                    <label>Country</label>
                    <select v-model="country_id" class="form-select" @change="getStates($event)">
                        <option>Choose Country</option>
                        <option :value="country.id" v-for="country in countries" :key="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label>State</label>
                    <select v-model="state_id" class="form-select" @change="getCities($event)">
                        <option value="">Choose state</option>
                        <option :value="state.id" v-for="state in states" :key="state.id">
                            {{ state.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>City</label>
                    <select v-model="city_id" class="form-select">
                        <option value="">Choose city</option>
                        <option :value="city.id" v-for="city in cities" :key="city.id">
                            {{ city.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col"></div>
                <div class="form-group col"></div>
            </div>
            <div class="mt-4 d-flex justify-content-end buttoncustomer">
                <button type="submit" class="btn px-5 me-5 allbuttonsclr text-white" v-if="crudLoading === false">
                    Save
                </button>
                <button v-if="crudLoading === true" type="submit" class="btn px-5 me-5 allbuttonsclr text-white" disabled>
                    Processing
                </button>
                <button type="button" class="btn px-5 exportbtn" @click="$router.go(-1)">
                    Cancel
                </button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    ref
} from "@vue/reactivity";
import SettingHeaderTab from "./components/settingheadertab.vue";
import axios from "axios";
import {
    onMounted
} from "@vue/runtime-core";
import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
export default {
    components: {
        SettingHeaderTab,
    },
    setup() {
        const customer_name = ref("");
        const phone_no = ref("");
        const country_id = ref("");
        const city_id = ref("");
        const state_id = ref("");
        const email = ref("");
        const address = ref("");
        const countries = ref([]);
        const crudLoading = ref(false);
        const loading = ref(false);
        const router = useRouter();
        const validationErrors = ref("");
        const toast = useToast();
        const states = ref([]);
        const cities = ref([]);

        const createCustomer = async () => {
            crudLoading.value = true;
            await axios
                .post("settings/customer", {
                    customer_name: customer_name.value,
                    phone_no: phone_no.value,
                    country_id: country_id.value,
                    city_id: city_id.value,
                    state_id: state_id.value,
                    email: email.value,
                    address: address.value,
                })
                .then((res) => {
                    crudLoading.value = false;
                    router.push("/all-customers");
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        toast.warning("There is an error with your input");
                        validationErrors.value = error.response.data.message;
                    }

                    if (error.response.status === 403) {
                        toast.error(error.response.data.message);
                    }

                    if (error.response.status === 500) {
                        toast.error("An error occured please try again");
                    }
                    crudLoading.value = false;
                });
        };

        const getAllCountries = async () => {
            const response = await axios.get("countries");
            countries.value = response.data.data.data;
            loading.value = false;
        };

        const getStates = async (event) => {
            let country_id = event.target.value;
            const response = await axios.get("/states/" + country_id);
            states.value = response.data.data.data;
            loading.value = false;
        };

        const getCities = async (event) => {
            let state_id = event.target.value;
            const response = await axios.get("/cities/" + state_id);
            cities.value = response.data.data.data;
            loading.value = false;
        };
        onMounted(()=>{
            getAllCountries()
        });

        return {
            crudLoading,
            validationErrors,
            customer_name,
            phone_no,
            email,
            address,
            country_id,
            city_id,
            state_id,
            countries,
            getAllCountries,
            getStates,
            getCities,
            cities,
            states,
            createCustomer,
        };
    },
};
</script>

<style>
@media only screen and (max-width: 280px) {
    .buttoncustomer {
        flex-direction: column;
    }
}
</style>
