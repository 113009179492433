<template>
<nav class="navbar navbar-expand-lg navbar-white bg-white ps-2 fixed-top" ref="formContainer">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img src="./private-assets/images/bmac-logo1.png" alt="brand-logo" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <font-awesome-icon class="text-success" icon="bars" /></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item fw-bold navpagelinkk">
                    <a class="nav-link active text-dark" aria-current="page" style="font-size: 17px">{{ $router.currentRoute.value.name }}</a>
                </li>
            </ul>
            <ul class="navbar-nav ms-auto">
                <li class="fw-bold mt-4 me-3" style="margin-top: 10px !important;">
                  <router-link to="/subscribe" style="font-size: 16px; color: #07d958;text-decoration:none"> Subscribe </router-link> 
                </li>
                <!-- <li class="mt-2 ms-3 me-4">
                    <a class="thebell">
                        <span>
                            <font-awesome-icon icon="bell" style="font-size: 30px" /></span>
                        <span class="num">12</span>
                    </a>
                </li> -->
                <div class="dropdown">
                    <div class="d-flex dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <div class="me-3">
                            <li v-if="user?.first_name" class="mt-1 fw-bold text-capitalize">
                                {{ user?.first_name }} {{ user?.last_name }}
                            </li>
                            <li v-if="!user?.first_name" class="mt-1 fw-bold text-capitalize">
                                John Doe
                            </li>
                            <li class="" v-if="user?.roles">
                                <span v-if="user?.roles.length > 0"> 
                                    {{user?.roles[0]?.name}}

                                </span>
                           </li>
                            
                        </div>

                        <a class="nav-link" href="#" id="navbarDropdown">
                            <img src="./private-assets/images/pseudopic.webp" alt="user" class="rounded-circle" width="36" />
                        </a>
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li class="dropdown-item d-flex">
                            <div class="">
                                <img src="./private-assets/images/pseudopic.webp" alt="user" class="rounded-circle" width="36" />
                            </div>
                            <div class="" style="margin-left: 8px">
                                <div v-if="user?.first_name" class="fw-bold">
                                    {{ user?.first_name }} {{ user?.last_name }}
                                </div>
                                <div v-if="!user?.first_name" class="fw-bold">John Doe</div>
                                <div class="admintopright" v-if="user?.roles">
                                    <span v-if="user?.roles.length > 0"> 
                                    {{user?.roles[0]?.name}}

                                </span>                                
                                </div>
                            </div>
                        </li>
                        <li class="text-center">
                            <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#logoutmodal">
                                <font-awesome-icon class="text-danger" icon="sign-out-alt" />
                                <span class="" style="font-size: 15px; color: gray; margin-left: 15px">Logout</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </ul>
        </div>
    </div>
</nav>

<div class="container-fluid" style="margin-top:70px">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 pb-2 px-0 bg-white shadow-sm min-vh-100">
            <div style="overflow-y:auto; height:95vh;position:fixed;bottom:50px;top:65px" class="d-flex side_mainF flex-column align-items-center align-items-sm-start px-3 pt-1 text-white sidebar_scroll">
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nodecor" id="menu">
                    <li class="">
                        <router-link to="/" exact-active-class="sidebar-active" class="nav-link align-middle px-1" style="color: gray; width: 150% !important" :class="
                  $router.currentRoute.value.name === 'Dashboard'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon class="sidebaricon" icon="home" :class="
                    $router.currentRoute.value.name === 'Dashboard'
                      ? 'sidebariconactive'
                      : null
                  " />
                    <span class="sidebarlefttext d-none d-md-inline" style="
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Dashboard</span>
                        </router-link>
                    </li>
                    <li v-if="permission.administration_module === 1 || permission.view_administration === 1 ||
                      permission.create_employee === 1 || permission.edit_employee===1 ||
                      permission.delete_employee===1 || permission.view_store_management ||
                      permission.create_store_management===1 || permission.edit_store_management === 1 ||
                      permission.delete_store_management===1 
                    ">
                        <router-link :to="permission.view_store_management === 1 ? '/manage-store' : permission.administration_module === 1 ? '/manage-user':''" :active-class="sidebar-active" class="nav-link pt-2 px-1 align-middle takeoffwidth" style="color: gray; width: 125% !important" :class="
                  $router.currentRoute.value.name === 'Manage User' ||
                  $router.currentRoute.value.name === 'Create User' ||
                    $router.currentRoute.value.name === 'Manage Store' ||
                  $router.currentRoute.value.name === 'Edit Store' ||
                  $router.currentRoute.value.name === 'Edit User' ||
                  $router.currentRoute.value.name === 'Create Store'
                    ? 'sidebar-active'
                    : null
                "
                v-if="!freemium"

                >
                            <font-awesome-icon class="" icon="user" :class="
                    $router.currentRoute.value.name === 'Manage User' ||
                    $router.currentRoute.value.name === 'Manage Store' ||
                    $router.currentRoute.value.name === 'Create User' ||
                    $router.currentRoute.value.name === 'Edit Store' ||
                    $router.currentRoute.value.name === 'Edit User' ||
                    $router.currentRoute.value.name === 'Create Store'
                      ? 'sidebariconactive'
                      : null
                  " />
                    <span class="d-none d-md-inline" style="
                    margin-left: 20px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Administration</span>
                        </router-link>
                        
                    <div @click="freemiumPrompt" class="nav-link pt-2 px-1 align-middle takeoffwidth" style="color: gray; width: 125% !important; cursor: pointer;opacity: 0.5;" v-if="freemium">
                        <font-awesome-icon class="" icon="user"/>
                        <span class="d-none d-md-inline" style="
                        margin-left: 20px;
                        font-size: 14.6px;
                        font-family: 'Roboto', sans-serif !important;
                    ">Administration</span>
                    </div>
                        
                    </li>
                    <li v-if="permission.inventory_mgt_module == 1 || permission.view_product == 1 ||
                      permission.create_product == 1 || permission.edit_product==1 ||
                      permission.delete_product==1 || permission.create_stock_adjustment ||
                      permission.view_stock_adjustment==1 || permission.reverse_stock_adjustment == 1 ||
                      permission.create_stock_transfer==1 || permission.view_stock_transfer == 1 ||
                      permission.reverse_stock_transfer == 1
                    ">
                        <router-link :to="((permission.inventory_mgt_module == 1 && permission.view_product == 1) ? '/all-products' : (permission.inventory_mgt_module == 1 && permission.view_product_location == 1) ? '/all-product-location' : (permission.inventory_mgt_module == 1 && permission.view_stock_adjustment == 1) ? '/all-stock-adjustment' : (permission.inventory_mgt_module == 1 && permission.view_stock_transfer == 1) ? '/all-stock-transfer' : '/expired-products')" active-class="sidebar-active" class="nav-link pt-2 px-1 align-middle" style="color: gray; width: 110% !important" :class="
                  $router.currentRoute.value.name === 'Product Location' ||
                  $router.currentRoute.value.name === 'All Products' ||
                  $router.currentRoute.value.name === 'Stock Transfer' ||
                  $router.currentRoute.value.name === 'Stock Adjustment' ||
                  $router.currentRoute.value.name === 'Create Product' ||
                  $router.currentRoute.value.name === 'View Product' ||
                  $router.currentRoute.value.name === 'Expired Products' ||
                  $router.currentRoute.value.name === 'Create Stock Transfer' ||
                  $router.currentRoute.value.name ===
                    'Create Stock Adjustment' ||
                  $router.currentRoute.value.name === 'Edit Product' ||
                  $router.currentRoute.value.name ===
                    'Print Stock Adjustment' ||
                  $router.currentRoute.value.name === 'Print Stock Transfer' ||
                  $router.currentRoute.value.name === 'View Stock Transfer' ||
                  $router.currentRoute.value.name === 'View Stock Adjustment' ||
                  $router.currentRoute.value.name === 'Bulk Product Upload'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon class="" prefix="far" icon="chart-bar" :class="
                    $router.currentRoute.value.name === 'Product Location' ||
                    $router.currentRoute.value.name === 'All Products' ||
                    $router.currentRoute.value.name === 'Stock Transfer' ||
                    $router.currentRoute.value.name === 'Stock Adjustment' ||
                    $router.currentRoute.value.name === 'Create Product' ||
                    $router.currentRoute.value.name === 'View Product' ||
                    $router.currentRoute.value.name === 'Expired Products' ||
                    $router.currentRoute.value.name ===
                      'Create Stock Transfer' ||
                    $router.currentRoute.value.name ===
                      'Create Stock Adjustment' ||
                    $router.currentRoute.value.name === 'Edit Product' ||
                    $router.currentRoute.value.name ===
                      'Print Stock Adjustment' ||
                    $router.currentRoute.value.name ===
                      'Print Stock Transfer' ||
                    $router.currentRoute.value.name === 'View Stock Transfer' ||
                    $router.currentRoute.value.name ===
                      'View Stock Adjustment' ||
                    $router.currentRoute.value.name === 'Bulk Product Upload'
                      ? 'sidebariconactive'
                      : null
                  " />
                            <span class="sidebarlefttext d-none d-md-inline inventmgt" style="
                    margin-left: 20px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Inventory Management</span>
                        </router-link>
                    </li>
                    <li v-if="permission.sales_module == 1">
                        <router-link to="/all-sales" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 220% !important" :class="
                  $router.currentRoute.value.name === 'All Sales' ||
                  $router.currentRoute.value.name === 'Make Credit Sales' ||
                  $router.currentRoute.value.name === 'Sales Receipt' ||
                  $router.currentRoute.value.name === 'Sales Receipt Cash' ||
                  $router.currentRoute.value.name === 'Sales Receipt Credit' ||
                  $router.currentRoute.value.name === 'Make Sales'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon icon="wallet" :class="
                    $router.currentRoute.value.name === 'All Sales' ||
                    $router.currentRoute.value.name === 'Make Credit Sales' ||
                    $router.currentRoute.value.name === 'Sales Receipt' ||
                    $router.currentRoute.value.name === 'Sales Receipt Cash' ||
                    $router.currentRoute.value.name ===
                      'Sales Receipt Credit' ||
                    $router.currentRoute.value.name === 'Make Sales'
                      ? 'sidebariconactive'
                      : null
                  " />
                            <span class="sidebarlefttext d-none d-md-inline" style="
                    margin-left: 20px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Sales</span>
                        </router-link>
                    </li>
                    <li v-if="permission.purchase_module === 1 || permission.view_purchase === 1 ||
                      permission.create_purchase === 1
                    ">                        <router-link to="/all-purchases" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 150% !important" :class="
                  $router.currentRoute.value.name === 'All Purchase' ||
                  $router.currentRoute.value.name === 'Make Purchase' ||
                  $router.currentRoute.value.name === 'Print Purchase' ||
                  $router.currentRoute.value.name === 'Purchase Receipt'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon icon="credit-card" :class="
                    $router.currentRoute.value.name === 'All Purchase' ||
                    $router.currentRoute.value.name === 'Make Purchase' ||
                    $router.currentRoute.value.name === 'Print Purchase' ||
                    $router.currentRoute.value.name === 'Purchase Receipt'
                      ? 'sidebariconactive'
                      : null
                  " />
                            <span class="sidebarlefttext d-none d-md-inline" style="
                    margin-left: 20px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Purchases</span>
                        </router-link>
                    </li>
                    <li v-if="(permission.debtors_module === 1 || permission.view_debtors === 1 ||
                      permission.create_record_receipts === 1) && !freemium
                    "
                    >
                        <router-link to="/debtor" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 178% !important" :class="
                  $router.currentRoute.value.name === 'Debtor Receipt' ||
                  $router.currentRoute.value.name === 'User Debtor'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon icon="sticky-note" id="sidebaricon" :class="
                    $router.currentRoute.value.name === 'Debtor Receipt' ||
                    $router.currentRoute.value.name === 'User Debtor'
                      ? 'sidebariconactive'
                      : null
                  "
                   />
                            <span class="d-none d-md-inline" style="
                    margin-left: 20px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Debtors</span>
                        </router-link>
                    </li>

                    
                    <div @click="freemiumPrompt" class="nav-link pt-2 px-1 align-middle takeoffwidth" style="color: gray; width: 125% !important; cursor: pointer; opacity: 0.5" v-if="(permission.debtors_module === 1 || permission.view_debtors === 1 ||
                      permission.create_record_receipts === 1) && freemium">
                            <font-awesome-icon icon="sticky-note" id="sidebaricon" :class="
                                $router.currentRoute.value.name === 'Debtor Receipt' ||
                                $router.currentRoute.value.name === 'User Debtor'
                                ? 'sidebariconactive'
                                : null
                            " />

                        <span class="d-none d-md-inline" style="
                        margin-left: 20px;
                        font-size: 14.6px;
                        font-family: 'Roboto', sans-serif !important;
                    ">Debtors</span>
                    </div>

                    <li v-if="(permission.creditors_module === 1 || permission.view_creditors === 1 ||
                  permission.create_record_payment === 1 || permission.create_record_payment===1) && !freemium">
                        <router-link to="/creditor" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 167% !important" :class="
                  $router.currentRoute.value.name === 'Creditor Receipt' ||
                  $router.currentRoute.value.name === 'User Credit'
                    ? 'sidebar-active'
                    : null
                "
                >
                            <font-awesome-icon class="sidebaricon" icon="calculator" :class="
                    $router.currentRoute.value.name === 'Creditor Receipt' ||
                    $router.currentRoute.value.name === 'User Credit'
                      ? 'sidebariconactive'
                      : null
                  "
                   />
                            <span class="d-none d-md-inline" style="
                    margin-left: 25px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Creditors</span>
                        </router-link>
                    </li>

                    
                    <div @click="freemiumPrompt" class="nav-link pt-2 px-1 align-middle takeoffwidth" style="color: gray; width: 125% !important; cursor: pointer; opacity: 0.5;" v-if="(permission.creditors_module === 1 || permission.view_creditors === 1 ||
                  permission.create_record_payment === 1 || permission.create_record_payment===1) && freemium">
                         <font-awesome-icon class="sidebaricon" icon="calculator" :class="
                            $router.currentRoute.value.name === 'Creditor Receipt' ||
                            $router.currentRoute.value.name === 'User Credit'
                            ? 'sidebariconactive'
                            : null
                        " />

                        <span class="d-none d-md-inline" style="
                        margin-left: 20px;
                        font-size: 14.6px;
                        font-family: 'Roboto', sans-serif !important;
                    ">Creditors</span>
                    </div>

                    <li v-if="permission.settings_module === 1 || permission.view_settings === 1 ||
                      permission.view_inventory_category === 1 || permission.view_cutomers===1 ||
                      permission.view_suppliers === 1 || permission.view_taxes===1 ||
                      permission.change_email === 1 || permission.view_cutomers===1 ||
                      permission.change_password === 1 || permission.change_payment_type===1 ||
                      permission.change_notification === 1

                    ">
                        <router-link :to="(user.is_super == 1 ? '/company-info': permission.view_inventory_category  == 1 ? '/inventory-category' : permission.view_cutomers==1 ? '/all-customers' : permission.view_suppliers === 1 ? '/all-suppliers' : permission.change_payment_type===1 ? '/all-payments' : permission.view_taxes ===1 ?'/tax': '/notifications')" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 192% !important" :class="
                  $router.currentRoute.value.name === 'Change Password' ||
                  $router.currentRoute.value.name === 'All Customers' ||
                  $router.currentRoute.value.name === 'All Payments' ||
                  $router.currentRoute.value.name === 'All Suppliers' ||
                  $router.currentRoute.value.name === 'Inventory Category' ||
                  $router.currentRoute.value.name === 'Notification' ||
                  $router.currentRoute.value.name === 'Tax' ||
                  $router.currentRoute.value.name === 'Bulk Customer Upload' ||
                  $router.currentRoute.value.name === 'Bulk Supplier Upload' ||
                  $router.currentRoute.value.name === 'Create Customer' ||
                  $router.currentRoute.value.name === 'Create Supplier' ||
                  $router.currentRoute.value.name === 'Edit Company Info' ||
                  $router.currentRoute.value.name === 'Change Email'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon icon="cog" :class="
                    $router.currentRoute.value.name === 'Change Password' ||
                    $router.currentRoute.value.name === 'All Customers' ||
                    $router.currentRoute.value.name === 'All Payments' ||
                    $router.currentRoute.value.name === 'All Suppliers' ||
                    $router.currentRoute.value.name === 'Inventory Category' ||
                    $router.currentRoute.value.name === 'Notification' ||
                    $router.currentRoute.value.name === 'Tax' ||
                    $router.currentRoute.value.name ===
                      'Bulk Customer Upload' ||
                    $router.currentRoute.value.name ===
                      'Bulk Supplier Upload' ||
                    $router.currentRoute.value.name === 'Create Customer' ||
                    $router.currentRoute.value.name === 'Create Supplier' ||
                    $router.currentRoute.value.name === 'Edit Company Info' ||
                    $router.currentRoute.value.name === 'Change Email'
                      ? 'sidebariconactive'
                      : null
                  " />
                            <span class="d-none d-md-inline" style="
                    margin-left: 22px;
                    font-size: 14.6px;
                    font-family: 'Roboto', sans-serif !important;
                  ">Settings</span>
                        </router-link>
                    </li>
                    <li v-if="permission.report_module === 1 || permission.view_reports === 1">
                        <router-link to="/all-reports" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 185% !important" :class="
                  $router.currentRoute.value.name === 'Performance' ||
                  $router.currentRoute.value.name === 'Report Adjustment' ||
                  $router.currentRoute.value.name === 'Report Bin' ||
                  $router.currentRoute.value.name === 'View Stock Report' ||
                  $router.currentRoute.value.name === 'Report Receipt' ||
                  $router.currentRoute.value.name === 'Customer Report' ||
                  $router.currentRoute.value.name === 'User Customer Report' ||
                  $router.currentRoute.value.name === 'Customer Invoice' ||
                  $router.currentRoute.value.name === 'Sales Report' ||
                  $router.currentRoute.value.name === 'Sales Invoice' ||
                  $router.currentRoute.value.name === 'Profitability Report' ||
                  $router.currentRoute.value.name === 'Purchase Report' ||
                  $router.currentRoute.value.name ===
                    'Purchase Report Invoice' ||
                  $router.currentRoute.value.name === 'Stock Transfer Report' ||
                  $router.currentRoute.value.name ===
                    'Stock Transfer Report Details' ||
                  $router.currentRoute.value.name ===
                    'Stock Adjustment Report Details' ||
                  $router.currentRoute.value.name ===
                    'Stock Adjustment Report' ||
                  $router.currentRoute.value.name === 'Sales By Category' ||
                  $router.currentRoute.value.name === 'Audit Trail'
                    ? 'sidebar-active'
                    : null
                ">
                            <font-awesome-icon icon="clipboard" :class="
                    $router.currentRoute.value.name === 'Performance' ||
                    $router.currentRoute.value.name === 'Report Adjustment' ||
                    $router.currentRoute.value.name === 'Report Bin' ||
                    $router.currentRoute.value.name === 'View Stock Report' ||
                    $router.currentRoute.value.name === 'Report Receipt' ||
                    $router.currentRoute.value.name === 'Customer Report' ||
                    $router.currentRoute.value.name ===
                      'User Customer Report' ||
                    $router.currentRoute.value.name === 'Customer Invoice' ||
                    $router.currentRoute.value.name === 'Sales Report' ||
                    $router.currentRoute.value.name === 'Sales Invoice' ||
                    $router.currentRoute.value.name ===
                      'Profitability Report' ||
                    $router.currentRoute.value.name === 'Purchase Report' ||
                    $router.currentRoute.value.name ===
                      'Purchase Report Invoice' ||
                    $router.currentRoute.value.name ===
                      'Stock Transfer Report' ||
                    $router.currentRoute.value.name ===
                      'Stock Transfer Report Details' ||
                    $router.currentRoute.value.name ===
                      'Stock Adjustment Report Details' ||
                    $router.currentRoute.value.name ===
                      'Stock Adjustment Report' ||
                    $router.currentRoute.value.name === 'Sales By Category' ||
                    $router.currentRoute.value.name === 'Audit Trail'
                      ? 'sidebariconactive'
                      : null
                  " />
                            <span class="d-none d-md-inline" style="font-size: 16px; margin-left: 24px">Reports</span>
                        </router-link>
                    </li>

                     <li>
                        <router-link to="/subscription-plan" active-class="sidebar-active" class="nav-link px-1 pt-2 align-middle" style="color: gray; width: 150% !important" :class="
                    $router.currentRoute.value.name === 'Subscribe' || $router.currentRoute.value.name === 'Subscription Plan' ? 'sidebar-active': null">
                            <font-awesome-icon icon="credit-card" :class="
                    $router.currentRoute.value.name === 'Subscription Index'
                      ? 'sidebariconactive' : null " />
                        <span class="sidebarlefttext d-none d-md-inline" style="
                            margin-left: 20px;
                            font-size: 14.6px;
                            font-family: 'Roboto', sans-serif !important;
                        ">Subscription
                        </span>
                        </router-link>
                    </li>
                    <!-- <li>
              <button @click="logout" class="nav-link px-1 align-middle pt-3">
                <font-awesome-icon class="text-danger" icon="sign-out-alt" />
                <span
                  class="d-none d-md-inline"
                  style="font-size: 15px; color: gray; margin-left: 20px"
                  >Logout</span
                >
              </button>
            </li> -->
                </ul>

                <div class="theside last-sidebar chatuspadding pb-3 mt-5" style="border-radius: 15px;margin-left:-15px;margin-bottom:50px">
                <div class="questionsidebarcontainer py-2">
                    <div class="text-white questionsidebar py-1">
                        <span class="">?</span>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="text-white font-weight-semibold">Need help?</div>
                    <div class="text-white my-1" style="font-size: 13px">
                        Chat us immediately
                    </div>
                </div>
                <div class="bg-white text-center chatussidebar">
                    <div class="py-2">CHAT US</div>
                </div>
            </div>
            </div>

            
        </div>
        <div class="col py-3 privatebody">
            <router-view></router-view>
            <!-- <router-view v-slot="{ Component }">
                <keep-alive include="ReportHeaderTab">
                <component :is="Component" />
                </keep-alive>
            </router-view> -->

        </div>
    </div>
</div>

<div class="modal fade" id="logoutmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    <img src="./private-assets/images/logout.webp" style="font-size: 70px" alt="trash" />
                </div>
                <div class="fw-bold text-center mt-3 mb-2" style="font-size: 20px">
                    Log Out
                </div>
                <div class="text-center mt-2">Are you sure you want to Log Out?</div>
            </div>
            <div class="mb-5 text-center">
                <button :disabled="logoutLoading === true" @click="logout" type="button" class="btn btn-success me-3" style="padding-left: 2.5rem; padding-right: 2.5rem">
                    Yes
                </button>
                <button :disabled="logoutLoading === true" type="button" class="btn" style="
              background: #dfeafd;
              padding-left: 2.5rem;
              padding-right: 2.5rem;
            " data-bs-dismiss="modal">
                    No
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import {
//     computed
// } from "@vue/runtime-core";
import axios from "axios";
import {
    ref
} from "@vue/reactivity";
import {
    useStore
} from "vuex";
// import {
//     onMounted
// } from "@vue/runtime-core";
import {
    useRouter
} from "vue-router";

import { useToast } from "vue-toastification";

// export default {
//   setup() {
//     const user = ref("");
//     const permission = ref("")
//     const store = useStore();
//     const route = useRouter();
//     const logoutLoading = ref(false);
//     const emitter = inject('emitter'); // Inject `emitter`

//     const logout = () => {
//       logoutLoading.value = true;
//       localStorage.clear();

//       window.location.href = "/auth/login";
//     };

//     const getCurrentUser = async () => {
//       const response = await axios.get("/user");
//       user.value = response.data;
//       await store.dispatch("getCurrentUser", user.value);
//     };

//     const getCurrentUserPermission = async () => {
//       const response = await axios.get("/user/check-user-permission");
//       permission.value = response.data.message.returnData
//       emitter.emit("emit-change")
//       await store.dispatch("getCurrentUserPermission", permission.value);
//     };

//     const permissions = computed(() => store.state.getCurrentUserPermission);

//     onMounted(getCurrentUser(), getCurrentUserPermission());

//     return { permissions,logout, getCurrentUser,getCurrentUserPermission, user, store, route, logoutLoading, permission};
//   },
// };

export default {
 
    data() {
        return {
            user: '',
            permission: '',
            store: useStore(),
            route: useRouter(),
            toast: useToast(),
            logoutLoading: ref(false),
            loader:this.$loading.show({
              container: this.$refs.formContainer,
              canCancel: true,
              onCancel: this.onCancel,
              color: '#008000',
              width: 100,
              height: 100,
            }),
            freemium: true,
        }
    },

    methods: {
        logout() {
            this.logoutLoading = true;
            axios.get("/logout").then((response) => {
                if(response.status ==200){
                    this.logoutLoading = false;
                    localStorage.clear();
                    window.location.href = "/auth/login";
                    // this.route.push('/auth/login')

                }                
                
            }).catch((error)=>{
                this.logoutLoading = false;
                this.toast.error(error?.response?.data?.message)
            })
            
        },
        getCurrentUser() {
            axios.get("/user").then((response) => {
                this.user = response.data.data.data;
                this.store.dispatch("getCurrentUser", this.user);
            }).catch((error)=>{
                if(error.response.status ==  403){
                    this.route.push('/auth/login')
                } else{
                    this.route.push('/auth/login')
                }

                this.toast.error(error?.response?.data?.message)
            })

        },

        getCurrentUserPermission() {
            this.loader;
            axios.get("/user/check-user-permission").then((response) => {
                setTimeout(() => {
                    this.loader.hide()
                }, 500)
                this.permission = response.data.message.returnData
                console.log(this.permission)
                this.store.dispatch("getCurrentUserPermission", this.permission);
            }).catch((error) => {
              this.loader.hide();
              this.toast.error(error?.response?.data?.message)
            })
        },
        activeSubscription() {
            // loading.value = true
            axios.get("/subscription/user")
                .then((response) => {
                // loading.value = false
                // subscriptions.value = response.data.data
                // // console.log(response.data.data, 'hh')
                // allUsers()
                // superUser()
            if(response.data.data.filter(data => data.status == 1 || data.status == 0).length == 0) {
                this.freemium = true;
            }else {
                this.freemium = false;
            }
            }).catch(() => {
            // loading.value = false
            })
        },

        freemiumPrompt () {
            this.toast.error('subscribe or upgrade to use this functionality')
        }

    },
    created() {
        this.activeSubscription();
        this.getCurrentUserPermission();
        this.getCurrentUser();

    },
    mounted() {
        this.activeSubscription();
    },
    computed: {
        permissions() {
            return this.store.state.getCurrentUserPermission
        }

    }
}
</script>
<style>
body::-webkit-scrollbar, .sidebar_scroll::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track,.sidebar_scroll::-webkit-scrollbar {
  background: #18b768;        /* color of the tracking area */
}
body::-webkit-scrollbar-thumb,.sidebar_scroll::-webkit-scrollbar {
  background-color: #8faa9d;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid #fff;  /* creates padding around scroll thumb */
}

</style>
<style scoped>
.privatebody {
    background: rgb(238, 234, 234);
    overflow-x: hidden !important;
}

.col-xl-2 {
    width: 18.66666667% !important;
}

.dropdown-menu {
    left: -20px !important;
}

.navpagelinkk {
    margin-left: 125px;
}

#sidebaricon {
    font-size: 20px !important;
    /* color: green; */
}

.dropdown-toggle::after {
    display: none !important;
}

.admintopright {
    color: darkgray;
    font-size: 12px;
}

.sidebartext {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.nodecor>li>a {
    text-decoration: none !important;
}

.last-sidebar {
    background: #18b768;
    padding: 4px;
    width: 226px !important;
    margin-left: 12px;
    margin-top: 1px;
}

.questionsidebarcontainer {
    background: white;
    width: 43px;
    display: flex;
    justify-content: center;
    padding: 4px;
    border-radius: 999px;
}

.questionsidebar {
    border-radius: 999px;
    background: green;
    width: 24px;
    height: 29px;
    margin-top: 1px;
    text-align: center;
}

.chatussidebar {
    border-radius: 0.75rem;
}

.sidebar-active {
    background: #18b768 !important;
    color: white !important;
    width: 20rem !important;
}

.sidebarlefttext {
    margin-left: 17px;
}

@media (max-width: 1263px) {
    .side_mainF {
        width: 18.6666667%;
    }

}

@media (max-width: 1000px) {
    .inventmgt {
        font-size: 14.8px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
    .last-sidebar {
        width: 116px !important;
    }
}

@media only screen and (min-width: 906px) and (max-width: 1323px) {
    .last-sidebar {
        width: 160px !important;
    }
}

@media (max-width: 768px) {
    .navpagelinkk {
        margin-left: 0px;
    }

    .sidebar-active {
        width: 100% !important;
    }

    .last-sidebar {
        display: none;
    }

    .theside {
        visibility: hidden !important;
    }

    .inventmgt {
        font-size: 14.2px !important;
    }
}
</style>
