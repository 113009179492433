<template>
  <nav id="navbar-example2" class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
    <div class="container-fluid navpadding">
      <router-link to="/home" class="navbar-brand" href="#">
        <img
          src="../assets/images/logo.png"
          alt="top logo"
          style="height: 36px"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        </ul>
        <div class="d-flex justify-between">
          <!-- <ul class="d-flex me-4  nav nav-pills">
            <li class="nav-item">
              <a class="nav-link" href="#scrollspyHome" >Home</a>
            </li>
            <li class="nav-item">
              <a href="#scrollspyFeatures" class="mx-3 nav-link">Features</a>
            </li>
            <li class="nav-item">
              <a href="#scrollspyPricing" to="/home" class="nav-link">Pricing</a>
            </li>
          </ul> -->
          <router-link to="/" class="me-4">
            <button class="btn btn-outline-success">Go to App</button>
          </router-link>
          <router-link to="/auth/login">
            <!-- <span v-if="token === undefined"> -->
              <button class="btn btn-success">Sign In</button>
            <!-- </span> -->
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      token: localStorage?.token,
    }
  }
};
</script>

<style scoped>
.navpadding {
  padding-left: 80px;
  padding-right: 80px;
}

.linksnav {
  margin-top: 8px;
}

.nav-link.active {
  color: rgba(200, 22, 22, 0.9) !important;
}

@media (max-width: 767px) {
  .navpadding {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
