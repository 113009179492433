<template>
<InventoryHeaderTab />

<div class="mt-3 px-3 fw-bold d-flex justify-content-between gridtop">
    <div class="mt-2" style="font-size: 24px">Expiring Products</div>
    <div class="d-flex makeflex">
        <div class="d-flex mt-1">
            <select type="text" class="form-select me-2" placeholder="Search" style="width: 198px; height: 35px" v-model="tableData.period" @change="fetchData()">
                <option value="0">This Month</option>
                <option value="3">3 Months</option>
                <option value="6">6 Months</option>
                <option value="9">9 Month</option>
                <option value="12">12 Months</option>
                <option value="all">Expired Products</option>
            </select>
            <div style="margin-top: 2px">
                <!-- <button class="btn allbuttonsclr" @click="fetchData()" type="button">Run Report</button> -->
            </div>
        </div>
        <div class="d-flex align-items-center small pl-2 me-2">
            <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
            <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px" v-model="query" />
        </div>
        <div>

            <div class="dropdown">
                <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                    Export
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <a @click="exportToPdf" class="dropdown-item" href="#">PDF</a>
                    </li>
                    <li>
                        <a @click="reusableMixinToCSV('expired-products.csv')" class="dropdown-item" href="#">CSV</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="card thecard rounded-lg mt-3 mb-3 py-3 cardpadding" style="overflow-x: auto">
    <div v-if="permission_status === 403">
        <div class="text-center py-5" >
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
    </div>
    <div v-else>
        <div class="text-center py-5" v-if="details.length === 0 && loading === false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">No product expired for this month!</div>
        </div>
    </div>
    
    <div v-if="loading === true" class="text-center py-5">
        <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem;">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="loading === false && details.length > 0">

            <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option :value="pagination.total">All</option>
                    
                </select>
            </div>
            <!--begin::Actions-->

        </div>
        <!--end::Toolbar-->
    </div>
    <div class="" v-if="loading === false && details.length > 0"  style="margin-left:1.2rem">
        <table class="table table-sm-responsive">
            <thead class="thead-white" style="border-style: hidden !important">
                <tr class="pb-5 text-left">
                    <!-- <th class="py-3"><input type="checkbox" name="" id="" /></th> -->
                    <th class="fw-bold py-3 heading" style="width:30%">Products</th>
                    <th class="fw-bold py-3 heading">Quantity</th>
                    <th class="fw-bold py-3 heading">Cost Price</th>
                    <th class="fw-bold py-3 heading">Store</th>
                    <th class="fw-bold py-3 heading" style="width:15%">Best Before</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(products, index) in details" :key="index.id" class="text-left">
                    <!-- <th class="py-3"><input type="checkbox" name="" id="" /></th> -->
                    <td class="text-secondary py3 row1" style="font-size: 16px">{{products.product_name}}</td>
                    <!-- <td class="text-secondary py-3 row1" style="font-size: 16px">{{products.quantity}}</td> -->
                    <td class="text-secondary py3 row1">{{products?.quantity}}</td>
              
                    <td class="text-secondary py3 row1" style="font-size: 16px">
                        {{ $filters.formatMoney(products.cost_price, currentUserCurrency?.symbol)  }} 
                    </td>


                    <td class="text-secondary row1">
                        {{products?.store_name}}
                    </td>

                    <td class="text-secondary py-3 row1" style="font-size: 16px">
                        {{dateTime(products?.best_before, 'short')}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)" @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
</template>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";
import jsPDF from "jspdf";

import axios from "axios";
import {
    useToast
} from "vue-toastification";

import {
    useStore
} from "vuex";
import {
    reusableMixin
} from "../../../lib/reusableMixin";

export default {
    components: {
        InventoryHeaderTab,
    },
    mixins: [reusableMixin],
    data() {
        let sortOrders = {};
        let columns = [{
                width: "16%",
                label: "Name",
                name: "name",
                sortable: false,
            },
            {
                width: "15%",
                label: "Sortcode",
                name: "sortcode",
                sortable: false,
            },
            {
                width: "15%",
                label: "Phonecode",
                name: "phonecode",
                sortable: false,
            },
            {
                width: "5%",
                label: "Status",
                name: "status",
                sortable: false,
            },
            {
                width: "5%",
                label: "Actions",
                name: "actions",
                sortable: false,
            },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            details: [],
            loading: false,
            query: null,

            selectedModel: [],
            allSelected: false,
            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                // period:this.$route.query.period,
                draw: 0,
                length: 10,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: '',
                period: this.$route.query.period ?? '0'
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            store: useStore(),
            permission_status:null

        }
    },
    methods: {
        exportToPdf() {
            const pdf = new jsPDF();
            pdf.setFont("Helvetica");
            // pdf.setFontType("bold");
            pdf.text(105, 10, "IMS", null, null, "center");
            pdf.setFont("Helvetica");
            pdf.text(10, 20, 'Expired Product List');
            pdf.setFontSize("10");
            pdf.text(210, 20, `Generated at ${new Date()?.toISOString()}`);
            pdf.autoTable({
                startY: 25,
                html: ".table",
                headStyles: {
                    fillColor: ['green', 95, 240]
                }
            });
            pdf.save(`Expired Products-${this.formatDateMMDDYY()}.pdf`);
        },
        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },

        fetchData(url = '/inventory/expired-products') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.configPagination(data.data);
            }).catch(error => {
                this.errorMessagem = error
                this.loading = false;
                if(error?.response?.status === 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);

                }
            });
        },

        getModalCurrentId(id) {
            this.currentID = id;
        },
        computed: {
            permission() {
                return this.store.state.getCurrentUserPermission
            },
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
    },

    watch: {
        query() {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.table-plain tbody tr,
.table-plain tbody tr:hover,
.table-plain tbody td {
    background-color: transparent;
    border: none;
}

@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }

    .makeflex {
        flex-direction: column;
    }
}
</style>
