<template>

    <div class="gridtop">
        
        <PrintInvoice :title="'Creditor-Invoice-'" :urlA4="'/creditor-receipt/'"
            :url58mm="'/print58mm-creditor-receipt/'" :url80mm="'/print80mm-creditor-receipt/'"
            :currentUrl="'View Creditor Receipt'" :data="creditor_credits" :type="'Creditor Receipt'"
            :purchase_items="purchase_items" :purchase_array="purchase_array" 
            :purchase="purchase" :creditor_credits="creditor_credits" :loading="loading"
        />
    </div>
  </template>


<script>
import {
    useToast
} from "vue-toastification";
import axios from "axios";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import {
    useStore
} from "vuex";
import PrintInvoice from '../purchase/components/print-invoice.vue'

export default {
    components: {
          PrintInvoice
      },
    mixins: [reusableMixin],
    data() {
        return {
            creditor_credits:null,
            toast: useToast(),
            purchase: null,
            grand_total: 0,
            tax_amount: 0,
            creditor: [],
            purchase_items: [],
            purchase_array: [],
            store: useStore(),
            payment_type: null,
            amount: null,
            balance: "0.00",
            def_balance:"0.00",
            crudLoading: false,
            loading:true
        }
    },
    methods: {
        getCreditor() {
            this.loading = true
            axios
                .get(`/creditors/id/${this.$route.params.id}`)
                .then((response) => {
                    this.loading = false
                    this.creditor_credits = response.data.data;
                    this.creditor = response?.data?.data?.creditor;

                    this.purchase = this.creditor_credits?.purchase;
                    this.purchase_items = this.creditor_credits?.purchase?.purchase_item;
                    this.purchase_items.forEach((s) => {
                        this.purchase_array.push({
                            product_name: s?.product?.product_name,
                            cost_price: s?.cost_price,
                            variant_name:s?.variant?.variant_name ?? s?.product?.unit_of_measurement,
                            quantity: s?.quantity,
                            total: parseFloat(s?.amount_paid)
                        });
                    });
                    this.def_balance = this.creditor_credits?.total_amount - this.creditor_credits?.amount_paid;
                    this.balance = (this.def_balance).toFixed(2)
                })
                .catch(() => {
                    this.loading = true
                    this.toast.error("Oops,Something went wrong");
                });
        },

    },

    created() {
        this.getCreditor()
    }

}
</script>
  
  <style>
  @media (max-width: 767px) {
    .printpurchasebtns {
      margin-top: 10px;
    }
  }
  </style>