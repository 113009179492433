<template>
    <InventoryHeaderTab />

    <div class="mt-3 gridtop">
        <div class="d-flex justify-content-between my-3">
            
            

            <div class="mt-2 fw-bold" style="font-size:20px">
                <router-link to="/all-stock-transfer">
                    <font-awesome-icon class="arrowradius ms-1" prefix="far" icon="angle-left" />
                </router-link>
                {{store_transfer?.batch_code}}
            </div>
            <PrintButtonHeader :title="'Invoice-'+store_transfer?.batch_code"  :urlA4="'/view-stock-transfer/'" :url58mm="'/print58mm-stock-transfer/'" :url80mm="'/print80mm-stock-transfer/'" :currentUrl="'Print 80mm Stock Transfer'" :data="store_transfer" :type="'Stock Transfer'"/>
        </div>
        <div class="bg-white d-flex justify-content-center rounded-lg mt-3 mb-3 px-4 py-3 cardpadding"
            style="overflow-x: auto;">
            <div class="printborder">
                <div class="p-5 sales_invoice" id="printMe">
                    <div>Date: {{dateTime(store_transfer?.date) + ', ' + timeConvert(store_transfer?.time)}}</div>
                    <div>Transfer No: {{store_transfer?.batch_code}}</div>
                    <div>Source Store: {{ store_transfer?.store?.store_name }}</div>
                    <div>Destination Store: {{ store_transfer?.destination?.store_name }}</div>
                    <div>Transfered By: {{store_transfer?.user?.fullname}}</div>

                    <div class="printdottedborder mt-3"></div>
                    <div class="printdottedborder mt-1 mb-3"></div>
                    <hr class="solid_line" />
                    <div v-for="item in issue_stock_item" :key="item.id">
                        <div class="d-flex justify-content-between">
                            <div>{{item?.product?.bar_code}}</div>
                            <div v-if="item?.variant">({{  item?.variant?.variant_name }})</div>
                            <div v-else>({{  item?.product?.unit_of_measurement }})</div>

                        </div>
                        <div class="d-flex justify-content-between">
                            <div>{{item?.product?.product_name}}</div>
                                <div>{{ item?.quantity}}</div>
                        </div>
                        <hr class="solid_line" />
                    </div>

                    <div class="printdottedborder mt-3"></div>
                    <div class="printdottedborder mt-1 mb-3"></div>
                    <div class="mt-4">Powered by BMAC POS</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InventoryHeaderTab from './component/inventoryheadertab.vue'
// import JQuery from 'jquery'
import axios from "axios";
import { reusableMixin } from '../../../lib/reusableMixin';

export default {
    components: {
        InventoryHeaderTab
    },
    mixins: [reusableMixin],

    data() {
        return {
            store_transfer: null,
            issue_stock_item: []
        }
    },

    methods: {

        getStockTransfer() {
            axios.get(`/inventory/issue-stock/${this.$route.params.id}`).then((response) => {
                this.store_transfer = response.data.data
                this.issue_stock_item = response.data.data.issue_stock_item

            }).catch(() => {
                this.toast.error("Oops, Something went wrong")
            })
        }
    },

    computed: {
        totalPrice() {
            let total = this.adjustment_items.reduce((a, b) => {
                return a + parseFloat(b.selling_price) * b.unit_quantity;
            }, 0);
            return (total).toFixed(2);
        }
    },

    created() {
        this.getStockTransfer()
    }

}
</script>

<style>
/* style sheet for "A4" printing */
/* @media print {

    html,
    body {
        width: 80mm;
        height: 100%;
        position: absolute;
    }

    .print {
        display: none;
    }

} */

/* A4 Landscape*/
/* @page {
    size: A4 landscape;
    margin: 10%;
}

@page {
    margin: 100cm;
    size: portrait !important;
} */
</style>
