<template>
  <div class="" style="width: 100%">
    <ul class="nav nav-tabs thetoppills py-2">
      <li class="nav-item">
        <router-link
          class="nav-link makeGreen"
          active-class="pillactive"
          to="/all-reports"
        >
          Daily Sales Report
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/customer-report"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          :class="
            $router.currentRoute.value.name === 'User Customer Report' ||
            $router.currentRoute.value.name === 'Customer Invoice'
              ? 'pillactive'
              : ''
          "
          v-if="!freemium"
        >
          Customer Report
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Customer Report</span>
      </li>
      <li class="nav-item">
        <router-link
          to="/sales-report"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          :class="
            $router.currentRoute.value.name === 'Sales Report' ||
            $router.currentRoute.value.name === 'Sales Invoice'
              ? 'pillactive'
              : ''
          "
        >
          Sales Report
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/profitability-report"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          :class="
            $router.currentRoute.value.name === 'Profitability Report'
              ? 'pillactive'
              : ''
          "
          v-if="!freemium"
        >
          Profitability Report
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Profitability Report</span>
      </li>
      <li class="nav-item">
        <router-link
          to="/purchase-report"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          :class="
            $router.currentRoute.value.name === 'Purchase Report' ||
            $router.currentRoute.value.name === 'Purchase Report Invoice'
              ? 'pillactive'
              : ''
          "
        >
          Purchase Report
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/stock-transfer-report"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          :class="
            $router.currentRoute.value.name === 'Stock Transfer Report' ||
            $router.currentRoute.value.name === 'Stock Transfer Report Details'
              ? 'pillactive'
              : ''
          "
          v-if="!freemium"
        >
          Stock Transfer Report
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Stock Transfer Report</span>

      </li>
      <li class="nav-item">
        <router-link
          to="/stock-adjustment-report"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          :class="
            $router.currentRoute.value.name ===
              'Stock Adjustment Report Details' ||
            $router.currentRoute.value.name === 'Stock Adjustment Report'
              ? 'pillactive'
              : ''
          "
          v-if="!freemium"
        >
          Stock Adjustment Report
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Stock Adjustment Report</span>
      </li>
      <li class="nav-item">
        <router-link
          to="/performance"
          active-class="pillactive"
          class="nav-link makeGreen"
          id="performance-tab"
          v-if="!freemium"
        >
          Top Selling Product
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Top Selling Product</span>
      </li>
      <li class="nav-item">
        <router-link
          to="/sales-by-category"
          active-class="pillactive"
          class="nav-link makeGreen"
          v-if="!freemium"
        >
          Sales By Category
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Sales By Category</span>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <router-link
          to="/report-bin"
          active-class="pillactive"
          class="nav-link makeGreen"
        >
          Bin Card
        </router-link>
      </li> -->
      <li class="nav-item" role="presentation">
        <router-link
          to="/audit-trail"
          active-class="pillactive"
          class="nav-link makeGreen"
          v-if="!freemium"
        >
          Audit Trail
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Audit Trail</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import axios from "axios";


export default {
  name: "ReportHeaderTab",
  data () {
    return {
      freemium: true,
      toast: useToast(),
    } 
  },
  methods: {
    activeSubscription() {
      // loading.value = true
      axios.get("/subscription/user")
        .then((response) => {
          // loading.value = false
          // subscriptions.value = response.data.data
          // // console.log(response.data.data, 'hh')
          // allUsers()
          // superUser()
          if(response.data.data.filter(data => data.status == 1 || data.status == 0).length == 0) {
            this.freemium = true;
          }else {
            this.freemium = false;
          }
        }).catch(() => {
          // loading.value = false
        })
    },

    freemiumPrompt () {
      this.toast.error('subscribe or upgrade to use this functionality')
    }
  },
  mounted() {
      this.activeSubscription();
  },

};
</script>

<style scoped>
/* .tabbable .nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.tabbable .nav-tabs .nav-link {
  white-space: nowrap;
} */

.nav {
  white-space: nowrap;
  display: block !important;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  width: 73rem;
}

.nav li {
  display: inline-block;
}

@media (max-width: 767px) {
  .nav {
    width: 30rem;
    max-width: 100%;
  }
}
</style>
