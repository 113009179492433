<template>
<div>
<SettingHeaderTab />

<div>
    <div class="tab-pane mt-3 show active bg-white rounded-lg px-3 py-3" id="company">
        <div class="d-flex justify-content-between">
            <div>
                <h4>Edit Company Info</h4>
                <div class="text-muted" style="font-size: 10px">
                    Set up your company profile
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                <li class="text-danger" style="list-style: none">
                    <small>{{ errors }}</small>
                </li>
            </ul>
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <label style="cursor:pointer" v-if="company_image || new_company_image">
                <font-awesome-icon class="img-icon" prefix="fal" icon="camera" />
                <!-- ../../private-assets/images/pseudopp.jpg -->
                <div class="imgedit"><img :src="new_company_image == '' ? (host.hostName+company_image): new_company_image" alt="" class="logosnippet" style=""></div>
                <input type="file" class="form-control" style="display:none" @change="onFileSelected($event)" />
            </label>
            <label style="cursor:pointer" v-else>
                <font-awesome-icon class="img-icon" prefix="fal" icon="camera" />
                <div class="imgedit">
                    <div class="logosnippet" style="color: gray">
                        <div class="insidelogo">
                            <div>Upload</div>
                            <div>your logo</div>
                        </div>
                    </div>
                </div>
                <input type="file" class="form-control" style="display:none" @change="onFileSelected($event)" />
            </label>
        </div>
        <form class="mt-4" @submit.prevent="updateCompany">
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Company Name</label>
                    <input type="text" class="form-control" v-model="company_name" name="company_name">
                </div>
                <div class="form-group col-md-4">
                    <label>Email Address</label>
                    <input type="text" class="form-control" v-model="company_email" name="company_email">
                </div>
                <div class="form-group col-md-4">
                    <label>Address</label>
                    <input type="text" class="form-control" v-model="address" name="address">
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>Country</label>
                    <select v-model="country_id" class="form-select" @change="getStates($event)" name="country_id">
                        <option>Choose Country</option>
                        <option :value="country?.id" v-for="country in countries" :key="country?.id">{{ country?.name }}
                        </option>
                    </select>
                </div>

                <div class="form-group col-md-4">
                    <label class="mb-2" for="inputState">State</label>
                    <select v-model="state_id" class="form-select" @change="getCities($event)" name="state_id">
                        <option value="">Choose state</option>
                        <option :value="state.id" v-for="state in states" :key="state?.id">{{ state?.name }}</option>
                    </select>
                </div>
     
                <div class="form-group col-md-4">
                    <label class="mb-2" for="inputZip">City</label>
                    <select v-model="city_id" class="form-select" name="city_id">
                        <option value="">Choose city</option>
                        <option :value="city.id" v-for="city in cities" :key="city?.id">{{ city?.name }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>Phone Number</label>: {{phone}}
                    <vue-tel-input v-model="phone" @change="onInput($event)" name="phone" mode="international"></vue-tel-input>
                </div>
                <div class="form-group col-md-4">
                    <label>Postal Code</label>
                    <input type="text" class="form-control" v-model="postal_code" name="postal_code" onkeypress="return /[0-9]/i.test(event.key)">
                </div>
                <div class="form-group col-md-4">
                    <label>Business Type</label>
                    <select class="form-select" v-model="business_type" name="business_type">
                        <option selected>Choose type</option>
                        <option value="Retail/Grocery Shop">Retail/Grocery Shop</option>
                        <option value="Electronics">Electronics</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Laundry service">Laundry service</option>
                        <option value="Food vendor/Restaurant">Food vendor/Restaurant</option>
                        <option value="RSupermarket">Supermarket</option>
                        <option value="Wholesaler">Wholesaler</option>
                        <option value="Apparel (clothing, Shoes, etc)">Apparel (clothing, Shoes, etc)</option>
                        <option value="Others">Others</option>

                    </select>
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>RC Number</label>
                    <input type="text" class="form-control" v-model="rc_number" name="rc_number" />
                </div>
                <div class="form-group col-md-4">
                    <label>Number of staff</label>
                    <input type="number" class="form-control" v-model="no_of_staff" name="no_of_staff" min="1"/>
                </div>

                 <div class="form-group col-md-4">
                    <label for="inputZip">Currency</label>
                    <select v-model="currency_id" class="form-select" name="currency_id">
                        <option selected disabled>Choose currency</option>
                        <option :value="currency.id" v-for="currency in allCurrencies" :key="currency.id">{{ currency.name }}</option>
                    </select>
                </div>
            </div>


            <div class="mt-4 d-flex justify-content-end buttoncustomer">
                <button type="submit" class="btn px-5 me-5 allbuttonsclr text-white" v-if="crudLoading === false">Update</button>
                <button type="submit" class="btn px-5 me-5 allbuttonsclr text-white" v-if="crudLoading === true" disabled>
                    <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Processing</span>
                    </div>
                </button>

                <button type="button" class="btn px-5 exportbtn" @click="$router.go(-1)">Cancel</button>
            </div>
        </form>
    </div>
</div>
</div>
</template>

<script>
import host from "../../../main"
import {
    ref
} from '@vue/reactivity';
import axios from 'axios';
import SettingHeaderTab from './components/settingheadertab.vue';
import {
    onMounted
} from '@vue/runtime-core';
import {
    useToast
} from "vue-toastification";
import {
    useRouter
} from "vue-router";

export default {
    components: {
        SettingHeaderTab,
    },
    setup() {
        const phone = ref(null);
        const company_email = ref(null);
        const address = ref(null);
        const country_id = ref(null);
        const city_id = ref(null);
        const state_id = ref(null);
        const postal_code = ref(null);
        const business_type = ref(null);
        const company_name = ref(null);
        const no_of_staff = ref(null);
        const rc_number = ref(null);
        const countries = ref(null);
        const states = ref(null);
        const default_state = ref("")
        const default_city =  ref("")
        const cities = ref(null);
        const currency_id = ref(null)
        const loading = ref(null);
        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref("");
        const allCurrencies = ref([])
        const crudLoading = ref(false)
        const company_image = ref("")
        const new_company_image = ref("")

        const onInput = (event) => {
            phone.value = event.target.value
        }

        const getCurrencies = async () => {
            loading.value = true

            const response = await axios.get('/settings/currency')
            allCurrencies.value = response.data.data.data
            loading.value = false
        }
        const singleCompany = async () => {
            await axios.get('/settings/company').then((response) => {
                getDefaultState(response.data.data.data.company.country_id);
                getDefaultCity(response.data.data.data.company.state_id);
                phone.value = response.data.data.data.company.phone
                company_email.value = response.data.data.data.company.company_email
                address.value = response.data.data.data.company.address
                city_id.value = response.data.data.data.company.city_id
                country_id.value = response.data.data.data.company.country_id
                state_id.value = response.data.data.data.company.state_id
                default_state.value = response.data.data.data.state
                default_city.value = response.data.data.data.city
                postal_code.value = response.data.data.data.company.postal_code
                business_type.value = response.data.data.data.company.business_type
                company_name.value = response.data.data.data.company.company_name
                rc_number.value = response.data.data.data.company.rc_number
                no_of_staff.value = response.data.data.data.company.no_of_staff
                company_image.value = response.data.data.data.company.company_image
                currency_id.value = response.data.data.data.company.currency_id
                states.value = response.data.data.data.states;
                cities.value = response.data.data.data.cities;

            })
        }
    
        const updateCompany = async () => {
            loading.value = true;
            crudLoading.value = true
            await axios
                .post("/settings/company", {
                    phone: phone.value,
                    company_email: company_email.value,
                    address: address.value,
                    city_id: city_id.value,
                    country_id: country_id.value,
                    state_id: state_id.value,
                    postal_code: postal_code.value,
                    business_type: business_type.value,
                    company_name: company_name.value,
                    no_of_staff: no_of_staff.value,
                    rc_number: rc_number.value,
                    new_company_image: new_company_image.value,
                    currency_id : currency_id.value

                })

                .then((res) => {
                    loading.value = false;
                    router.push("/company-info");
                    toast.success(res.data.message);
                    crudLoading.value = false

                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status === 500) {
                        toast.error("An error occured please try again");
                    }
                    loading.value = false;
                    crudLoading.value = false

                });
        }
        const getAllCountries = async () => {
            const response = await axios.get('countries')
            countries.value = response.data.data.data
            loading.value = false
        }

        const getStates = async (event) => {
            let country_id = event.target.value;
            const response = await axios.get("/states/" + country_id);
            states.value = response.data.data.data;
            loading.value = false;
        };

        const getCities = async (event) => {
            let state_id = event.target.value;
            const response = await axios.get("/cities/" + state_id);
            cities.value = response.data.data.data;
            loading.value = false;
        };

        const getDefaultState = async (country_id) => {
            const response = await axios.get("/states/" + country_id);
            states.value = response.data.data.data;
            loading.value = false;
        };

        const getDefaultCity = async (state_id) => {
            const response = await axios.get("/cities/" + state_id);
            cities.value = response.data.data.data;
            loading.value = false;
        };

        const onFileSelected = (event) => {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (event) => {
                new_company_image.value = event.target.result;
            };
            reader.readAsDataURL(file);

        }

        onMounted(
            singleCompany(), getAllCountries(),getCurrencies()
        )

        return {
            loading,
            phone,
            company_email,
            address,
            country_id,
            city_id,
            state_id,
            currency_id,

            default_state,
            default_city,
            postal_code,
            business_type,
            company_name,
            countries,
            states,
            cities,
            rc_number,
            no_of_staff,
            company_image,
            new_company_image,
            onInput,
            singleCompany,
            updateCompany,
            getAllCountries,
            allCurrencies,
            getCities,
            getStates,
            onFileSelected,
            getCurrencies,
            host,
            validationErrors,
            crudLoading

        }
    }
}
</script>

<style scoped>
.img-icon {
    position: absolute;
    z-index: 7;
    margin-top: 66px;
    margin-left: 93px;
    color: #18b768;
}

.imgedit {
    position: relative;
}

@media (max-width: 576px) {
    .allbuttonsclr {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .exportbtn {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

</style>
