<template>
    <InventoryHeaderTab />

    <div class="mt-3 gridtop">
        <div class="d-flex justify-content-between my-3">
            <div class="mt-2 fw-bold" style="font-size:20px">
                <span @click="$router.go(-1)" style="cursor:pointer">
                    <font-awesome-icon class="arrowradius ms-1" prefix="far" icon="angle-left" />
                </span>
                {{store_transfer?.batch_code}}
            </div>
            <PrintButtonHeader :title="'Invoice-' + store_transfer?.batch_code" :urlA4="'/view-stock-transfer/'"
                :url58mm="'/print58mm-stock-transfer/'" :url80mm="'/print80mm-stock-transfer/'"
                :currentUrl="'Print 80mm Stock Transfer'" :data="store_transfer" :type="'Stock Transfer'" />

        </div>

        <div class="bg-white rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto;" id="printMe">
            <div v-if="loading === true" class="text-center py-5">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!loading">

                <div class="d-flex justify-content-between my-5 headercreate" style="font-size: 13px;">
                    <div class="text-secondary">Date: <span class=" text-dark fw-bold">{{ dateTime(store_transfer?.date)
                    }}</span></div>
                    <div class="text-secondary">STOCK TRANSFER NUMBER: <span class="text-dark fw-bold">{{
                            store_transfer?.batch_code
                    }}</span></div>
                    <div class="text-secondary">SOURCE STORE: <span class="fw-bold text-dark">{{
                            store_transfer?.store?.store_name
                    }}</span></div>
                    <div class="text-secondary">DESTINATION STORE: <span class="fw-bold text-dark">{{
                            store_transfer?.destination?.store_name
                    }}</span></div>
                    <div class="text-secondary">TRANSFERRED BY: <span class="fw-bold text-dark">{{
                            store_transfer?.user?.fullname
                    }}</span></div>
                </div>
                <div class="">
                    <table class="table table-sm-responsive">
                        <thead class="thead-light" style="border-style: hidden !important;">
                            <tr class="pb-5 text-center">
                                <th class="fw-bold" style="font-size: 14px;">S/N</th>
                                <th class="fw-bold" style="font-size: 14px;">Products</th>
                                <th class="fw-bold" style="font-size: 14px;">Variant</th>
                                <th class="fw-bold" style="font-size: 14px;">Quantity</th>
                            </tr>
                        </thead>
                        <tbody v-for="(item, index) in issue_stock_item" :key="index">
                            <tr class="text-center">
                                <th class="text-secondary" style="font-size: 16px;">{{ index + 1 }}</th>
                                <td class="text-secondary" style="font-size: 16px;">{{ item?.product?.product_name }}
                                </td>
                                <td class="text-secondary" style="font-size: 16px;">{{ item?.variant?.variant_name ??
                                        item?.product?.unit_of_measurement
                                }}</td>
                                <td class="text-secondary" style="font-size: 16px;">{{ item?.quantity }}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="mt-4 d-flex justify-content-end">
        <div class="me-4 fw-bold">Grand Total:</div>
        <span>₦{{totalPrice}}</span>
      </div> -->
        </div>
    </div>
</template>

<script>
import InventoryHeaderTab from './component/inventoryheadertab.vue'
import {
    useToast
} from "vue-toastification";
import axios from "axios";

import {
    reusableMixin
} from "../../../lib/reusableMixin";

export default {
    components: {
        InventoryHeaderTab
    },
    mixins: [reusableMixin],

    data() {
        return {
            toast: useToast(),
            store_transfer: null,
            issue_stock_item: []
        }
    },
    methods: {

        getStockTransfer() {
            this.loading = true
            axios.get(`/inventory/issue-stock/${this.$route.params.id}`).then((response) => {
                this.loading = false
                this.store_transfer = response.data.data
                this.issue_stock_item = response.data.data.issue_stock_item

            }).catch(() => {
                this.toast.error("Oops, Something went wrong")
            })
        }
    },
    computed: {
        totalPrice() {
            let total = this.issue_stock_item.reduce((a, b) => {
                if (b.variant) {
                    return a + parseFloat(b.variant?.variant_selling_price);
                } else {
                    return a + parseFloat(b.selling_price) * b.unit_quantity;
                }
            }, 0);
            return (total).toFixed(2);
        }
    },
    created() {
        this.getStockTransfer()
    }

}
</script>

<style>

</style>
