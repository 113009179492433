<template>
    <div>
    <div id="printMe">

        <div class="d-flex justify-content-between mb-5">
            <div class="fw-bold" style="font-size:45px; padding-left: 20px">Invoice</div>
            <div style="padding-right: 20px;"><img style="max-width:120px;" class="invoicelogo"
                    :src="host + company?.company_image" alt="Company Logo"/>
            </div>
        </div>

        <div class="d-flex justify-content-between mb-5">
            <div style="padding-left: 20px;">
                <div class="fw-bold">Customer</div>
                <div class="fw-bold" style="text-transform:uppercase">{{ sales?.customer?.customer_name }}</div>
                <div class="">{{ sales?.customer?.address }}</div>
                <div class="">{{ sales?.customer?.email }}</div>
            </div>
            <div style="padding-right: 20px;">
                <div class="fw-bold">{{ company?.company_name }}</div>
                <div class="">{{ sales?.store?.address }}</div>
                <div class="mt-1">{{ sales?.store?.phone_no }}, {{ company?.phone }}</div>
                <div class="mt-1">{{ company?.company_email }}</div>
            </div>
        </div>

        <table class="table table-sm-responsive">
            <thead class="thead" style="border-style: hidden !important;">
                <tr class="center">
                    <th class="fw-bold" style="font-size: 14px;">Sales Date</th>
                    <th class="fw-bold" style="font-size: 14px;">Sales No</th>
                    <th class="fw-bold" style="font-size: 14px;">Store</th>
                    <th class="fw-bold" style="font-size: 14px;">Sold By</th>
                </tr>
            </thead>
            <tbody style="border-style: hidden !important;" v-if="!loading">
                <tr class="center">
                    <td>{{ dateTime(sales?.date) }}</td>
                    <td>{{ sales?.batch_code }}</td>
                    <td>{{ sales?.store?.store_name }}</td>
                    <td>{{ sales?.user?.fullname }}</td>
                </tr>
            </tbody>
        </table>

        <div class="table-scroll">
            <table class="table table-sm-responsive">
                <thead class="thead bg-light" style="border-style: hidden !important;">
                    <tr class="center">
                        <th class="fw-bold" style="font-size: 14px;width:20%;">PRODUCT</th>
                        <th class="fw-bold" style="font-size: 14px;">VARIANT</th>
                        <th class="fw-bold" style="font-size: 14px;">QTY</th>
                        <th class="fw-bold" style="font-size: 14px;">PRICE({{ company?.currency?.symbol }})</th>
                        <th class="fw-bold" style="font-size: 14px;">DISCOUNT({{ company?.currency?.symbol }})</th>
                        <th class="fw-bold" style="font-size: 14px;">TAX({{ company?.currency?.symbol }})</th>
                        <th class="fw-bold" style="font-size: 14px;">AMOUNT({{ company?.currency?.symbol }})</th>
                    </tr>
                </thead>
                <tbody style="border-style: hidden !important;" v-if="!loading">
                    <tr class="center" v-for="(p) in sales_array" :key="p.id">
                        <td>{{ p?.product_name }}</td>
                        <td>{{ p?.variant_name }}</td>
                        <td>{{ p?.quantity }}</td>
                        <td>
                            {{ $filters.formatMoney(p?.selling_price) }}
                        </td>
                        <td v-if="p.discount">{{ $filters.formatMoney(p?.discount) }}</td>
                        <td v-else>{{ 'N/A' }}</td>

                        <td v-if="p.tax">{{ $filters.formatMoney(p?.tax) }}</td>
                        <td v-else>{{ 'N/A' }}</td>
                        <td>{{$filters.formatMoney(p?.total) }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="text-center" style="margin:50px" v-if="loading">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                </div>
            </div>
        </div>

        <div class="topd d-flex justify-content-end" style="padding-right: 20px;" v-if="!loading">
            <div style="width:300px;">
                <div class="d-flex justify-content-between">
                    <div class="fw-bold subtotal">SUBTOTAL</div>
                    <div class="leftsub">
                        {{$filters.formatMoney(subTotal, company?.currency?.symbol)}}
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="fw-bold subtotal">DISCOUNT</div>
                    <div class="">
                        {{$filters.formatMoney(discountTotal, company?.currency?.symbol)}}

                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="fw-bold taxmargin">TAX</div>
                    <div class="">
                        {{$filters.formatMoney(taxTotal, company?.currency?.symbol)}}
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="fw-bold totalmargin">TOTAL</div>
                    <div class="">
                        {{$filters.formatMoney(grandTotal, company?.currency?.symbol)}}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading">
            <hr class="firsthr">

            <div class="fw-bold" style="padding: 20px;">Payment Information</div>

            <div style="padding: 20px;">
                <div class="d-flex justify-content-between">
                    <div>Payment Type</div>
                    <div class="fw-bold" style="text-transform: capitalize;">{{ sales?.payment_type  == 'part' ?  'Part Payment' : sales?.payment_type }}</div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Paid Amount</div>
                    <div class="fw-bold">
                        {{$filters.formatMoney(sales?.amount_paid, company?.currency?.symbol)}}
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1"
                    v-if="sales?.payment_type == 'cash' || sales?.payment_type == 'card'">
                    <div>Change</div>
                    <div class="fw-bold">
                        {{$filters.formatMoney(sales?.change, company?.currency?.symbol)}}
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Balance</div>
                    <div class="fw-bold" v-if="sales?.balance !== null">
                        {{$filters.formatMoney(sales?.balance, company?.currency?.symbol)}}
                    </div>
                    <div class="fw-bold" v-else>{{$filters.formatMoney(0, company?.currency?.symbol)}}</div>

                </div>
            </div>
        </div>

    </div>
    </div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import {
    reusableMixin
} from "../../../../lib/reusableMixin";
import {
    useStore
} from "vuex";
import host from "../../../../main";
// import axios from 'axios';

export default {
    props: {
        sales_items: Array,
        sales_array: Array,
        sales: Array,
        loading: Boolean
    },
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            grand_total: 0,
            tax_amount: 0,
            store: useStore(),
            company: null
        }
    },
    methods: {
        // async getCompany(){
        //     await axios.get('/settings/company').then((response) => {
        //     this.company = response.data.data.data.company;
        //     })
        // },

    },
    computed: {
        host() {
            return host.hostName
        },
        loggedInUser() {
            return this.store.state.getLoggedInUser
        },
        grandTotal() {
            return this.sales_array.reduce((a, b) => {
                return ((parseFloat(a) + (b.total) + b.tax) - b.discount).toFixed(2)
            }, 0);
        },
        subTotal() {
            return this.sales_array.reduce((a, b) => {
                return a + b.total
            }, 0);
        },
        discountTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a) + parseFloat(b.discount)).toFixed(2)
            }, 0);
        },
        taxTotal() {
            return this.sales_array.reduce((a, b) => {
                return a + b.tax
            }, 0);
        }
    },

    created() {
        this.getCompany();
    }


}
</script>
<style>
.table-scroll {
    overflow-x: auto
}
</style>