<template>
    <Navbar />
    <div>
      <div class="price-bg">
        <div class="d-flex justify-content-center mt-5">
            <div class="package_padding">
                <h1 class="text-center">
                    We have the ideal Package
                    for your Business
                </h1>
                <div class="d-flex justify-content-center">
                  <div class="p-2 select_one">
                      <div class="d-flex justify-content-between align-items-center">
                          <h3 @click="webClicked()" :class="[web && 'active', 'items']">Web</h3>
                          <h3 @click="mobClicked()" :class="[!web && 'active', 'items']">Mobile Pos</h3>  
                      </div>
                  </div>
                </div>
            </div>
            </div>
        </div>
              <div class="radio_buttons d-flex justify-content-center">
                  <div class="padd d-flex justify-content-evenly">
                      <div class="radio-item">
                          <input @click="monthly" :checked=type.monthly type="radio"  id="ritema" name="ritem" value="ropt1">
                          <label for="ritema">Monthly</label>
                      </div>
                      <div class="radio-item">
                          <input type="radio" @click="Quaterly" :checked=type.Quaterly  id="ritemb" name="ritem" value="ropt2">
                          <label for="ritemb">Quarterly</label>
                      </div>
                      <div class="radio-item">
                          <input type="radio" @click="sixMonths" :checked=type.sixMonths id="ritemc" name="ritem" value="ropt1">
                          <label for="ritemc">6 Months</label>
                      </div>
                      
                      <div class="radio-item">
                          <input type="radio" @click="Yearly" :checked=type.Yearly id="ritemd" name="ritem" value="ropt2">
                          <label for="ritemd">Yearly</label>
                      </div>

                  </div>
              </div>
        <div class="d-flex mb-5 prices_container justify-content-center">
            <div class="price ms-sm-4 ms-0 mb-4">
                <div class="content py-4">
                    <div class="top_text text-center">
                        <h2>Basic</h2>
                        <h1>₦{{basicAmount}}</h1>
                        <h3>PER MONTH</h3>
                        <button @click="setAmount(basicAmount)">BUY NOW</button>
                        <h4>or <a href="">Try it for Free</a></h4>
                        <div class="p-2">
                            <ul>
                                <li>2 locations, Admin + 2 Users</li>
                                <li>Products Expiring Notification</li>
                                <li>Multi- Location capacity</li>
                                <li>Split Payment Functionality</li>
                                <li>Make Sales in different valiant e.g Pcs.</li>
                                <li>24/7 Customer service support</li>
                                <li>Access control for employees</li>
                                <li>24/7 Customer service support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="price mx-sm-5 mx-0 mb-4">
                <div class="content py-4">
                    <div class="top_text text-center">
                        <h2>Standard</h2>
                        <h1>₦{{standardAmount}}</h1>
                        <h3>PER MONTH</h3>
                        <button class="active" @click="setAmount(standardAmount)">BUY NOW</button>
                        <h4>or <a href="">Try it for Free</a></h4>
                        <div class="p-2">
                            <ul>
                                <li>Up to 5 Locations, Admin + 8 Users</li>
                                <li>Products Expiring Notification</li>
                                <li>Products Expiring Notification</li>
                                <li>Split Payment Functionality</li>
                                <li>Make Sales in different valiant e.g Pcs.</li>
                                <li>24/7 Customer service support</li>
                                <li>Access control for employees</li>
                                <li>24/7 Customer service support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="[!web && 'd-none','price me-sm-4 me-0 mb-4']" >
                <div class="content py-4">
                    <div class="top_text text-center">
                        <h2>Premium</h2>
                        <h1>Custom</h1>
                        <h3>PER MONTH</h3>
                        <button type="button" data-bs-toggle="modal"
                          data-bs-target="#modalId">Contact Us</button>
                        <h4>or <a href="">Try it for Free</a></h4>
                        <div class="p-2">
                            <ul>
                                <li>More than 5 Locations</li>
                                <li>Products Expiring Notification</li>
                                <li>Multi- Location capacity</li>
                                <li>Split Payment Functionality</li>
                                <li>Make Sales in different valiant e.g Pcs.</li>
                                <li>24/7 Customer service support</li>
                                <li>Access control for employees</li>
                                <li>24/7 Customer service support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5" style="background: #F8FFFB;">
      <div class="homepadding">
        <Footer />
      </div>
    </div>


        <!-- Modal -->
    <div class="modal fade" id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog mw-25 w-50 modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
                  <img src="../assets/images/contact2-.png" alt="trash">
                </div>
      
                <div class="fw-bold text-center mt-3 mb-2" style="font-size:20px">
                    Contact Us
                </div>
            <div class="text-center">
              <div>Call Customer Support: <span class="text-muted">{{ super_user?.profile?.phone_no  }}</span></div>
              <div>Email: <span class="text-muted">{{ super_user?.email ?? 'business@onebmac.com' }} </span> </div>
            </div>
          </div>
          <div class="my-3 text-center">
            <button type="button" class="btn btn-default" style="border: 1px solid grey" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    
    <div class="modal fade" id="modalId2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog mw-25 w-50 modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-around payment_home">
              <div class="fluter_home">
                  <span>
                    <img @click="flutterPayment" src="../assets/images/flutter.png" alt="flutter">
                  </span>
              </div>

              <div class="stack_home position-relative">
                <img src="../assets/images/paystack.png" alt="paystack">
                <span>
                    <paystack
                      buttonClass="'hide_pay position-absolute'"
                      buttonText=""
                      :publicKey="publicKey"
                      :email="user.getLoggedInUser?.email"
                      :amount="amount"
                      :reference="reference"
                      :onSuccess="onSuccessfulPayment"
                      :onCanel="onCancelledPayment"
                      style="background: transparent; border: none; position: absolute; left: 0; top: 0;bottom: 0; width: 100%;"
                      >
                  </paystack>
                </span>
              </div>
            </div>
          </div>
          <div class="my-3 text-center">
            <button ref="closeModalRef" type="button" class="btn btn-default" style="border: 1px solid grey" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>


    
    <button class="d-none" ref="payRef" type="button" style="padding:0px;border:0px;" data-bs-toggle="modal"
      data-bs-target="#modalId2">
    </button>
    
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";
import { reusableMixin } from '../lib/reusableMixin';
import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
import paystack from "vue3-paystack";
import { useFlutterwave } from "flutterwave-vue3"


export default {
    components: {
      Navbar,
      Footer,
      paystack
    },
  mixins: [reusableMixin],
    data() {
    const router = useRouter();
    const toast = useToast();
      return{
        web: true,
        type: {
          monthly: true,
          Quaterly: false,
          sixMonths: false,
          Yearly: false,
        },
        basicAmount: '7,500',
        standardAmount: '15,000',
        plans: [],
        categories: [],
        types: [],
        details: [],
        loading: false,
        router: router,
        toast: toast,
        amount: 0,
        token: localStorage?.token,
        payment: false,
        publicKey:'pk_live_7505355c505d780e487e23eb2ae2911b8a42f09e',
        user: localStorage.vuex !== undefined && JSON.parse(localStorage?.vuex),
        planData: {
          type: 'Monthly',
          category: 'IMS',
          plan: null,
          plan_details_id: null,
          amount: 0,
          user_sub_id: null
        }
      }
        
    },
    computed: {
      reference(){
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for( let i=0; i < 10; i++ )
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
      }
    },
    methods: {
      webClicked() {
        this.web = true;
        this.basicAmount='7,500';
        this.standardAmount = '15,000';
        this.type= {
          monthly: true,
          Quaterly: false,
          sixMonths: false,
          Yearly: false,
        }
      },
      mobClicked () {
        this.web = false;
        this.basicAmount='5,000';
        this.standardAmount = '8,500';
        this.type= {
          monthly: true,
          Quaterly: false,
          sixMonths: false,
          Yearly: false,
        }
      },
      monthly() {
        this.type= {
          monthly: true,
          Quaterly: false,
          sixMonths: false,
          Yearly: false,
        }
       if(this.web) {
        this.basicAmount='7,500';
        this.standardAmount = '15,000';
       }else {
        this.basicAmount='5,000';
        this.standardAmount = '8,500';
       }
      },
      Quaterly() {
        this.type= {
          monthly: false,
          Quaterly: true,
          sixMonths: false,
          Yearly: false,
        }
       if(this.web) {
        this.basicAmount='20,000';
        this.standardAmount = '42,500';
       }else {
        this.basicAmount='15,000';
        this.standardAmount = '25,000';
       }
      },
      sixMonths() {
        this.type= {
          monthly: false,
          Quaterly: false,
          sixMonths: true,
          Yearly: false,
        }
       if(this.web) {
        this.basicAmount='35,000';
        this.standardAmount = '80,000';
       }else {
        this.basicAmount='25,000';
        this.standardAmount = '50,000';
       }
       
      },
      Yearly() {
       if(this.web) {
        this.type= {
          monthly: false,
          Quaterly: false,
          sixMonths: false,
          Yearly: true,
        }
        this.basicAmount='65,000';
        this.standardAmount = '150,000';
       }else {
        this.basicAmount='50,000';
        this.standardAmount = '90,000';
       }
       
      },
      onSuccessfulPayment(res) {
        this.$refs.closeModalRef.click();
        axios.post("/subscription/buy", {
          amount: this.amount/100,
          reference_number: res.reference,
          plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
          type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
          payment_type: 'paystack'

        }).then((response) => {
          this.toast.success(response.data.message);
          this.$refs.closeModalRef.click();
          setTimeout(() => {
            window.location.replace("/subscription-plan");
          }, 1000)
        })
        .catch ((err)=> {
          this.toast.error('error');
          console.log(err)
        })
      },

      onCancelledPayment() {
        console.log('cancel')
      },

      setAmount (amount) {
        if(this.token === undefined) {
          this.toast.error('signin to buy plan');
          this.router.push('/auth/login')
        }else {
          this.checkIfEligible(amount);
        }
        this.amount = parseInt(amount.replace(',', '')) * 100;
        this.payment = false;
      },

      flutterPayment() {
        useFlutterwave({
            amount: this.amount/100,
            callback(data) {
              let toast = useToast();
              let payment_data = {
                plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
                type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
                customer: data.customer,
                reference_number:data.flw_ref,
                meta:data,
                payment_type: 'Flutterwave'
              }
              this.$refs.closeModalRef.click();

              axios.post('/subscription/buy', payment_data).then((response) => {
                toast.success(response.data.message);
                setTimeout(() => {
                  window.location.replace("/subscription-plan");
                }, 1000)
              }).catch((error) => {
                this.toast.error(error.response.data.message);
              })

            },
            country: "NG",
            currency: this.user.getLoggedInUser?.code,
            customer: { email: this.user.getLoggedInUser.email, name: this.user.getLoggedInUser.fullname, phone_number: this.user.getLoggedInUser.company.phone },
            customizations: { description: "", logo: "", title: "BMAC" },
            meta: {
              user_id: this.user.getLoggedInUser.id,
              // token: 'FLW-' + this.reference
            },
            onclose() {
              console.log('close it here')
            },
            payment_options: "card",
            public_key: "FLWPUBK-9dbf5b16745932868693fead47ef7100-X",
            redirect_url: undefined,
            tx_ref: "tx_ref_" + Math.random().toString(36).substring(1, 100)
          })
      },

      checkIfEligible(amount) {
          this.payment = true;
        axios.post('/subscription/check-subscription', {
          plan_details_id: this.plans.filter(plan=> plan.amount == parseInt(amount.replace(',', '')))[0].id,
        }).then((res) => {
          this.$refs.payRef.click();
          console.log(res);
        }).catch((error) => {
          this.payment = false;
          console.log(error)
          this.toast.error(error.response.data.message);
        })
      },


      getActiveCategories() {
        axios.get("/subscription/category/active").then((response) => {
          this.categories = response.data.data
          this.planData.category = response?.data?.data[0]?.name //this is changed to
        })
      },

      getPlanTypes() {
        axios.get("/subscription/types").then((response) => {
          this.types = response.data.data
          this.planData.type = this.types[Object.keys(this.types)[0]]
        })
      },

      // buyPlan(type) {
      //   // if(this.token === undefined) {
      //   //   this.toast.error('signin to buy plan');
      //   //   this.router.push('/auth/login')
      //   // }
      //   // else {
      //     this.checkIfEligible(type);
      //   // }
      // },

      fetchPlan() {
        axios.get("/subscription/active").then((response) => {
          this.plans = response.data.data;
        })
        .catch((err)=> {
          console.log(err,'error')
        })
      },

      scrollTop(){
          window.scroll({
              top: 0,
              behavior: 'smooth',
          })
      }

    },

    
    mounted() {
      this.superUser()
      this.getActiveCategories()
      this.fetchPlan()
      this.getPlanTypes();
      this.scrollTop();
    },
    mountBefore() {

    }
    
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Inter");
.price-bg {
  background: #FAFFFC;
  padding: 90px 0 30px 0;
}
.hide_pay {
  z-index: 100 !important;
  background: pink !important;
}
.payment_home img {
  width: 180px;
  height: 100px;
  cursor: pointer;
}
.package_padding {
  width: 730px;
}
.package_padding h1 {
  font-weight: 800;
  font-size: 53px;
  font-family: 'Inter';
}
.main_content {
  margin-top: 100px;
}
.select_one {
  background: #DCFFEE;
  width: 495px;
  border-radius: 10px;
  margin: 50px 0 0 0;
}
.select_one .items {
  display: flex;
  width: 33.333%;
  justify-content: center;
  height: 60px;
  cursor: pointer;
  align-items: center;
}
.select_one .items  {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
}
.select_one .active {
  background: #FCFFFD !important;
  border-radius: 10px !important;
  color: #037B40 !important;
}

.radio_buttons .padd {
  width: 730px;
  margin-top: 18px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #18191F;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #047B40;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #047B40;
}
.main_content {
  font-family: 'Inter';
}

.prices_container {
  margin-top: 100px;
  flex-wrap: wrap;
}
.prices_container .price .content {
  background: #FFFFFF;
  border: 1px solid #CFD4D7;
  width: 348px;
  border-radius: 10px;
  min-height: 518px;
  max-height: auto;
}
.prices_container .price .content h2 {
  font-weight: 400;
  font-size: 24px;
  color: #5B5971;
  margin-bottom: 28px;
}
.prices_container .price .content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #047B40;
}
.prices_container .price .content h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5B5971;
  margin: 20px 0;
}
.prices_container .price .content button {
  padding: 1em 3em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5B5971;
  background: #FFFFFF;
  border: 1px solid #037B40;
  border-radius: 5px;
}
.prices_container .price .content h4 {
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0;
  color: #5B5971;
}
.prices_container .price .content h4 a {
  color: #037B40 !important;
}
.prices_container .price .content ul {
  list-style-image: url('../assets/images/down.png');
  text-align: start;
  list-style-position: outside;
}
.prices_container .price .content ul li {
  margin-bottom: 10px;
}
.prices_container .price .content .active {
  background: #037B40;
  color: white;
}

@media (max-width: 554px) {
  .select_one {
    width: 90%;
  }
}

@media (max-width: 360px) {
  .prices_container .price .content {
    width: 100%;
  }
  .prices_container .price {
    width: 90%;
  }
  
}

@media (max-width: 767px) {
  .package_padding h1 {
    font-size: 40px;
  }
  .select_one .items {
    font-size: 20px;
  }
}
</style>