<template>
    <div class="container-fluid bg-white">
        <ul class="nav nav-tabs thetoppills py-2" id="myTab" role="tablist">
            <li class="nav-item" role="presentation"
                v-if="permission.settings_module === 1 && loggedInUser.is_super == 1">
                <router-link active-class="pillactive" to="/company-info" class="nav-link makeGreen" :class="
                    $router.currentRoute.value.name === 'Edit Company Info'
                        ? 'pillactive'
                        : ''
                ">
                    <!-- <font-awesome-icon icon="user-plus" class="pillicons" /> -->
                    Company Info
                </router-link>
            </li>
            <li class="nav-item dropdown" role="presentation"
                v-if="permission.change_password === 1 || permission.change_email === 1">
                <a class="nav-link makeGreen dropdown-toggle" data-bs-toggle="dropdown" role="button"
                    aria-expanded="false" :class="
                        $router.currentRoute.value.name === 'Change Password' ||
                            $router.currentRoute.value.name === 'Change Email'
                            ? 'pillactive'
                            : ''
                    ">
                    <!-- <font-awesome-icon icon="user" class="pillicons" /> -->
                    My Account
                </a>
                <ul class="dropdown-menu" style="z-index: 20">
                    <li class="dropdown-item" @click="modalToggle('password')" style="cursor: pointer"
                        v-if="permission.change_password === 1">
                        Change Password
                    </li>
                    <li class="dropdown-item" @click="modalToggle('email')"
                        style="cursor: pointer" v-if="permission.change_email === 1 && !freemium">
                        Change Email
                    </li>
                    
                    <li class="dropdown-item" @click="freemiumPrompt()"
                        style="cursor: pointer" v-if="permission.change_email === 1 && freemium">
                        Change Email
                    </li>
                    
                </ul>
            </li>


            <router-link to="#" class="makeGreen thetoppills" style="text-decoration: none;" :active-class="
                $router.currentRoute.value.name === 'Inventory Category' ||
                    $router.currentRoute.value.name === 'Inventory Subcategory'
                    ? 'pillactive'
                    : ''
            ">

                <li class="nav-item dropdown" role="presentation"
                    v-if="(permission.view_inventory_category === 1) || (permission.view_inventory_subcategory === 1)">
                    <a class="nav-link makeGreen dropdown-toggle" data-bs-toggle="dropdown" role="button"
                        aria-expanded="false">
                        Inventory Category
                    </a>
                    <ul class="dropdown-menu" style="z-index: 20">
                        <li class="dropdown-item" v-if="permission.view_inventory_category === 1">
                            <router-link active-class="pillactive" to="/inventory-category" class="nav-link makeGreen"
                                style="color:black">
                                Category
                            </router-link>
                        </li>
                        <li class="dropdown-item" style="cursor: pointer" v-if="permission.change_email === 1">
                            <router-link active-class="pillactive" to="/inventory-subcategory"
                                class="nav-link makeGreen" style="color:black">
                                Subcategory
                            </router-link>
                        </li>
                    </ul>
                </li>
            </router-link>
            <li class="nav-item" role="presentation" v-if="permission.view_cutomers === 1">
                <router-link active-class="pillactive" to="/all-customers" class="nav-link makeGreen" :class="
                    $router.currentRoute.value.name === 'Create Customer' ||
                        $router.currentRoute.value.name === 'Bulk Customer Upload' ||
                        $router.currentRoute.value.name === 'Edit Customer'
                        ? 'pillactive'
                        : ''
                ">
                    <!-- <font-awesome-icon icon="users" class="pillicons" /> -->
                    Customers
                </router-link>
            </li>
            <li class="nav-item" role="presentation" v-if="permission.view_suppliers === 1">
                <router-link active-class="pillactive" to="/all-suppliers" class="nav-link makeGreen" :class="
                    $router.currentRoute.value.name === 'Create Supplier' ||
                        $router.currentRoute.value.name === 'Bulk Supplier Upload' ||
                        $router.currentRoute.value.name === 'Edit Supplier'
                        ? 'pillactive'
                        : ''
                ">
                    <!-- <font-awesome-icon prefix="far" icon="shopping-bag" class="pillicons" /> -->
                    Suppliers
                </router-link>
            </li>

            <!-- <li class="nav-item" role="presentation">
            <router-link active-class="pillactive" to="/all-currencies" class="nav-link makeGreen" :class="
            $router.currentRoute.value.name === 'Create Currency' ||
            $router.currentRoute.value.name === 'Bulk Supplier Upload'
              ? 'pillactive'
              : ''
          ">
                Currencies
            </router-link>
        </li> -->

            <li class="nav-item" role="presentation"
                v-if="(permission.view_payment_type === 1 ) || (permission.change_payment_type === 1)">
                <router-link v-if="!freemium" active-class="pillactive" to="/all-payments" class="nav-link makeGreen">
                    <!-- <font-awesome-icon icon="coins" class="pillicons" /> -->
                    Payment type
                </router-link>
                <span disabled v-if="freemium" active-class="pillactive" @click="freemiumPrompt()" class="nav-link makeGreen">
                    <!-- <font-awesome-icon icon="coins" class="pillicons" /> -->
                    Payment type
                </span>
            </li>
            <li class="nav-item" role="presentation" v-if="permission.view_taxes === 1">
                <router-link v-if="!freemium" active-class="pillactive" to="/tax" class="nav-link makeGreen">
                    <!-- <font-awesome-icon icon="receipt" class="pillicons" /> -->
                    Tax
                </router-link>
                <span disabled v-if="freemium" active-class="pillactive" @click="freemiumPrompt()" class="nav-link makeGreen">
                    <!-- <font-awesome-icon icon="coins" class="pillicons" /> -->
                    Tax
                </span>
            </li>
            <li class="nav-item" role="presentation" v-if="permission.change_notification === 1">
                <router-link v-if="!freemium" active-class="pillactive" to="/notifications" class="nav-link makeGreen">
                    <!-- <font-awesome-icon icon="volume-down" class="pillicons" /> -->
                    Notification
                </router-link>
                <span disabled v-if="freemium" active-class="pillactive" @click="freemiumPrompt()" class="nav-link makeGreen">
                    <!-- <font-awesome-icon icon="coins" class="pillicons" /> -->
                    Notification
                </span>
            </li>
        </ul>
    </div>

    <!-- change password modal -->
    <div class="modal fade" :class="{ show: password_active, 'd-block': password_active }" id="passwordmodal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold">Change Password</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalToggle('password')"></button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="Array.isArray(validationPassErrors)">
                        <ul class="col-md-12" v-for="errors in validationPassErrors" :key="errors.id">
                            <li class="text-danger" style="list-style: none"><small>{{ errors }}.</small></li>
                        </ul>
                    </div>

                    <div class="row" v-else>
                        <ul class="col-md-12">
                            <li class="text-danger" style="list-style: none"><small>{{ validationPassErrors }}.</small>
                            </li>
                        </ul>
                    </div>
                    <form @submit.prevent="changePassword">
                        <div v-if="showCurrentPassword" class="mb-4">
                            <div class="">
                                <label class="form-label labelform" style="font-weight: 500; color: gray">Current
                                    Password</label>
                                <input v-model="password" type="password" class="form-control py-2"
                                    placeholder="******************" />
                                <font-awesome-icon style="cursor: pointer" @click="toggleCurrentPasswordField"
                                    v-if="showCurrentPassword" class="field-icon" icon="eye-slash" />
                            </div>
                        </div>
                        <div v-else class="mb-4">
                            <div class="">
                                <label class="form-label labelform" style="font-weight: 500; color: gray">Current
                                    Password</label>
                                <input v-model="password" type="text" class="form-control py-2"
                                    placeholder="******************" />
                                <font-awesome-icon style="cursor: pointer" @click="toggleCurrentPasswordField"
                                    v-if="!showCurrentPassword" class="field-icon" icon="eye" />
                            </div>
                        </div>
                        <div v-if="showPassword" class="mb-4">
                            <div class="">
                                <label class="form-label labelform" style="font-weight: 500; color: gray">New
                                    Password</label>
                                <input v-model="new_password" type="password" class="form-control py-2"
                                    placeholder="******************" />
                                <font-awesome-icon style="cursor: pointer" @click="togglePasswordField"
                                    v-if="showPassword" class="field-icon" icon="eye-slash" />
                            </div>
                        </div>
                        <div v-else class="mb-4">
                            <div class="">
                                <label class="form-label labelform" style="font-weight: 500; color: gray">New
                                    Password</label>
                                <input v-model="new_password" type="text" class="form-control py-2"
                                    placeholder="******************" />
                                <font-awesome-icon style="cursor: pointer" @click="togglePasswordField"
                                    v-if="!showPassword" class="field-icon" icon="eye" />
                            </div>
                        </div>
                        <div v-if="showConfirmPassword" class="mb-4">
                            <div class="">
                                <label class="form-label labelform" style="font-weight: 500; color: gray">Confirm New
                                    Password</label>
                                <input v-model="password_confirmation" type="password" class="form-control py-2"
                                    placeholder="******************" />
                                <font-awesome-icon style="cursor: pointer" @click="togglePasswordConfirmField"
                                    v-if="showConfirmPassword" class="field-icon" icon="eye-slash" />
                            </div>
                        </div>
                        <div v-else class="mb-4">
                            <div class="">
                                <label class="form-label labelform" style="font-weight: 500; color: gray">Confirm New
                                    Password</label>
                                <input v-model="password_confirmation" type="text" class="form-control py-2"
                                    placeholder="******************" />
                                <font-awesome-icon style="cursor: pointer" @click="togglePasswordConfirmField"
                                    v-if="!showConfirmPassword" class="field-icon" icon="eye" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button class="btnexportbtn btn text-white me-3 px-3 py-2" style="background: #cbd1cc"
                                data-bs-dismiss="modal" type="button" @click="modalToggle('password')">
                                Cancel
                            </button>
                            <button type="submit" :disabled="passloading === true"
                                class="btn px-4 py-2 text-white allbuttonsclr" data-bs-dismiss="modal">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div v-if="password_active" class="modal-backdrop fade show"></div>
    <div v-if="email_active" class="modal-backdrop fade show"></div>

    <!-- change email modal -->
    <div class="modal fade" :class="{ show: email_active, 'd-block': email_active }" id="emailmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold">Change Email</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="modalToggle('email')" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="Array.isArray(validationErrors)">
                        <ul class="col-md-12" v-for="errors in validationErrors" :key="errors.id">
                            <li class="text-danger" style="list-style: none"><small>{{ errors }}.</small></li>
                        </ul>
                    </div>
                    <div class="row" v-else>
                        <ul class="col-md-12">
                            <li class="text-danger" style="list-style: none"><small>{{ validationErrors }}.</small></li>
                        </ul>
                    </div>
                    <form class="mt-2" @submit.prevent="changeemail">
                        <div class="row">
                            <div class="form-group">
                                <label for="inputEmail4" style="font-weight: 500; color: gray">Current Email</label>
                                <input type="text" class="form-control" placeholder="Enter current email address"
                                    v-model="email" />
                                <p class="form-text text-danger" v-if="validationErrors?.email">
                                    {{ validationErrors.email[0] }}
                                </p>
                            </div>
                            <div class="form-group mt-2">
                                <label style="font-weight: 500; color: gray">New Email</label>
                                <input type="text" class="form-control" placeholder="Enter new email address"
                                    v-model="new_email" />
                                <p class="form-text text-danger" v-if="validationErrors?.new_email">
                                    {{ validationErrors.new_email[0] }}
                                </p>
                            </div>
                            <div class="form-group mt-2">
                                <label style="font-weight: 500; color: gray">Confirm New Email</label>
                                <input type="text" class="form-control" placeholder="Confirm new email address"
                                    v-model="email_confirmation" />
                                <p class="form-text text-danger" v-if="validationErrors?.email_confirmation">
                                    {{ validationErrors.email_confirmation[0] }}
                                </p>
                            </div>
                        </div>
                        <div class="d-flex mt-3 justify-content-end">
                            <button class="btnexportbtn btn text-white me-3 px-3 py-2" style="background: #cbd1cc"
                                data-bs-dismiss="modal" @click="modalToggle('email')" type="button">
                                Cancel
                            </button>
                            <button type="submit" :disabled="loading === true"
                                class="btn px-4 py-2 text-white allbuttonsclr" data-bs-dismiss="modal">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import {
    ref
} from "@vue/reactivity";
import axios from "axios";
import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import {
    onMounted
} from "@vue/runtime-core";



export default {
    setup() {
        // password
        const showCurrentPassword = ref(true);
        const showPassword = ref(true);
        const showConfirmPassword = ref(true);
        const password = ref("");
        const new_password = ref("");
        const password_confirmation = ref("");
        const validationPassErrors = ref([]);
        const passloading = ref(false);

        // email
        const email = ref("");
        const new_email = ref("");
        const email_confirmation = ref("");
        const validationErrors = ref([]);
        const router = useRouter();
        const toast = useToast();
        const loading = ref(false);
        const store__ = useStore();
        const user = ref('')
        const password_active = ref(false)
        const email_active = ref(false)
        const freemium = ref(true);

        const modalToggle = (type) => {
            let body='';
            if(type == 'password'){
                body = document.getElementById("passwordmodal")
                password_active.value = !password_active.value
                password_active.value ? body.classList.add("modal-open") : body.classList.remove("modal-open")
            }else{
                body = document.getElementById("emailmodal")
                email_active.value = !email_active.value
                email_active.value ? body.classList.add("modal-open") : body.classList.remove("modal-open")
            }

        }

        // email logic
        const clearFields = () => {
            email.value = "";
            new_email.value = "";
            email_confirmation.value = "";
            validationErrors.value = [];
        };

        const changeemail = async () => {
            loading.value = true;
            await axios
                .post("/user/change-email-web", {
                    email: email.value,
                    new_email: new_email.value,
                    email_confirmation: email_confirmation.value,
                })
                .then((response) => {
                    loading.value = false;
                    clearFields();
                    modalToggle('email')
                    localStorage.removeItem("token");
                    localStorage.clear()
                    router.push("/auth/login");
                    toast.success(response?.data?.message);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        toast.warning("Your inputs are not valid, kindly recheck");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status === 500) {
                        toast.error("Please recheck your login details");
                    }
                    loading.value = false;
                });
        };

        const getCurrentUser = () => {
            axios.get("/user").then((response) => {
                user.value = response.data.data.data;
                store__.dispatch("getCurrentUser", user.value);
            })
        }
        const loggedInUser = computed(() => store.state.getLoggedInUser);

        // password logic
        const toggleCurrentPasswordField = () => {
            showCurrentPassword.value = !showCurrentPassword.value;
        };

        const togglePasswordField = () => {
            showPassword.value = !showPassword.value;
        };

        const togglePasswordConfirmField = () => {
            showConfirmPassword.value = !showConfirmPassword.value;
        };

        const clearPassFields = () => {
            password.value = "";
            new_password.value = "";
            password_confirmation.value = "";
            validationPassErrors.value = [];
        };
        const changePassword = async () => {
            passloading.value = true;
            await axios
                .post("/user/change-password", {
                    password: password.value,
                    new_password: new_password.value,
                    password_confirmation: password_confirmation.value,
                })
                .then((response) => {
                    modalToggle('password')

                    passloading.value = false;
                    localStorage.removeItem("token");
                    localStorage.clear()
                    router.push("/auth/login");
                    clearPassFields();
                    toast.success(response?.data?.message);
                })
                .catch((error) => {

                    if (error.response.status === 422) {
                        toast.warning("Your inputs are not valid, kindly recheck");
                        validationPassErrors.value = error.response.data.message;
                    }
                    if (error.response.status === 500) {
                        toast.error("Please recheck your login details");
                    }
                    passloading.value = false;
                });
        };

        const activeSubscription = () => {
            // loading.value = true
            axios.get("/subscription/user")
                .then((response) => {
                // loading.value = false
                // subscriptions.value = response.data.data
                // // console.log(response.data.data, 'hh')
                // allUsers()
                // superUser()
            if(response.data.data.filter(data => data.status == 1 || data.status == 0).length == 0) {
                freemium.value = true;
            }else {
                freemium.value = false;
            }
            }).catch(() => {
            // loading.value = false
            })
        };

        const freemiumPrompt = ()=> {
            toast.error('subscribe or upgrade to use this functionality')
        }

        onMounted(() => {
            getCurrentUser()
            activeSubscription()
            // state.modal_demo = new Modal('#modal_demo', {})

        })
        const store = useStore();
        const permission = computed(() => store.state.getCurrentUserPermission);

        return {
            modalToggle,
            showCurrentPassword,
            toggleCurrentPasswordField,
            showPassword,
            togglePasswordField,
            showConfirmPassword,
            togglePasswordConfirmField,
            changeemail,
            email,
            new_email,
            email_confirmation,
            router,
            toast,
            loading,
            validationErrors,
            changePassword,
            password,
            new_password,
            password_confirmation,
            passloading,
            validationPassErrors,
            permission,
            getCurrentUser,
            loggedInUser,
            user,
            store__,
            email_active,
            password_active,
            freemiumPrompt,
            freemium
        };
    },
    
    
};
</script>

<style scoped>
.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -27px;
    position: relative;
    z-index: 2;
    margin-right: 15px;
}

.btn-close {
    font-size: 9px;
}

.modal-content {
    border-radius: 15px;
}

@media (min-width: 576px) {
    .modal-sm {
        max-width: 342px;
    }
}
</style>
