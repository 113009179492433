<template>
    <Navbar/>
    <div class="privacy_cont" ref="top">
        <div class="first_sec d-flex justify-content-center align-items-center">
            <h1>Privacy Policy</h1>
        </div>
        <div class="seond_sec py-4 px-5">
            <!-- <h2>consent</h2> -->
            <span v-html="policyData.ims_privacy_policy"></span>
            <!-- <span>{{policyData.ims_privacy_policy}}</span> -->
        </div>
    </div>
    
    <div v-if="loading === true" class="text-center py-5">
    <div
        class="spinner-border text-success"
        role="status"
        style="width: 5rem; height: 5rem"
    >
        <span class="visually-hidden">Loading...</span>
    </div>
    </div>
    <div style="background: #F8FFFB;">
      <div class="homepadding">
        <Footer />
      </div>
    </div>
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";

export default{
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            policyData: {},
            loading: true
        }
    },
    mounted() {
        this.scrollTop();
        this.pageData();
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },
        pageData() {
            axios.get("/admin/fetchProjectSetup").then((response) => {
                this.policyData = response.data.data;
                this.loading = false;
            })
        }

    }

}
</script>

<style scoped>
    .first_sec {
        background: #047B40;
        height: 362px;  
    }
    .privacy_cont {
        margin-top: 70px;
    }
    .first_sec h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 53px;
        line-height: 80px;
        text-align: center;
        font-feature-settings: 'salt' on, 'liga' off;

        color: #FFFFFF;
    }
    .seond_sec p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        color: #696984;
    }
    .seond_sec h2 {
        text-transform: capitalize;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #696984;
    }
</style>
