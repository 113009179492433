<template>
  <div class="pagebg">
    <div class="container d-flex justify-content-center authtop">
      <div class="row justify-content-center align-self-center">
        <div class="text-center"><h1>Change Password</h1></div>
        <div class="w-100 text-center mb-4">
          Please change your password here.
        </div>

        <div class="authbg"></div>

        <div class="card w-50" style="z-index: 26">
          <div class="text-center h5 mt-3">Change Password</div>

          <div>
            <div class="" v-for="errors in validationErrors" :key="errors.id">
              <div class="text-danger text-center">
                {{ errors }}
              </div>
            </div>
          </div>
          <form class="px-3 pt-2 pb-4" @submit.prevent="changePassword">
            <div v-if="showPassword" class="mb-4">
              <label class="form-label labelform">Password</label>
              <input
                type="password"
                class="form-control py-2 inputborder"
                placeholder="New Password"
                v-model="password"
              />
              <font-awesome-icon
                style="cursor: pointer"
                @click="togglePasswordField"
                v-if="showPassword"
                class="field-icon"
                icon="eye-slash"
              />
            </div>
            <div v-else class="mb-4">
              <label class="form-label labelform">Password</label>
              <input
                type="text"
                class="form-control py-2 inputborder"
                placeholder="New Password"
                v-model="password"
              />
              <font-awesome-icon
                style="cursor: pointer"
                @click="togglePasswordField"
                v-if="!showPassword"
                class="field-icon"
                icon="eye"
              />
            </div>
            <div v-if="showConfirmPassword" class="mb-4">
              <label class="form-label labelform">Confirm Password</label>
              <input
                type="password"
                class="form-control py-2 inputborder"
                placeholder="Confirm New Password"
                v-model="password_confirmation"
              />
              <font-awesome-icon
                style="cursor: pointer"
                @click="togglePasswordConfirmField"
                v-if="showConfirmPassword"
                class="field-icon"
                icon="eye-slash"
              />
            </div>
            <div v-else class="mb-4">
              <label class="form-label labelform">Confirm Password</label>
              <input
                type="text"
                class="form-control py-2 inputborder"
                placeholder="Confirm New Password"
                v-model="password_confirmation"
              />
              <font-awesome-icon
                style="cursor: pointer"
                @click="togglePasswordConfirmField"
                v-if="!showConfirmPassword"
                class="field-icon"
                icon="eye"
              />
            </div>

            <div class="mt-5 text-center">
              <button
                v-if="loading === false"
                type="submit"
                class="btn buttongreen btn-lg btn-block rounded-3 w-100"
              >
                Change Password
              </button>
              <button
                v-if="loading === true"
                disabled
                type="submit"
                class="btn buttongreen btn-lg btn-block rounded-3 w-100"
              >
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Processing</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="text-secondary d-flex justify-content-between bottom-content-container pb-3 authtop">
      <div>© 2021, Made with <i class="fa fa-heart text-danger"></i> by <span class="text-success">Ikooba Technologies</span> for a better web</div>
      <div class="d-flex">
        <div class="bottom-footer-margin">Ikooba Tim</div>
        <div class="bottom-footer-margin">Creative</div>
        <div class="bottom-footer-margin">Blog</div>
        <div class="bottom-footer-margin">Licenses</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const password = ref("");
    const password_confirmation = ref("");
    const router = useRouter();
    const validationErrors = ref("");
    const toast = useToast();
    const loading = ref(false);
    const showPassword = ref(true);
    const showConfirmPassword = ref(true);

    const togglePasswordField = () => {
      showPassword.value = !showPassword.value;
    };

    const togglePasswordConfirmField = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const route = useRoute();

    const changePassword = async () => {
      loading.value = true;
      await axios
        .post("/reset-password", {
          password: password.value,
          resetToken: route.params.token,
          password_confirmation: password_confirmation.value,
        })
        .then(() => {
          loading.value = false;
          router.push("/auth/login");
          toast.success("Password changed successfully");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toast.error("There are errors in your inputs");
            validationErrors.value = error.response.data.message;
          }

          if (error.response.status === 400) {
            toast.error(error?.response?.data?.message);
          }
          if (error.response.status === 500) {
            toast.error("Please recheck your login details");
          }
          loading.value = false;
        });
    };
    return {
      changePassword,
      password_confirmation,
      password,
      router,
      toast,
      loading,
      showPassword,
      showConfirmPassword,
      togglePasswordField,
      togglePasswordConfirmField,
      validationErrors,
    };
  },
};
</script>
v
<style scoped>
.pagebg {
  background: #f2fff8;
  position: relative;
  height: 100%;
}

.authbg {
  background-image: url("../assets/images/Rectangle93.png") !important;
  background-repeat: no-repeat;
  position: absolute;
  /* margin-top: 5rem; */
  bottom: 0;
  /* background-position: center bottom; */
  height: 23rem;
  max-height: 20rem;
  width: 100% !important;
  z-index: 1;
  /* height: 100vh !important */
}

.authtop {
  padding-top: 84px;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -27px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

.card {
  border: none !important;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
}

.fontinput {
  font-size: 23px;
}

.footergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.bottom-content-container {
  padding-left: 113px;
  padding-right: 111px;
}

.bottom-footer-margin {
  margin-right: 14px;
  /* margin-left: 5px; */
}

.password-reset-success-icon {
  background: #9fffcc;
  border-radius: 999px;
}

.thefooter {
  margin-top: 84px;
}

.thealtfooter {
  margin-top: 100px;
}

.buttongreen {
  background: green !important;
  color: white !important;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -27px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

.second-field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -36px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

@media (max-width: 1000px) {
  .authbg {
    display: none;
  }
}

@media (max-width: 767px) {
  .bottom-content-container {
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
}
</style>
