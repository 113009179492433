<template>

  <div class="mt-3 gridtop">
    <div class="d-flex justify-content-between">
      <div class="mt-1 fw-bold" style="font-size:20px">
        <span style="cursor:pointer" @click="$router.go(-1)"><font-awesome-icon class="arrowradius me-3" prefix="far"
            icon="angle-left" /></span>
        {{ sales?.batch_code }}
      </div>

      <PrintButtonHeader :title="'Invoice-' + sales?.batch_code" :urlA4="'/sales-receipt/'" v-if="!loading"
        :url58mm="'/print58mm-sales-receipt/'" :url80mm="'/print80mm-sales-receipt/'"
        :currentUrl="'Print 80mm Sales Receipt'" :data="sales" :type="'Sales Receipt'" />


    </div>
    <div class="bg-white d-flex justify-content-center rounded-lg mt-3 mb-3 px-4 py-3 cardpadding"
      style="overflow-x: auto;">
      <div v-if="loading === true" class="text-center" style="margin:200px">
        <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem"></div>
      </div>
      <div class="printborder" v-if="loading == false" style="width:375px">
        <div class="p-3 sales_invoice" id="printMe">

          <center>
            <div>
              <p>
                <strong style="font-size: 25px;">{{ company?.company_name }}</strong> <br>
                <span style="font-size: 13px;">{{ sales?.store?.address }}</span> <br>
                <span style="font-size: 13px;">{{ sales?.store?.phone_no }}, {{ company?.phone }}</span> <br>
                <span style="font-size: 13px;">{{ company?.company_email }}</span>
              </p>
            </div>
          </center>
          <div>Date: {{ dateTime(sales?.date) }}, {{ timeConvert(sales.time) }}</div>
          <div>Store: {{ sales?.store?.store_name }}</div>
          <div>Customer: {{ sales?.customer?.customer_name }}</div>
          <div>Sales No: {{ sales?.batch_code }}</div>
          <div>Sold By: {{ sales?.user?.fullname }}</div>

          <div class="printdottedborder mt-3"></div>
          <div class="printdottedborder mt-1 mb-3"></div>
          <hr class="solid_line" />

          <div v-for="item in sales_array" :key="item.id" class="mt-2">
            <div>{{ item.batch_code }}</div>
            <div>{{ item?.product_name }}</div>
            <div v-if="item.variant">
              <div class="d-flex justify-content-between">
                <!-- <div>1 carton * 20,000</div> -->
                <div>{{ `${item.quantity}` + ' ' + `${item?.variant?.variant_name + '(s)'}` + ' x ' +
                  `${$filters.formatMoney(item?.variant?.variant_selling_price, company?.currency?.symbol)}`
                }}</div>
                <div>{{ $filters.formatMoney(item?.total, company?.currency?.symbol) }}</div>
              </div>

            </div>

            <div v-else>
              <div class="d-flex justify-content-between">
                <div>{{ `${item?.quantity + ' ' + item?.variant_name + ' x ' + parseFloat(item?.selling_price
                ).toFixed(2)}`}}</div>
                <div>{{ $filters.formatMoney(item?.quantity * item?.selling_price, company?.currency?.symbol) }}
                </div>
              </div>
            </div>
            <hr class="solid_line" />
          </div>
          <div class="printdottedborder mt-3"></div>
          <div class="printdottedborder mt-1 mb-3"></div>

          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8">

              <div class="mt-3 d-flex justify-content-between font-weight-bold">
                <div>Sub-total</div>
                <span>
                  {{ $filters.formatMoney(subTotal, company?.currency?.symbol) }}
                </span>
              </div>

              <div class="d-flex justify-content-between">
                <div>Tax</div>
                <div>{{ $filters.formatMoney(totalTax, company?.currency?.symbol) }}</div>
              </div>

              <div class="d-flex justify-content-between">
                <div>Discount</div>
                <div>{{ $filters.formatMoney(totalDiscount, company?.currency?.symbol) }}</div>

              </div>
              <!-- <div class="d-flex justify-content-between">
                <div>Total Quantity</div>
                <div>{{ totalQty }}</div>
              </div> -->
              <div class="d-flex justify-content-between" style="font-weight:500">
                <div>Total Amount</div>
                <span>
                  {{ $filters.formatMoney(sales?.total_amount, company?.currency?.symbol) }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <div>Amount Paid</div>
            <div>{{ $filters.formatMoney(sales?.amount_paid, company?.currency?.symbol) }}</div>
          </div>
          
          <div class="d-flex justify-content-between">
            <div>Payment</div>
            <div style="text-transform:capitalize">{{sales?.payment_type  }}</div>
          </div>

          <div class="d-flex justify-content-between"
            v-if="sales?.payment_type == 'cash' || sales?.payment_type == 'card'">
            <div>Change</div>
            <div class="">
              {{ $filters.formatMoney(sales?.change, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Balance</div>
            <div class="" v-if="sales?.balance !== null">
              {{ $filters.formatMoney(sales?.balance, company?.currency?.symbol) }}
            </div>
            <div class="" v-else>
              {{ $filters.formatMoney(0, company?.currency?.symbol) }}
            </div>
          </div>

          <div class="mt-4">Powered by BMAC POS - <span style="font-size: 13px">www.bmacims.com</span></div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.sales_invoice {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.7rem;

  color: #35413B;

}

.solid_line {
  border: 1px solid #E7E4E4;
}
</style>
<script>
import {
  useToast
} from "vue-toastification";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import {
  reusableMixin
} from "../../../lib/reusableMixin";
import { useStore } from "vuex";
import axios from 'axios';


export default {

  mixins: [reusableMixin],
  data() {
    return {
      toast: useToast(),
      sales: null,
      grand_total: 0,
      tax_amount: 0,
      sales_items: [],
      sales_array: [],
      store: useStore(),
      loading: true,
      company: null

    }
  },
  computed: {
    totalQty() {
      let qty = this.sales_items.reduce((a, b) => {
        return a + b.unit_quantity
      }, 0);
      return qty
    },

    subTotal() {
      let total = this.sales_array.reduce((a, b) => {
        return a + b.total
      }, 0);
      return total
    },

    totalTax() {
      let total = this.sales_array.reduce((a, b) => {
        return a + b.tax
      }, 0);
      return total
    },

    totalDiscount() {
      let total = this.sales_array.reduce((a, b) => {
        return a + b.discount
      }, 0);
      return total
    },
  },
  methods: {
    getSale() {
      this.loading = true
      axios.get(`/inventory/sales/${this.$route.params.id}`).then((response) => {
        this.loading = false
        this.sales = response.data.data
        this.sales_items = response.data.data.sales_item
        this.sales_items.forEach((s) => {
          // let tax = s?.product?.tax.reduce(function (sum, tax) {
          //     return parseFloat(sum) + (s?.selling_price * parseFloat(tax?.tax_percentage) / 100)
          // }, 0)
          this.sales_array.push({
            "product_name": s?.product?.product_name,
            "selling_price": s?.selling_price,
            "variant_name": s?.variant?.variant_name ?? s?.product?.uom?.name,
            "variant": s?.variant,
            "quantity": s?.quantity,
            "tax": parseFloat(s.tax_amount),
            "discount": s?.discount,
            "total": s?.amount_paid//(s?.selling_price * s?.quantity)
          })
        })

      }).catch(() => {
        this.loading = true
        this.toast.error("Oops, Something went wrong")
      })
    },
    createPDF(batch_code) {
      var pdf = new jsPDF({
        unit: 'pt',
        orientation: 'l',
        lineHeight: 1.2
      });

      var element = document.getElementById('pdf');
      var width = element.style.width;
      var height = element.style.height;
      html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 0, 0, width, height);
        pdf.save(batch_code + '.pdf');
      });
    },
  },

  created() {
    this.getSale(),
    this.getCompany()
  }

}
</script>


<style>

</style>