<template>
  <div class="pagebg">
    <div class="container d-flex justify-content-center authtop">
      <div class="row justify-content-center align-self-center">
        <div class="text-center"><h1>Log in</h1></div>
        <div class="w-100 text-center mb-4" style="z-index: 20">
          Don't have an Account?
          <router-link
            to="/auth/register"
            class="text-success fw-bold"
            style="text-decoration: none"
          >
            Register here
          </router-link>
        </div>
        <div class="authbg"></div>
        <div class="card w-75" style="z-index: 26">
          <div class="text-center h5 mt-3">Sign In</div>
          <div>
            <div class="" v-for="errors in validationErrors" :key="errors.id">
              <div class="text-danger text-center">
                {{ errors }}
              </div>
            </div>
          </div>
          <form
            @submit.prevent="loginUser"
            class="px-3 pt-2 pb-4 h-100"
            style="position: relative"
          >
            <div class="mb-4">
              <label class="form-label labelform">Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control py-2"
                placeholder="Email Address"
              />
            </div>
            <div class="mb-4" v-if="showPassword">
              <label class="form-label labelform">Password</label>
              <input
                v-model="password"
                type="password"
                class="form-control py-2"
                id="exampleInputPassword1"
                placeholder="Password"
              />
              <!-- <font-awesome-icon icon="" /> -->
              <font-awesome-icon
                style="cursor: pointer"
                @click="toggleShowHide"
                v-if="showPassword"
                class="field-icon"
                icon="eye-slash"
              />
            </div>
            <div v-else class="mb-4">
              <label class="form-label labelform">Password</label>
              <input
                v-model="password"
                type="text"
                class="form-control py-2"
                id="exampleInputPassword1"
                placeholder="Password"
              />
              <font-awesome-icon
                style="cursor: pointer"
                @click="toggleShowHide"
                v-if="!showPassword"
                class="field-icon"
                icon="eye"
              />
            </div>
            <div class="d-flex justify-content-between">
              <div class="form-check form-switch">
                <!-- <input class="form-check-input me-2" type="checkbox" />
              <label class="form-check-label">Remember me</label> -->
              </div>
              <div>
                <router-link
                  to="/auth/reset-password"
                  class="text-secondary underline"
                  >Forgot Password?</router-link
                >
              </div>
            </div>
            <div class="mt-5 text-center">
              <button
                v-if="loading === false"
                type="submit"
                class="btn buttongreen btn-lg btn-block w-100 rounded-3"
              >
                Sign in
              </button>
              <button
                v-if="loading === true"
                disabled
                type="submit"
                class="btn buttongreen btn-lg btn-block w-100 rounded-3"
              >
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Processing</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="text-secondary d-flex justify-content-between bottom-content-container pb-3 thefooter">
      <div>© 2021, Made with <i class="fa fa-heart text-danger"></i> by <span class="text-success">Ikooba Technologies</span> for a better web</div>
      <div class="d-flex">
        <div class="bottom-footer-margin">Ikooba Tim</div>
        <div class="bottom-footer-margin">Creative</div>
        <div class="bottom-footer-margin">Blog</div>
        <div class="bottom-footer-margin">Licenses</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ref } from "@vue/reactivity";


export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const validationErrors = ref("");
    const router = useRouter();
    const toast = useToast();
    const loading = ref(false);
    const showPassword = ref(true);


    const toggleShowHide = () => {
      showPassword.value = !showPassword.value;
    };

    const loginUser = async () => {
      if(email.value.length === 0 || password.value.length === 0) {
        return
      }else {
          loading.value = true;
          await axios
            .post("/login-web", {
              email: email.value,
              password: password.value,
            })
            .then((response) => {
              console.log('++++++++', response);
              loading.value = false;
              localStorage.setItem(
                "token",
                response.data.data.original.accessToken
              );


              axios.defaults.headers[
                "Authorization"
              ] = `Bearer ${response.data.data.original.accessToken}`;
              router.push("/");
              toast.success("Login successful");
            })
            .catch((error) => {
              loading.value = false;
              if (error.response.status === 400) {
                toast.error("There are errors in your input");
                validationErrors.value = error.response.data.message;
              }
              if (error.response.status === 401) {
                toast.error(error?.response?.data?.message)
              }
              if (error.response.status === 402) {
                router.push('send-email-verifcation')
                toast.error(error?.response?.data?.message)
              }
              if (error.response.status === 500) {
                toast.error("Please recheck your login details");
              }
            });
      
      }

    };

    return {
      loginUser,
      email,
      password,
      router,
      toast,
      loading,
      toggleShowHide,
      showPassword,
      validationErrors,
    };
  },

};
</script>

<style scoped>
.pagebg {
  background: #f2fff8;
  position: relative;
  height: 100vh;
  overflow-y: hidden;
}

.authbg {
  background-image: url("../assets/images/Rectangle93.png") !important;
  background-repeat: no-repeat;
  position: absolute;
  /* margin-top: 5rem; */
  bottom: 0;
  /* background-position: center bottom; */
  height: 23rem;
  max-height: 20rem;
  width: 100% !important;
  z-index: 1;
  /* height: 100vh !important */
}

.authtop {
  padding-top: 50px;
}

.card {
  border: none !important;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
}

.fontinput {
  font-size: 23px;
}

.footergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.bottom-content-container {
  padding-left: 113px;
  padding-right: 111px;
}

.bottom-footer-margin {
  margin-right: 14px;
  /* margin-left: 5px; */
}

.password-reset-success-icon {
  background: #9fffcc;
  border-radius: 999px;
}

.thefooter {
  margin-top: 84px;
}

.thealtfooter {
  margin-top: 100px;
}

.buttongreen {
  background: green !important;
  color: white !important;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -27px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

@media (max-width: 1000px) {
  .authbg {
    display: none;
  }
}

/* @media screen and (max-width: 280px) {
  .authbg {
    margin-top: 20rem;
    bottom: 0;
    height: 36rem !important;
    max-height: 36rem;
  }

  .authtop {
  padding-top: 20px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 374px) {
  .authbg {
    margin-top: 38rem;
    bottom: -10;
    height: 30rem !important;
    max-height: 30rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: 389px) {
  .authbg {
     margin-top: 38rem;
    bottom: -10;
    height: 40rem !important;
    max-height: 27rem;
  }
}

@media only screen and (min-width: 390px) and (max-width: 550px) {
  .authbg {
    margin-top: 30rem;
    bottom: -10;
    height: 39rem !important;
    max-height: 39rem;
  }
} */

@media only screen and (min-width: 1024px) and (max-width: 1030px) {
  .authbg {
    margin-top: 10rem;
    bottom: -10;
    height: 28rem !important;
    max-height: 28rem;
  }
}

@media (min-width: 1280px) {
  .authbg {
    margin-top: 10rem;
    bottom: -10;
    height: 28rem !important;
    max-height: 30rem;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1023px) { 
  .authbg {
    margin-top: 23rem;
    bottom: -10;
    height: 30rem !important;
    max-height: 30rem;
  }

  .authtop {
    padding-top: 250px;
  }
} */

@media (max-width: 767px) {
  .bottom-content-container {
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
}
</style>
