<template>
  <div class="authbg">
    <div class="container d-flex justify-content-center authtop">
      <div class="row justify-content-center align-self-center">
        <div class="w-100 text-center">
          <font-awesome-icon prefix="fad" icon="envelope" class="fontinput text-secondary password-reset-success-icon p-3" />
          <!-- <i class="fa fa-envelope fontinput text-secondary password-reset-success-icon p-3"></i> -->
        </div>
        <div class="text-center mt-4 mb-3"><h1>Check your email</h1></div>
        <div class="w-100 text-center my-3 text-success">We sent a password reset link to</div>
        <div class="w-100 text-center mb-3 text-success">{{route.query.email}}</div>
        <div class="w-100 text-center my-3">Didn't receive the email? 
          <router-link to="/auth/reset-password" class="font-weight-bold text-success" style="text-decoration:none">Click to resend</router-link>
        </div>
        <div class="w-100 text-center mb-4 text-secondary">
          <router-link to="/auth/login" style="text-decoration:none">Back to Sign In</router-link>
        </div>
      </div>
    </div>
    <div class="text-secondary d-flex justify-content-between bottom-content-container thealtfooter">
      <div>© 2021, Made with <i class="fa fa-heart text-danger"></i> by <span class="text-success">Ikooba Technologies</span> for a better web</div>
      <div class="d-flex">
        <div class="bottom-footer-margin">Ikooba Tim</div>
        <div class="bottom-footer-margin">Creative</div>
        <div class="bottom-footer-margin">Blog</div>
        <div class="bottom-footer-margin">Licenses</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';

export default {
setup() {
    const route = useRoute();
    return { route };
  },
}
</script>

<style>
.authbg {
  background: #F2FFF8;
}

.authtop {
  padding-top: 84px;
}

.inputborder {
  border-left: white;
}

.fontinput {
  font-size: 23px;
}

.footergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.bottom-content-container {
  padding-left: 113px;
  padding-right: 111px;
}

.bottom-footer-margin {
  margin-right: 14px;
}

.password-reset-success-icon {
  background: #9FFFCC;
  border-radius: 999px;
}

.thefooter {
  margin-top: 84px;
}

.thealtfooter {
  margin-top: 190px;
}

.buttongreen {
  background: green !important;
  color: white !important;
}

@media (max-width: 767px) {
  .bottom-content-container {
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
}
</style>