<template>
<SettingHeaderTab />

<div class="px-2">
    <div class="mt-2 pl-3 font-weight-bold bulkheader" style="font-size:24px"> Bulk Supplier Upload</div>
</div>
<div class="d-flex justify-content-center" style="overflow-x:auto;">
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3">
        <div class="mt-5">1. All imports must be uploaded using the correct template file.</div>
        <div class="mt-3">
            <button v-if="downloading === false" class="btn btn-outline-success w-100 py-3 font-weight-bold" @click="downloadFile('/settings/supplier/download/upload', 'Supplier_Upload_Template.xlsx')">Download Template</button>
            <button v-if="downloading === true" type="submit" class="btn buttongreen btn-lg btn-block w-100 rounded-3">
                Downloading...
            </button>

        </div>

        <section class="mt-5">
            <form>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="preview-zone hidden">
                                    <div class="box box-solid">
                                        <div class="box-body"></div>
                                    </div>
                                </div>
                                <div class="dropzone-wrapper">
                                    <div class="dropzone-desc">
                                        <font-awesome-icon icon="sticky-note" class="dragfileicon text-success" />
                                        <p class="fw-bold mt-2 dragtext">Drag your file here or <span class="text-success">browse</span></p>
                                        <p>{{import_file_name}}</p>

                                    </div>
                                    <!-- <input type="file" name="file_import" class="dropzone90" ref="import_file" @change="onFileChange"> -->
                                    <input type="file" name="filename" class="custom-file-input dropzone" id="inputFileUpload" v-on:change="onFileChange">
                                </div>
                                <p class="form-text text-danger" align="center" v-if="validationErrors.import_file">
                                    {{ validationErrors.import_file[0] }}
                                </p>
                                <span align="center" v-if="validationErrors">
                                    <p class="form-text text-danger" v-for="(error, index) in validationErrors" :key="index">
                                        {{ error[0]}}
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2 text-center">
                        <button v-if="loading === false" type="submit" class="btn buttongreen btn-lg btn-block w-75 rounded-3" @click="importSuppliers($event)">Upload</button>
                        <button v-if="loading === true" type="submit" class="btn buttongreen btn-lg btn-block w-100 rounded-3">
                            <div class="spinner-grow text-info" role="status">
                                <span class="visually-hidden">Processing</span>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </section>

        <div class="mt-4 row">
            <div class="mb-2">Download the Sample Template <a href="#" @click="downloadFile('/settings/supplier/download/sample', 'Supplier_Sample_Template.xlsx')"> Here</a></div>
            <div class="font-weight-bold"> <b> Instructions</b></div>
            <div class="mb-2">Below are a few instructions to help you in filling the spreadsheet</div>
            <div class="mt-1 d-flex">
                <span class="me-2">1.</span>
                <div><span class="fw-bold">Download a template of the spreadsheet</span> using the 'Download Template' button to upload products using excel.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">2.</span>
                <div><span class="fw-bold">Supplier Name:</span> Name of the supplier. It is compulsory</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">3.</span>
                <div><span class="fw-bold">Phone Number:</span> Phone number of the supplier. Ensure that the PHONE_NUMBER columns sheet is on <span class="fw-bold">“TEXT”</span>. It is not compulsory.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">4.</span>
                <div><span class="fw-bold">Email:</span> Email address of the supplier. It is not compulsory.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">5.</span>
                <div><span class="fw-bold">Address:</span> Address of the supplier. It is not compulsory.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">6.</span>
                <div><span class="fw-bold">Country:</span> Country of the supplier. It is not compulsory.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">7.</span>
                <div><span class="fw-bold">State:</span> State of the supplier. This is based on the country selected above. It is not compulsory.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">8.</span>
                <div><span class="fw-bold">City:</span> City where the supplier resides. This is also base on the country and state selected above. It is not compulsory.</div>
            </div>

        </div>
    
    </div>
</div>
</template>

<script>
import SettingHeaderTab from './components/settingheadertab.vue';
import axios from 'axios'
import {
    useToast
} from 'vue-toastification'
import {
    useRouter
} from 'vue-router';

import {
    ref
} from '@vue/reactivity';

import { useReusable} from "../../../lib/reusable";

export default {
    components: {
        SettingHeaderTab
    },
    setup() {
        const {downloading, downloadFile} = useReusable()
        const import_file = ref('')
        const loading = ref(false)

        const toast = useToast()
        const router = useRouter()
        const validationErrors = ref([]);
        const import_file_name = ref('')

        const onFileChange = (e) => {
            import_file.value = e.target.files[0]
            import_file_name.value = import_file.value.name

        }

        const importSuppliers = () => {
            loading.value = true
            let formData = new FormData()
            formData.append("import_file", import_file.value)
            axios.post('/settings/supplier/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(() => {
                toast.success('Created suppliers successfully')
                loading.value = false
                router.push('/all-suppliers')
            }).catch((error) => {
                loading.value = false
                if (error?.message === 'Network Error') {
                    import_file_name.value = null
                    toast.error("Upload file changed, upload again");
                }
                if (error.response.status === 422) {
                    validationErrors.value = error.response.data.errors
                    toast.warning(error.response.data.message);
                }
                if (error.response.status === 500) {
                    toast.error('An error occured please try again');
                }
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                }
                loading.value = false

            })
        }
        return {
            loading,
            downloading,
            validationErrors,
            import_file,
            import_file_name,
            onFileChange,
            importSuppliers,
            downloadFile
        }
    }
}
</script>

<style>
.bottom-bulk {
    height: 9px;
    margin-top: 7px;
}

@media (max-width: 767px) {
    .dragfileicon {
        font-size: 30px;
    }

    .dragtext {
        font-size: 14px;
    }
}
</style>
