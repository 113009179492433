<template>
  <ReportHeaderTab />

  <div class="mt-3 gridtop" style="overflow-x:hidden">
    <div class="d-flex onmobile justify-content-between">
      <div class="mt-1 fw-bold" style="font-size:20px">
        <router-link to="/sales-report">
        <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" /></router-link> SA-123456
      </div>
      <div class="d-flex gridtop">
        <div class="me-2">
          <button class="btn px-5" style="background:white; border:1px solid gray;">Download as PDF</button>
        </div>
        <div class="d-flex">
          <button class="btn btn-light px-5 me-2" style="background:white; border:1px solid gray;">
            Print 58mm
          </button>
        </div>
        <div class="d-flex">
          <button class="btn btn-light px-5 me-2" style="background:white; border:1px solid gray;">
            Print 80mm
          </button>
        </div>
        <div class="d-flex">
          <button class="btn btn-light px-5 mr-2" style="background:white; border:1px solid gray;">
            Print A4
          </button>
        </div>
      </div>
    </div>  
    <div style="overflow-x:hidden">
      <div class="d-flex salescardcontainer">
        <div class="card rounded-lg mt-3 mb-3 px-4 py-3 w-75 salescard cardprintsales print-sales-shadow">
          <div class="d-flex justify-content-between mb-5">
            <div class="fw-bold" style="font-size:45px">Invoice</div>
            <div><img class="invoicelogo" src="../../../assets/images/logo.png" /></div>
          </div>

          <div class="d-flex justify-content-between mb-5">
            <div>
              <div class="fw-bold">Customer</div>
              <div class="fw-bold">JOHN SNOW</div>
              <div class="">26 Surulere street</div>
              <div class="">snow@mail.com</div>
            </div>
            <div>
              <div class="fw-bold">Company Name</div>
              <div class="">26 Surulere street</div>
              <div class="mt-1">snow@mail.com</div>
              <div class="mt-1">090998876677</div>
            </div>
          </div>

          <table class="table table-sm-responsive">
            <thead class="thead" style="border-style: hidden !important;">
              <tr class="">
                <th class="text-secondary font-weight-bold" style="font-size: 14px;">Sales Date</th>
                <th class="text-secondary font-weight-bold" style="font-size: 14px;">Sales No</th>
                <th class="text-secondary font-weight-bold" style="font-size: 14px;">Store</th>
                <th class="text-secondary font-weight-bold" style="font-size: 14px;">Sold By</th>
              </tr>
            </thead>
            <tbody style="border-style: hidden !important;">
              <tr>
                <td>12-02-10</td>
                <td>SN-12345678</td>
                <td>Surulere Store</td>
                <td>Peter John</td>
              </tr>
            </tbody>
          </table>

          <div style="overflow-x: auto;">
            <table class="table table-sm-responsive">
              <thead class="thead bg-light" style="border-style: hidden !important;">
                <tr class="">
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">PRODUCT</th>
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">VARIANT</th>
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">QTY</th>
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">PRICE</th>
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">DISCOUNT</th>
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">TAX</th>
                  <th class="text-secondary font-weight-bold" style="font-size: 14px;">AMOUNT</th>
                </tr>
              </thead>
              <tbody style="border-style: hidden !important;">
                <tr>
                  <td>Yam</td>
                  <td>Pieces</td>
                  <td>1</td>
                  <td>₦3,385.00</td>
                  <td>-</td>
                  <td>-</td>
                  <td>₦3,385.00</td>
                </tr>
                <tr>
                  <td>Beans</td>
                  <td>Carton</td>
                  <td>2</td>
                  <td>₦3,385.00</td>
                  <td>-</td>
                  <td>₦235</td>
                  <td>₦6,385.00</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="topd d-flex justify-content-end">
            <div style="width:300px;">
              <div class="d-flex">
                <div class="fw-bold subtotal">SUBTOTAL</div>
                <div class="leftsub">₦9,870.00</div>
              </div>

              <div class="d-flex">
                <div class="fw-bold taxmargin">TAX</div>
                <div class="">₦285.00</div>
              </div>

              <div class="d-flex">
                <div class="fw-bold subtotal">DISCOUNT</div>
                <div class="">₦0.00</div>
              </div>

              <div class="d-flex">
                <div class="fw-bold totalmargin">TOTAL</div>
                <div class="">₦10,870.00</div>
              </div>
            </div>
          </div>

          <hr class="firsthr">

          <div class="fw-bold">Payment Information</div>
          
          <div>
            <div class="d-flex justify-content-between">
              <div>Payment Type</div>
              <div class="fw-bold salesbottommargin">Cash</div>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>Paid Amount</div>
              <div class="fw-bold salesbottommarginpaid">₦2,400.00</div>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>Balance</div>
              <div class="fw-bold salesbottommarginbal">₦234.00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeaderTab from './components/reportheadertab.vue'
export default {
  components: { ReportHeaderTab },
}
</script>

<style scoped>
.subtotal {
  margin-right:99px;
}

.taxmargin {
  margin-right: 150px;
}

.totalmargin {
  margin-right: 134px;
}

.salesbottommargin {
  margin-right: 74px !important;
}

.salesbottommarginpaid {
  margin-right: 32px !important;
}

.salesbottommarginbal {
  margin-right: 46px !important;
}

.salescardcontainer {
  justify-content: center;
}

@media (max-width: 767px) {
  .salescard {
    width: 100% !important;
  }

  .onmobile {
    flex-direction: column !important;
  }

  .salescardcontainer {
    display: block !important;
  }

  .invoicelogo{
    width: 50px;
    height: 50px;
  }
}
</style>