<template>
    <div class="active" id="allproduct" role="tabpanel" aria-labelledby="allproduct-tab">
        <div class="px-3 font-weight-bold gridtop">
            <div class="mt-2" style="font-size: 20px">Sales List</div>
            <div class="d-flex justify-content-between gridtop mb-2">
                <div class="d-flex dateinputs allsalestop">
                    <div class="mb-0 me-1" style="width: 214px">
                        <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control"
                            style="background: none; border: none" />
                    </div>
                    <div class="mb-0 me-2" style="width: 214px">
                        <Datepicker v-model="tableData.end_date" :minDate="tableData.start_date"
                            :enableTimePicker="false" class="form-control" style="background: none; border: none" />
                    </div>
                    <div class="salessubmitdate">
                        <button type="submit" data-bs-dismiss="modal"
                            class="btn btn-success allbuttonsclr text-white mt-2" v-if="crudLoading === false"
                            @click="fetchData('/inventory/sales/web',true)">
                            Submit
                        </button>
                        <button v-if="crudLoading == true" class="btn btn-success allbuttonsclr text-white mt-2"
                            type="button" @click="fetchData()" disabled>
                            <span class="">Processing...</span>
                        </button>
                    </div>
                </div>
                <div class="d-flex allsalestop secondsalestop mt-1">
                    <div class="input-group mb-0 me-2" style="width: 214px">
                        <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:2px">
                            <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                            <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px"
                                v-model="query" />
                        </div>
                    </div>
                    <div class="dropdown">
                        <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                            Export
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a @click="
                                    drawPdf(
                                        print_content,
                                        headerArrayColumn,
                                        'l',
                                        'sales',
                                        'Sales'
                                    )
                                " class="dropdown-item" href="#">PDF</a>
                            </li>
                            <li>
                                <a @click="reusableMixinToCSV('sales.csv')" class="dropdown-item" href="#">CSV</a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-1">
                        <router-link to="/make-sales" class="btn allbuttonsclr text-white"
                            :class="permission.create_sales === 1 ? '' : 'disabled'">
                            <span class="ms-2">Make Sales</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
            <div v-if="loading === true" class="text-center py-5">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="text-center py-5" v-if="details.length === 0 && loading === false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your sales list is empty!</div>
            </div>
            <div class="card-toolbar" v-if="loading === false && details.length > 0">
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()"
                            class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->
                </div>
            </div>
            <div v-if="loading === false && details.length > 0">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5">
                            <th class="font-weight-bold heading">Sale Number</th>
                            <th class="font-weight-bold heading">Customer</th>
                            <th class="font-weight-bold heading">Date</th>
                            <th class="font-weight-bold heading">Time</th>
                            <th class="font-weight-bold heading">Store</th>
                            <th class="font-weight-bold heading">Total({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold heading">Payment Type</th>
                            <th class="font-weight-bold heading">Sold By</th>
                            <th class="font-weight-bold">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="sales in details" :key="sales.id">
                            <td class="text-secondary py-3 row1">{{ sales.batch_code }}</td>
                            <td class="text-secondary py-3 row1">
                                {{ sales?.customer?.customer_name }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ dateTime(sales?.date) }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ timeConvert(sales.time) }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ sales?.store?.store_name }}
                            </td>
                            <td class="text-secondary py-3 row1">
                                {{ $filters.formatMoney(sales?.total_amount) }}
                            </td>
                            <td class="text-secondary py-3 row1 text-capitalize">
                                {{ sales.payment_type }}
                                {{ sales.payment_type == "part" || sales.payment_type == "split" ? " Payment" : ""}}
                            </td>

                            <td class="text-secondary py-3 row1">
                                {{ sales?.user?.fullname }}
                            </td>

                            <td class="py-3">
                                <div class="dropdown p-0">
                                    <button class="btn btn-default dropdown-toggle" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                        style="background-color: '#90ee90'; padding: 2px">
                                        Select
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <router-link class="dropdown-item" :to="'/sales-receipt/' + sales.id"
                                                :class="permission.view_sales === 1 ? '' : 'disabled'">View
                                            </router-link>
                                        </li>
                                        <!-- <li><a class="dropdown-item" href="#">Print</a></li> -->
                                        <li>
                                            <a class="dropdown-item" href="#" data-bs-toggle="modal"
                                                data-bs-target="#singleModal" :class="
                                                    permission.reverse_sales === 1 ? '' : 'disabled'
                                                " @click="getCurrentId(sales.id)">Reverse</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Alert :modalId="'singleModal'" @alertModal="handleAction()"
            :modalText="'Are you sure you want to reverse this sales'" :action="'alertModal'" title="Reverse sales">
        </Alert>
        <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
            @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
    </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

import { reusableMixin } from "../../../lib/reusableMixin";
import { useStore } from "vuex";
import moment from "moment";
import Alert from "../../../components/AlertModal.vue";

export default {
    components: {
        Alert,
    },
    mixins: [reusableMixin],
    data() {
        let sortOrders = {};
        let columns = [
            {
                width: "16%",
                label: "Name",
                name: "name",
                sortable: false,
            },
            {
                width: "15%",
                label: "Sortcode",
                name: "sortcode",
                sortable: false,
            },
            {
                width: "15%",
                label: "Phonecode",
                name: "phonecode",
                sortable: false,
            },
            {
                width: "5%",
                label: "Status",
                name: "status",
                sortable: false,
            },
            {
                width: "5%",
                label: "Actions",
                name: "actions",
                sortable: false,
            },
        ];

        // Show date from the past 6 months
        var current_date = new Date();
        let current_date_to_date = current_date.setMonth(
            current_date.getMonth() - 6
        );
        current_date_to_date =
            this.$route.query.todaysdate ==
                new Date()
                    .toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                ? new Date()
                : new Date(current_date_to_date);

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content: [],
            headerArrayColumn: [
                "S/N",
                "sales Number",
                "Date",
                "Time",
                "Store",
                "Total",
                "Payment Type",
            ],
            details: [],
            loading: false,
            query: "",

            selectedModel: [],
            allSelected: false,
            columns: columns,
            sortKey: "id",
            sortOrders: sortOrders,
            tableData: {
                start_date: current_date_to_date,
                end_date: new Date(),
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
                id: "",
                status: [0, 1, 2, 3],
                query: "",
            },
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },
            toast: useToast(),
            currentID: null,
            store: useStore(),
            crudLoading: false,
        };
    },
    methods: {
        getCurrentId(id) {
            this.currentID = id;
        },
        dataToPrint(details) {
            details.map((sales, idx) => {
                let result = {
                    sn: idx + 1,
                    bacth_code: sales.batch_code,
                    date: sales.date,
                    time: sales.time,
                    store: sales.store?.store_name,
                    total: sales.total_amount,
                    payment_type: sales.payment_type,
                };
                this.print_content.push(result);
            });
        },

        handleAction() {
            this.loading = true;
            axios
                .post(`/inventory/sales/reverse/${this.currentID}`)
                .then((response) => {
                    if (response.status == 200) {
                        this.toast.success(response.data.message);
                        this.fetchData();
                    }
                })
                .catch((error) => {
                    this.toast.error(
                        error?.response?.data?.message ?? "Whoops, Something went wrong"
                    );
                });
        },
        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },

        fetchData(url = "/inventory/sales/web", crudLoading) {
            this.loading = true;
            if(crudLoading) this.crudLoading = true; else this.crudLoading = false;
            this.tableData.query = this.query;

            if (this.crudLoading && this.query) {
                this.crudLoading = false;
            }

            this.tableData.draw++;
            axios
                .get(url, {
                    params: this.tableData,
                })
                .then((response) => {
                    this.print_content = [];
                    if (!response.data.status == 200) {
                        this.loading = false;
                        return (this.errorMessage = "Could not fetch data");
                    }
                    this.loading = false;
                    this.crudLoading = false;
                    let data = response.data;
                    this.details = data.data.data;
                    this.dataToPrint(this.details);
                    this.configPagination(data.data);
                })
                .catch((error) => {
                    this.crudLoading = false;
                    this.errorMessagem = error;
                    this.loading = false;
                });
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
    },

    computed: {
        filtersearch() {
            let details;
            if (this.query == "") {
                details = this.details;
            } else {
                details = this.details.filter(
                    (sales) =>
                        sales?.batch_code.toLowerCase().includes(this.query) ||
                        sales?.store?.store_name.toLowerCase().includes(this.query) ||
                        sales?.customer?.customer_name.toLowerCase().includes(this.query) ||
                        sales?.total_amount.toString().includes(this.query) ||
                        this.dateTime(sales?.date).toLowerCase().includes(this.query) ||
                        this.timeConvert(sales.time).toLowerCase().includes(this.query)
                );
            }
            return details;
        },
        permission() {
            return this.store.state.getCurrentUserPermission;
        },
    },

    watch: {
        query() {
            this.fetchData()
        },
    },
    created() {
        this.fetchData();
        this.moment = moment;
        this.getCompany();
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .allsalestop {
        flex-direction: column;
    }

    .salessubmitdate {
        margin-left: 16px;
    }

    .thecard {
        /* width: 21rem; */
        width: 100%;
    }

    .secondsalestop {
        margin-top: 10 px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .allsalestop {
        flex-direction: column;
    }

    .salessubmitdate {
        margin-left: 13px;
    }
}
</style>
