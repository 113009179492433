<template>
<InventoryHeaderTab />

<div>
    <div class="tab-pane mt-3 fade show active card thecard rounded-lg px-3 py-3" id="company" role="tabpanel" aria-labelledby="company-tab">
        <div class="d-flex justify-content-between">
            <div>
                <h4>Product</h4>
                <div class="text-muted" style="font-size: 10px">Create Product</div>
            </div>
        </div>
        <createProductForm></createProductForm>
    </div>
</div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";

import createProductForm from "./component/createProductForm";
export default {
    components: {
        InventoryHeaderTab,
        createProductForm
    },

};
</script>

<style scoped>
.img-icon {
    position: absolute;
    z-index: 7;
    margin-top: 43px;
    margin-left: 11px;
    color: white;
}

.imgedit {
    position: relative;
}

@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }

    .thebtn {
        margin-right: 5px !important;
    }
}
</style>



