<template>
<div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    <font-awesome-icon type="button" class="text-danger" icon="trash" style="font-size: 70px;" />
                </div>
                <div class="fw-bold text-center mt-3 mb-2" style="font-size:20px">
                    Delete
                </div>
                <div class="text-center" v-html="modalText">
                </div>
            </div>
            <div class="my-3 text-center">
                <button @click.prevent="actionButton" type="button" class="btn btn-danger" style="margin-right:12px" data-bs-dismiss="modal">Confirm</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    onMounted
} from '@vue/runtime-core';
export default {

    props: {
        modalId: String,
        modalText: String,
        action:String
    },
    setup(props, context) {
        const actionButton = async ()=>{
            context.emit(props.action)
        }
        onMounted(() => {
        })

        return {actionButton,}
    }

}
</script>
