import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    getLoggedInUser:null,
    getCurrentUserPermission:null

  },
  plugins: [createPersistedState()],
  mutations: {
    GET_CURRENTLY_LOGGED_IN_USER(state, getLoggedInUser){
      state.getLoggedInUser = getLoggedInUser
    },
    GET_CURRENT_LOGGED_IN_USER_PERMISSION(state, getCurrentUserPermission){
      state.getCurrentUserPermission = getCurrentUserPermission
    }
  },
  actions: {
    getCurrentUser:({ commit }, getLoggedInUser) => commit('GET_CURRENTLY_LOGGED_IN_USER', getLoggedInUser),
    getCurrentUserPermission:({ commit }, getCurrentUserPermission) => commit('GET_CURRENT_LOGGED_IN_USER_PERMISSION', getCurrentUserPermission)

  }
})