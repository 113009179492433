import axios from 'axios'
import {ref,computed} from 'vue'
import {useStore} from "vuex";

export function useReusable(product=null){

    const downloading = ref (false)
    const getProducts = ref([]);
    const store = useStore()
    const paymentTypes = ref([])
    const getStore = ref([])


    const currentUserCurrency = () => {
        return store.state.getLoggedInUser?.business?.currency;
    }
    const permission = computed(() => {
        return store.state.getCurrentUserPermission
    })

    const removeProduct = (index, calculateTotal) => {
        if(product.value.length > 1)product.value.splice(index, 1);

        calculateTotal(product)
    };

    const validateInput = (product)=>{
        if(product.quantity < 0){
            product.quantity =0
        }
    }
      
    const getProductInStore = async (evt) => {
        let storeId;
        if(typeof evt == 'string'){
            storeId = evt
        }else{
            storeId = evt.target.value
        }
        const getProduct = await axios.get(`${'/admin/store/product-in-store/'+storeId}`)
        getProducts.value = getProduct?.data?.data?.data
  
        // Set avalaible quantity value back to zero
        product.value.forEach((p)=>{
          p.available_qty = 0
        })
      }

       function downloadFile(url, file_name){
            downloading.value = true

            axios({
                url: url,
                method: "GET",
                responseType: "blob", // important
            }).then((response) => {
                downloading.value = false
    
                // Service that handles ajax call
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file_name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(() => {
                downloading.value = false
            });
    
        }
        const allPaymentTypes = async () => {
            const result = await axios.get('settings/payment_type/active')
            const response = result.data.data
            response.forEach((p) => {
                paymentTypes.value.push({
                    value: p.payment_type,
                    name: p.name
                })
            })
        }
        const allStore = async () => {
            const storeCall = await axios.get("/admin/store/user-stores");
            let stores = storeCall.data.data;
            stores.forEach((store) => {
                getStore.value.push({
                    value: store.id,
                    name: store.store_name
                })
            })
        }

        return {
            downloading,
            downloadFile,
            getProductInStore,
            getProducts,
            permission,
            removeProduct,
            currentUserCurrency,
            validateInput,
            paymentTypes,
            allPaymentTypes,
            allStore,
            getStore
        }
    }
    